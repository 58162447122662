import { Box, Stack, Typography } from '@mui/material';
import React, { ComponentProps, ReactNode } from 'react';

import CustomBottomSheet from './CustomBottomSheet';
import { HorizontalSeparator } from './shared';

const Separator = () => <HorizontalSeparator sx={{ bgcolor: 'grey.100' }} />;

const SegmentedBottomSheetHeader = ({ children }: { children: ReactNode }) => (
    <Box p="20px 24px">
        {typeof children === 'string' ? (
            <Typography variant="body1" fontSize="16px" fontWeight={700} lineHeight="36px">
                {children}
            </Typography>
        ) : (
            children
        )}
    </Box>
);

const SegmentedBottomSheetBody = ({ children }: { children: ReactNode }) => <Box p="20px 24px">{children}</Box>;

const SegmentedBottomSheetActions = ({ children }: { children: ReactNode }) => (
    <Stack direction="row" justifyContent="end" p="20px 24px" spacing="8px">
        {children}
    </Stack>
);

const SegmentedBottomSheet = ({
    header,
    actions,
    children,
    ...props
}: {
    header?: ReactNode;
    actions?: ReactNode;
} & ComponentProps<typeof CustomBottomSheet>) => {
    return (
        <CustomBottomSheet {...props}>
            {header && (
                <>
                    <SegmentedBottomSheetHeader>{header}</SegmentedBottomSheetHeader>
                    <Separator />
                </>
            )}
            <SegmentedBottomSheetBody>{children}</SegmentedBottomSheetBody>
            {actions && (
                <>
                    <Separator />
                    <SegmentedBottomSheetActions>{actions}</SegmentedBottomSheetActions>
                </>
            )}
        </CustomBottomSheet>
    );
};

export default SegmentedBottomSheet;
