import { Stack, ThemeProvider } from '@mui/material';
import { Typography } from '@mui/material';
import { styled } from '@mui/material';
import { Coin } from '@phosphor-icons/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { useRewardsSummaryQuery } from '~/api/queries/caregiverRewards';
import { usePermissions } from '~/permissions/utils';
import theme from '~/scheduling/theme';
import { ReduxStore } from '~/types/redux';

const MY_REWARDS_COLOR = '#FCD7B1';

const MyRewardsActionContainer = styled(Stack)(({ theme: { palette, unstable_sx } }) =>
    unstable_sx({
        color: { xs: MY_REWARDS_COLOR, sm: palette.primary.main },
        flexDirection: 'row',
        alignItems: 'center',
        gap: '4px',
        p: { xs: '4px 8px', sm: '6px 10px' },
        border: '1px solid',
        borderColor: { xs: MY_REWARDS_COLOR, sm: palette.primary.main },
        borderRadius: '48px',
        userSelect: 'none',
        cursor: 'pointer',
        transition: 'background-color 0.2s, color 0.2s',
        '&:hover': {
            color: { xs: palette.primary.main, sm: MY_REWARDS_COLOR },
            bgcolor: { xs: MY_REWARDS_COLOR, sm: palette.primary.main },
        },
    })
);

const MyRewardsAction = () => {
    const navigate = useNavigate();
    const hasPermission = usePermissions();

    const { userId, branchId } = useSelector((state: ReduxStore) => state.session.sessionData);

    const { data: rewardsSummaryData } = useRewardsSummaryQuery(
        hasPermission('Community', 'read-reward') ? userId : undefined,
        branchId
    );

    if (!hasPermission('Community', 'update-reward')) return null;

    return (
        // TODO: Remove after applying the Scheduling theme globally
        <ThemeProvider theme={theme}>
            <MyRewardsActionContainer onClick={() => navigate('/rewards', { replace: true })}>
                <Coin size={20} />
                <Typography sx={{ fontSize: { xs: '14px', sm: '16px' } }}>
                    {rewardsSummaryData?.totalPoints ?? ''}
                </Typography>
            </MyRewardsActionContainer>
        </ThemeProvider>
    );
};

export default MyRewardsAction;
