import { Stack, Theme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ReactNode } from 'react';

import DesktopDrawer from './DesktopDrawer';
import MobileBottomNavigation from './MobileBottomNavigation';
import MobileDrawer from './MobileDrawer';

const LayoutContainer = styled(Stack)({
    flexDirection: 'row',
    width: '100%',
    height: '100%',
});

const ContentContainer = styled(Stack)({
    flex: 1,
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
});

const WithNavigation = ({ children }: { children: ReactNode }) => {
    const isMobile = useMediaQuery<Theme>(({ breakpoints }) => breakpoints.down('sm'));

    return (
        <LayoutContainer>
            {isMobile ? <MobileDrawer /> : <DesktopDrawer />}
            <ContentContainer>
                {children}
                {isMobile && <MobileBottomNavigation />}
            </ContentContainer>
        </LayoutContainer>
    );
};

export default WithNavigation;
