import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useSetAtom } from 'jotai';
import React from 'react';
import { PiCaretRight } from 'react-icons/pi';

import { isSlotDetailsBottomSheetOpenAtom, selectedSlotIdAtom } from '../../atom';

import { Flags, SlotContainer, SlotDetails, SlotProps } from './shared';

const FilledSlot = (props: SlotProps) => {
    const { palette } = useTheme();
    const toggle = useSetAtom(isSlotDetailsBottomSheetOpenAtom);
    const setSelectedSlotId = useSetAtom(selectedSlotIdAtom);

    const handleClick = () => {
        toggle();
        setSelectedSlotId(props.id);
    };

    return (
        <SlotContainer borderColor={palette.grey[100]} onClick={handleClick} isPublished={props.isPublished}>
            <Stack spacing="4px">
                <Box display="flex" gap="4px" alignItems="center">
                    <Typography variant="body1" color={palette.grey[900]} fontWeight={700} fontSize="16px">
                        {props.staffName}
                    </Typography>
                    <Flags flags={props.flags} />
                </Box>
                <SlotDetails location={props.location} role={props.role} />
            </Stack>
            <PiCaretRight type="bold" size={16} />
        </SlotContainer>
    );
};

export default FilledSlot;
