import { Box, Skeleton, Stack, Typography, styled, useTheme } from '@mui/material';
import { useAtomValue } from 'jotai';
import { groupBy, range, sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { PiCalendarBlankFill } from 'react-icons/pi';

import { useGetFullSchedule } from '~/scheduling/api/queries/shift-slot/getFullSchedule';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import { teamIdAtom } from '~/scheduling/atoms';
import { SELECTOR_ALL_ID } from '~/scheduling/constants';

import { datePickerValueAtom, selectedTabAtom } from '../../atom';
import { SlotItem } from '../SlotItem';

const IconContainer = styled(Box)(({ theme }) => ({
    width: '56px',
    height: '56px',
    padding: '16px',
    backgroundColor: theme.palette.grey[50],
    borderRadius: '100px',
}));

const LoadingPlaceholder = () => (
    <Stack height="100%" overflow="auto" padding="24px">
        {range(3).map((shift, index) => (
            <React.Fragment key={shift}>
                {index !== 0 && <Box height="16px" flexShrink={0} />}
                <Skeleton height="20px" width="41px" sx={{ flexShrink: 0, marginBlock: '4px' }} />
                {range(3).map((slot) => (
                    <Skeleton key={slot} height="72px" sx={{ marginTop: '8px', flexShrink: 0 }} width="100%" />
                ))}
            </React.Fragment>
        ))}
    </Stack>
);

const EmptyState = () => {
    const { palette } = useTheme();

    return (
        <Stack alignItems="center" height="100%" justifyContent="center" padding="24px">
            <IconContainer>
                <PiCalendarBlankFill size={24} color={palette.grey[300]} />
            </IconContainer>
            <Box height="32px" />
            <Typography color={palette.grey[900]} variant="h5" letterSpacing="unset">
                No Scheduled Shifts
            </Typography>
            <Typography
                color={palette.grey[500]}
                variant="body1"
                textAlign="center"
                sx={{ textWrap: 'balance' }}
                fontWeight={400}
                marginTop="8px"
            >
                There are currently no scheduled shifts for the selected day.
            </Typography>
        </Stack>
    );
};

export const ShiftSlotList = () => {
    const { palette } = useTheme();
    const selectedTab = useAtomValue(selectedTabAtom);
    const selectedDate = useAtomValue(datePickerValueAtom);
    const teamId = useAtomValue(teamIdAtom);

    const { data: rolesData, isLoading: isRolesLoading, error: rolesError } = useGetRoles();

    const {
        data: schedule,
        isLoading,
        error: scheduleError,
    } = useGetFullSchedule({
        startDay: selectedDate,
        endDay: selectedDate,
        locationId: selectedTab !== SELECTOR_ALL_ID ? selectedTab : undefined,
        teamId,
    });
    const slots = schedule?.slots ?? [];

    const shiftSlots = useMemo(() => {
        // Group shifts, then roles, then locations
        const sortedSlots = sortBy(slots, ({ roleShiftId, roleId, locationId }) => {
            const roleShift = rolesData?.roleShiftById.get(roleShiftId)?.id ?? 0;
            return [roleShift, roleId, locationId];
        });

        return groupBy(sortedSlots, ({ roleShiftId }) => rolesData?.roleShiftById.get(roleShiftId)?.name ?? '');
    }, [slots, rolesData]);

    if (isLoading || isRolesLoading) return <LoadingPlaceholder />;
    if ((!slots?.length && !scheduleError) || (!rolesData && !rolesError)) return <EmptyState />;
    if (rolesError || scheduleError) return null;

    return (
        <Stack height="100%" overflow="auto">
            {Object.entries(shiftSlots).map(([shiftName, slots], index) => (
                <Stack
                    key={shiftName}
                    spacing="8px"
                    padding="24px"
                    borderBottom={
                        index < Object.keys(shiftSlots).length - 1 ? `4px solid ${palette.grey[100]}` : undefined
                    }
                >
                    <Typography
                        textTransform="capitalize"
                        color={palette.grey[900]}
                        fontWeight={700}
                        fontSize="24px"
                        paddingBlock="4px"
                    >
                        {shiftName}
                    </Typography>
                    {slots.map((slot) => (
                        <SlotItem key={slot.id} slot={slot} />
                    ))}
                </Stack>
            ))}
        </Stack>
    );
};
