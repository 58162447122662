import { groupBy, range } from 'lodash';
import { DateTime } from 'luxon';

import { GetRoles } from '~/scheduling/api/types/staff-roles/getRoles';
import { GetStaff } from '~/scheduling/api/types/staff/getStaff';
import { getWeekDaysOrder } from '~/scheduling/utils/dates';

import { StaffList } from '../../../types';

export const mapReadStaffScheduleCustomShiftTimes = (
    shift: GetStaff.Shift,
    roleShiftById: Map<number, GetRoles.StaffRoleShift>
) => {
    const roleShift = roleShiftById.get(shift.staffRoleShiftId)!;
    const customStartTime = DateTime.fromISO(shift.customStartTime ?? roleShift.shiftStartTime);
    let customEndTime = DateTime.fromISO(shift.customEndTime ?? roleShift.shiftEndTime);

    // Some shifts are overnight, so we need to add a day to the end time
    if (customStartTime > customEndTime) customEndTime = customEndTime.plus({ days: 1 });

    return {
        customStartTime: +customStartTime,
        customEndTime: +customEndTime,
    };
};

export const mapBaylorSchedule = (
    schedule: GetStaff.Schedule,
    roleShiftById: Map<number, GetRoles.StaffRoleShift>
): StaffList.BaylorScheduleData[] => {
    const data = schedule.shifts.slice(0, 4).map((shift) => ({
        dayOfWeek: shift.weekday!.toString(),
        staffRoleShiftId: shift.staffRoleShiftId.toString(),
        staffRoleId: shift.staffRoleId.toString(),
        locationId: shift.locationId.toString(),
        ...mapReadStaffScheduleCustomShiftTimes(shift, roleShiftById),
    }));

    const fillWithEmpty = [
        ...data,
        ...Array<StaffList.BaylorScheduleData>(4 - data.length).fill({
            dayOfWeek: '',
            staffRoleShiftId: '',
            staffRoleId: '',
            locationId: '',
        }),
    ];

    return fillWithEmpty;
};

export const mapWeeklySchedule = (
    schedule: GetStaff.Schedule,
    firstDayOfWeek: number,
    roleShiftById: Map<number, GetRoles.StaffRoleShift>
): StaffList.WeekScheduleData[] => {
    const shifts = schedule.shifts.map<StaffList.WeekScheduleData>((shift) => ({
        enabled: true,
        dayIndex: shift.weekday!,
        staffRoleShiftId: shift.staffRoleShiftId.toString(),
        staffRoleId: shift.staffRoleId.toString(),
        locationId: shift.locationId.toString(),
        ...mapReadStaffScheduleCustomShiftTimes(shift, roleShiftById),
    }));

    const disabledField: StaffList.WeekScheduleData = {
        enabled: false,
        dayIndex: 0,
        staffRoleShiftId: '',
        staffRoleId: '',
        locationId: '',
    };

    const weekdaysOrder = getWeekDaysOrder(firstDayOfWeek);

    const weeklySchedule = weekdaysOrder.map<StaffList.WeekScheduleData>((weekday) => {
        // filling up with disabled fields
        return shifts.find((shift) => shift.dayIndex === weekday + 1) ?? { ...disabledField, dayIndex: weekday + 1 };
    });

    return weeklySchedule;
};

export const mapBiweeklySchedule = (
    schedule: GetStaff.Schedule,
    firstDayOfWeek: number,
    roleShiftById: Map<number, GetRoles.StaffRoleShift>
): StaffList.BiweeklyScheduleData[][] => {
    const shifts = schedule.shifts.map<StaffList.BiweeklyScheduleData>((shift) => ({
        enabled: true,
        dayIndex: shift.weekday!,
        weekIndex: shift.index ?? 0,
        staffRoleShiftId: shift.staffRoleShiftId.toString(),
        staffRoleId: shift.staffRoleId.toString(),
        locationId: shift.locationId.toString(),
        ...mapReadStaffScheduleCustomShiftTimes(shift, roleShiftById),
    }));

    const disabledField: StaffList.BiweeklyScheduleData = {
        enabled: false,
        dayIndex: 0,
        staffRoleShiftId: '',
        staffRoleId: '',
        locationId: '',
    };

    const weekdaysOrder = getWeekDaysOrder(firstDayOfWeek);

    const shiftsGroupedByWeek = groupBy(shifts, 'weekIndex');

    const biweeklySchedule: StaffList.BiweeklyScheduleData[][] = [[], []];

    for (const weekIndex of range(2)) {
        const weekSchedule = weekdaysOrder.map<StaffList.BiweeklyScheduleData>((weekday) => {
            const shift = shiftsGroupedByWeek[weekIndex]?.find((shift) => shift.dayIndex === weekday + 1);
            // filling up with disabled fields
            return (
                shift ?? {
                    ...disabledField,
                    dayIndex: weekday + 1,
                }
            );
        });

        biweeklySchedule[weekIndex].push(...weekSchedule);
    }

    return biweeklySchedule;
};

export const mapFourOnTwoOffSchedule = (
    schedule: GetStaff.Schedule,
    roleShiftById: Map<number, GetRoles.StaffRoleShift>
): StaffList.FourOnTwoOffScheduleData[] => {
    const data = schedule.shifts.slice(0, 4).map((shift) => ({
        staffRoleShiftId: shift.staffRoleShiftId.toString(),
        staffRoleId: shift.staffRoleId.toString(),
        locationId: shift.locationId.toString(),
        ...mapReadStaffScheduleCustomShiftTimes(shift, roleShiftById),
    }));

    const fillWithEmpty = [
        ...data,
        ...Array<StaffList.FourOnTwoOffScheduleData>(4 - data.length).fill({
            staffRoleShiftId: '',
            locationId: '',
            staffRoleId: '',
        }),
    ];

    return fillWithEmpty;
};

export const mapCustomSchedule = (
    schedule: GetStaff.Schedule,
    roleShiftById: Map<number, GetRoles.StaffRoleShift>
): StaffList.CustomScheduleData[] => {
    return schedule.shifts.map((shift) => ({
        dayOfWeek: shift.weekday?.toString() ?? '',
        staffRoleShiftId: shift.staffRoleShiftId.toString(),
        staffRoleId: shift.staffRoleId.toString(),
        locationId: shift.locationId.toString(),
        ...mapReadStaffScheduleCustomShiftTimes(shift, roleShiftById),
    }));
};
