import { Button, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { PiPlusBold, PiWarningCircleFill } from 'react-icons/pi';

import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';

import { StaffList } from '../../../types';
import GridContainer from '../shared/GridContainer';

import { ScheduleDay } from './ScheduleDay';
import { mapCustomSchedule } from './mapStaffSchedule';
import { StaffScheduleProps } from './shared';

const AddScheduleDay = styled(Button)(({ theme: { palette } }) => ({
    minHeight: '364px',
    height: '100%',
    color: palette.primary[400],
    borderColor: palette.primary[500],
}));

export const CustomSchedule = ({ form, schedule, scheduleIndex, isSaving, isEditing }: StaffScheduleProps) => {
    const { palette } = useTheme();
    const [scheduleDays, setScheduleDays] = React.useState<StaffList.CustomScheduleData[]>([]);
    const customScheduleFields = useFieldArray({ name: `schedules.${scheduleIndex}.shifts.0`, control: form.control });

    const { data: roleData } = useGetRoles();
    const scheduleData = React.useMemo(
        () => schedule && roleData && mapCustomSchedule(schedule, roleData.roleShiftById),
        [schedule, roleData]
    );

    React.useEffect(() => {
        if (scheduleData && !scheduleDays.length) setScheduleDays(scheduleData);
    }, [scheduleData]);

    const handleRemove = (fieldIndex: number) => {
        customScheduleFields.remove(fieldIndex);
        setScheduleDays(scheduleDays.filter((_, index) => index !== fieldIndex));
    };

    const handleAddNewField = () => {
        const lastField = scheduleDays.at(-1);

        if (lastField) {
            customScheduleFields.append(lastField);
            setScheduleDays([...scheduleDays, lastField]);
        } else {
            const emptyState: StaffList.CustomScheduleData = {
                staffRoleId: '',
                locationId: '',
                staffRoleShiftId: '',
                dayOfWeek: '',
            };
            customScheduleFields.append(emptyState);
            setScheduleDays([...scheduleDays, emptyState]);
        }
    };

    return (
        <Controller
            control={form.control}
            name={`schedules.${scheduleIndex}.shifts`}
            rules={{
                validate: (data) =>
                    data
                        ?.flat()
                        .some(
                            ({ staffRoleId, locationId, staffRoleShiftId, dayOfWeek }) =>
                                staffRoleId && locationId && staffRoleShiftId && dayOfWeek
                        )
                        ? undefined
                        : 'At least 1 shift needs to be filled out',
            }}
            render={({ fieldState: { error } }) => (
                <React.Fragment>
                    {error && (
                        <Typography
                            variant="body1"
                            color={palette.error[500] as string}
                            fontWeight={400}
                            fontSize="12px"
                            display="flex"
                            gap="4px"
                            alignItems="center"
                        >
                            <PiWarningCircleFill display="inline-flex" size={12} color={palette.error[500] as string} />{' '}
                            {error.message}
                        </Typography>
                    )}
                    <GridContainer minmax={240}>
                        {scheduleDays.map((scheduleDay, index) => (
                            <ScheduleDay
                                key={index + 'custom'}
                                scheduleIndex={scheduleIndex}
                                form={form}
                                title="Shift"
                                dayIndex={index}
                                scheduleDay={scheduleDay}
                                isEditing={isEditing || isSaving}
                                withDayOfWeekField
                                withDeleteButton
                                onDelete={handleRemove}
                            />
                        ))}
                        {!isEditing && (
                            <AddScheduleDay
                                variant="outlined"
                                startIcon={<PiPlusBold />}
                                onClick={handleAddNewField}
                                disabled={isSaving}
                            >
                                Add Shift
                            </AddScheduleDay>
                        )}
                    </GridContainer>
                </React.Fragment>
            )}
        />
    );
};
