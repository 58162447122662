import React from 'react';

import DesktopHeader from '~/components/Layout/DesktopHeader';
import WithHeader from '~/components/Layout/WithHeader';

import { LayoutBackground } from '../../components/LayoutBackground';

import Alerts from './components/Alerts';
import HourCards from './components/HourCards';
import { PeriodSelector } from './components/PeriodSelector';
import { CallOffTable, PeopleScheduledOvertimeTable } from './components/Table';

const Reports = () => {
    return (
        <WithHeader header={<DesktopHeader actions={<PeriodSelector />} />}>
            <LayoutBackground>
                <Alerts />
                <HourCards />
                <PeopleScheduledOvertimeTable />
                <CallOffTable />
            </LayoutBackground>
        </WithHeader>
    );
};

export default Reports;
