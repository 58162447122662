import { Box, Skeleton, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';

import { useGetCommunitySettings } from '~/scheduling/api/queries/community-settings/getCommunitySettings';
import { useGetCallOffs } from '~/scheduling/api/queries/reports/useGetCallOffs';
import { formatTimeDiff } from '~/scheduling/utils/dates';

import { usePeriod } from '../../usePeriod';

import { Cell, Row, Table, TableLink } from './Table';

export const CallOffTable = () => {
    const { data: communitySettings, isLoading: isGetCommunitySettingsLoading } = useGetCommunitySettings();
    const { palette } = useTheme();
    const { period } = usePeriod();
    const { data, isLoading } = useGetCallOffs({ period, firstDayOfWeek: communitySettings?.firstDayOfWeek });

    if (isLoading || isGetCommunitySettingsLoading) {
        return <Skeleton component="div" variant="rounded" width="100%" height={200} />;
    }

    return (
        <Box>
            <Box display="flex" justifyContent="space-between">
                <Typography variant="body1" fontWeight={700} fontSize="16px">
                    Call Offs
                </Typography>
                <TableLink href="/scheduling/full-schedule">{"This Week's Schedule"}</TableLink>
            </Box>
            <Box height="16px" />
            <Box padding="24px" bgcolor="#FFF" borderRadius="8px">
                <Table headers={['Staff Name', 'Role', 'Date', 'Shift', 'Reason', 'Call Off Policy Broken']}>
                    {!data?.length && (
                        <Row>
                            <Cell textAlign="center">No call offs</Cell>
                        </Row>
                    )}
                    {data?.map(
                        (
                            {
                                staffName,
                                staffRole,
                                reason,
                                shiftDay,
                                staffRoleShift,
                                callOffPolicy: { hasBroken, minutesToStartShift },
                            },
                            idx
                        ) => (
                            <Row key={`row${idx}`}>
                                <Cell sx={{ textAlign: 'left', textWrap: 'balance', textOverflow: 'ellipsis' }}>
                                    {staffName}
                                </Cell>
                                <Cell>{staffRole}</Cell>
                                <Cell>{DateTime.fromISO(shiftDay).toFormat('EEEE, MM/d')}</Cell>
                                <Cell>{staffRoleShift.name}</Cell>
                                <Cell>{reason}</Cell>
                                <Cell>
                                    {hasBroken ? (
                                        <Typography variant="body1" color={palette.error[500] as string}>
                                            Yes ({formatTimeDiff(minutesToStartShift / 60)})
                                        </Typography>
                                    ) : (
                                        `No (${formatTimeDiff(minutesToStartShift / 60)})`
                                    )}
                                </Cell>
                            </Row>
                        )
                    )}
                </Table>
            </Box>
        </Box>
    );
};
