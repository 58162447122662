import { Box, Button, Skeleton, Typography, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import range from 'lodash/range';
import { DateTime } from 'luxon';
import React from 'react';

import { useWeekInterval } from '~/scheduling/useWeekInterval';

import { useScheduleGridSchema } from '../../../../../../../useScheduleGridSchema';

import { GRID_COLUMNS } from './shared';

const VerticalSpacer = styled(Box)({ width: '8px' });

const CalendarContainer = styled(Grid)(({ theme: { palette } }) => ({
    position: 'sticky',
    top: 0,
    width: '100%',
    borderBottom: '1px solid',
    borderColor: palette.grey[200],
}));

const CalendarCell = styled(Grid)({
    position: 'relative',
});

const CalendarItem = ({
    dateTime,
    isPreview,
    isWeekend,
    isToday,
}: {
    dateTime: DateTime;
    isPreview: boolean;
    isWeekend: boolean;
    isToday: boolean;
}) => {
    let colors = {
        text: 'grey.900',
        background: 'white',
        border: 'grey.50',
    };

    // today should always take precedence
    if (isToday) {
        colors = {
            text: 'primary.600',
            background: 'primary.50',
            border: 'grey.100',
        };
    } else if (isPreview) {
        colors = {
            text: 'grey.300',
            background: 'grey.25',
            border: 'grey.100',
        };
    } else if (isWeekend) {
        colors = {
            text: 'secondary.main',
            background: 'grey.25',
            border: 'grey.100',
        };
    }

    return (
        <Box
            sx={{
                color: colors.text,
                bgcolor: colors.background,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '80px',
                py: '16px',
                gap: '4px',
                borderLeft: '1px solid',
                borderColor: colors.border,
            }}
        >
            <Typography variant="body1">{dateTime.weekdayShort?.toUpperCase()}</Typography>
            <Typography variant="h6">{dateTime.day}</Typography>
        </Box>
    );
};

const ArrowButton = styled(Button)({
    minWidth: '36px',
    height: '36px',
    position: 'absolute',
    top: '50%',
    padding: 0,
    borderRadius: '50%',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    '& .MuiSvgIcon-root': {
        fontSize: 24,
    },
});

const WeekCalendarHeader = ({ scrollBarWidth }: { scrollBarWidth: number }) => {
    const { weekInterval, setWeekInterval, weekDays } = useWeekInterval();
    const scheduleGridSchema = useScheduleGridSchema({ interval: weekInterval });

    const isLoading = !weekInterval || !weekDays;

    if (isLoading) {
        return (
            <CalendarContainer container columns={GRID_COLUMNS}>
                <VerticalSpacer />

                {range(GRID_COLUMNS).map((i) => (
                    <CalendarCell
                        key={i}
                        xs
                        sx={({ palette }) => ({
                            bgcolor: 'white',
                            display: 'grid',
                            placeItems: 'center',
                            p: '16px',
                            borderLeft: i !== 0 ? `1px solid ${palette.grey[100]}` : undefined,
                        })}
                    >
                        <Skeleton width="48px" height="48px" />
                    </CalendarCell>
                ))}

                <VerticalSpacer />
            </CalendarContainer>
        );
    }

    return (
        <CalendarContainer container columns={GRID_COLUMNS}>
            <VerticalSpacer />

            <CalendarCell xs /* Skip shift info column */ />
            {weekDays.map((dateTime, dayIndex) => {
                const { isPreview, isWeekend } = scheduleGridSchema?.days[dayIndex] ?? {};

                return (
                    <CalendarCell key={dateTime.toMillis()} xs>
                        {dayIndex === 0 && ( // Previous week button needs to stick to the first day
                            <ArrowButton
                                onClick={() => setWeekInterval(weekInterval.start.minus({ weeks: 1 }))}
                                sx={{ left: 0, transform: 'translate(-50%, -50%)' }}
                            >
                                <CaretLeft weight="bold" />
                            </ArrowButton>
                        )}

                        <CalendarItem
                            dateTime={dateTime}
                            isPreview={isPreview ?? true}
                            isWeekend={isWeekend ?? false}
                            isToday={dateTime.hasSame(DateTime.now(), 'day')}
                        />
                    </CalendarCell>
                );
            })}

            {/* Next week button needs to stick to the end of the screen */}
            <ArrowButton
                onClick={() => setWeekInterval(weekInterval.start.plus({ weeks: 1 }))}
                sx={{ right: 8, transform: 'translate(0, -50%)' }}
            >
                <CaretRight weight="bold" />
            </ArrowButton>

            {/* Fix misaligned columns when the grid has a scrollbar */}
            <VerticalSpacer bgcolor="grey.25" paddingRight={`${8 + scrollBarWidth}px`} />
        </CalendarContainer>
    );
};

export default WeekCalendarHeader;
