import { Skeleton, Stack } from '@mui/material';
import range from 'lodash/range';
import React, { useMemo } from 'react';

import WithHeader from '~/components/Layout/WithHeader';
import { GetMyScheduleResult, useGetMySchedule } from '~/scheduling/api/queries/shift-slot/getMySchedule';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import { useWeekInterval } from '~/scheduling/useWeekInterval';
import { compareValue } from '~/scheduling/utils/compare';

import CalendarHeader, { SlotStatus } from '../CalendarHeader';
import SlotItem from '../SlotItem';

const FilledSlotsList = ({ slots, isPending }: { slots: GetMyScheduleResult['slots']; isPending: boolean }) => (
    <Stack p="16px" spacing="12px" sx={{ overflowY: 'auto' }}>
        {isPending
            ? range(8).map((i) => <Skeleton key={i} height="68px" sx={{ flexShrink: 0 }} />)
            : slots.map((slot) => <SlotItem key={slot.id} slot={slot} renderPending />)}
    </Stack>
);

const FilledSlotsTabPanel = () => {
    const { weekDayStrs } = useWeekInterval();

    const { data: roleData, isPending: isGetRolesPending } = useGetRoles();
    const roleShiftById = roleData?.roleShiftById;

    const { data: myScheduleData, isPending: isGetMySchedulePending } = useGetMySchedule();
    const slots = myScheduleData?.slots ?? [];

    const isPending = isGetRolesPending || isGetMySchedulePending;

    const filteredSlots = useMemo(
        () =>
            roleShiftById
                ? slots
                      .filter((slot) => slot.isPending || !slot.isAvailable) // Requested or filled
                      .sort((a, b) => {
                          const aShift = roleShiftById.get(a.roleShiftId)!;
                          const bShift = roleShiftById.get(b.roleShiftId)!;

                          return (
                              compareValue(a.shiftDay, b.shiftDay) ||
                              compareValue(aShift.index, bShift.index) ||
                              compareValue(a.locationId, b.locationId)
                          );
                      })
                : [],
        [slots]
    );

    const slotStatusesByDay = useMemo(() => {
        if (!weekDayStrs) return null;

        const map: Record<string, SlotStatus[]> = {};
        weekDayStrs.forEach((day) => (map[day] = []));
        filteredSlots.forEach((slot) => map[slot.shiftDay].push(slot.isPending ? 'pending' : 'filled'));
        return map;
    }, [weekDayStrs, filteredSlots]);

    return (
        <WithHeader
            mobileHeader={<CalendarHeader slotStatuses={slotStatusesByDay ? Object.values(slotStatusesByDay) : null} />}
        >
            <FilledSlotsList slots={filteredSlots} isPending={isPending} />
        </WithHeader>
    );
};

export default FilledSlotsTabPanel;
