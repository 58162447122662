import { Avatar, Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { Button } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import { PiChatTextBold, PiPhoneBold } from 'react-icons/pi';

import { useGetAgencyStaffList } from '~/scheduling/api/queries/agency-staff/getAgencyStaffList';
import { useGetLocations } from '~/scheduling/api/queries/locations/getLocations';
import { useGetFullSchedule } from '~/scheduling/api/queries/shift-slot/getFullSchedule';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import { useGetStaff } from '~/scheduling/api/queries/staff/getStaff';
import { GetFullSchedule } from '~/scheduling/api/types/shift-slot/getFullSchedule';
import { teamIdAtom } from '~/scheduling/atoms';
import SegmentedBottomSheet from '~/scheduling/components/SegmentedBottomSheet';
import { HorizontalSeparator } from '~/scheduling/components/shared';
import { SELECTOR_ALL_ID } from '~/scheduling/constants';
import { formatPhoneNumber } from '~/scheduling/utils/phoneNumber';

import { datePickerValueAtom, isSlotDetailsBottomSheetOpenAtom, selectedSlotIdAtom, selectedTabAtom } from '../../atom';
import { reasonSheetSlotIdAtom } from '../ChangeStaff/atoms';
import { Flags, SlotDetails, iconByFlagType } from '../SlotItem/shared';

const ChangeStaffAction = ({ slotId }: { slotId: number }) => {
    const toggleSlotDetailsSheet = useSetAtom(isSlotDetailsBottomSheetOpenAtom);
    const setChangeStaffSheetSlotId = useSetAtom(reasonSheetSlotIdAtom);

    return (
        <Button
            variant="outlined"
            color="error"
            onClick={() => {
                setChangeStaffSheetSlotId(slotId);
                toggleSlotDetailsSheet();
            }}
            fullWidth
        >
            Change Staff
        </Button>
    );
};

const FlagInfo = ({ flag }: { flag: GetFullSchedule.Flag }) => {
    const { palette } = useTheme();
    const { title, Icon, color, bgcolor } = iconByFlagType[flag.type];

    return (
        <Box
            display="flex"
            gap="8px"
            alignItems="center"
            padding="8px 16px"
            bgcolor={bgcolor}
            width="100%"
            borderRadius="100px"
        >
            <Icon size={20} color={color} weight="fill" />
            <Typography variant="body1" fontSize={16} fontWeight={600} color={palette.grey[900]}>
                {title}
            </Typography>
            {!!flag.message && (
                <Typography variant="body1" fontSize={14} fontWeight={400} color={palette.grey[600]}>
                    {flag.message}
                </Typography>
            )}
        </Box>
    );
};

export const SlotDetailsActions = ({ phoneNumber }: { phoneNumber: string }) => {
    const { palette } = useTheme();

    return (
        <Box display="flex" justifyContent="space-between" gap="12px">
            <Box>
                <Typography
                    variant="body1"
                    fontSize="13px"
                    fontWeight={400}
                    lineHeight="16px"
                    color={palette.grey[500]}
                >
                    Phone Number
                </Typography>
                <Box height="4px" />
                <Typography variant="body1" fontSize="16px" fontWeight={600} color={palette.grey[600]}>
                    {formatPhoneNumber(phoneNumber)}
                </Typography>
            </Box>
            <Box display="flex" gap="8px">
                <IconButton
                    href={`sms:${phoneNumber}`}
                    aria-label="text-message"
                    sx={{
                        color: palette.grey[600],
                        padding: '10px',
                        border: `1px solid ${palette.grey[100]}`,
                        borderRadius: '8px',
                    }}
                >
                    <PiChatTextBold size={16} />
                </IconButton>
                <IconButton
                    href={`tel:${phoneNumber}`}
                    aria-label="phone-call"
                    sx={{
                        color: palette.grey[600],
                        padding: '10px',
                        border: `1px solid ${palette.grey[100]}`,
                        borderRadius: '8px',
                    }}
                >
                    <PiPhoneBold size={16} />
                </IconButton>
            </Box>
        </Box>
    );
};

export const SlotDetailsBottomSheet = () => {
    const [isOpen, toggle] = useAtom(isSlotDetailsBottomSheetOpenAtom);

    const { palette } = useTheme();
    const slotId = useAtomValue(selectedSlotIdAtom);
    const selectedTab = useAtomValue(selectedTabAtom);
    const selectedDate = useAtomValue(datePickerValueAtom);
    const teamId = useAtomValue(teamIdAtom);

    const { data: schedule } = useGetFullSchedule({
        startDay: selectedDate,
        endDay: selectedDate,
        locationId: selectedTab !== SELECTOR_ALL_ID ? selectedTab : undefined,
        teamId,
    });
    const slot = slotId ? schedule?.slotById.get(slotId) : null;

    const { data: roleData } = useGetRoles();
    const roleById = roleData?.roleById;
    const role = slot ? roleById?.get(slot.roleId)?.name : null;

    const { data: locationData } = useGetLocations();
    const locationById = locationData?.locationById;
    const location = slot ? locationById?.get(slot.locationId)?.abbreviation : null;

    const { data: staff } = useGetStaff(slot?.staffId ?? undefined);
    const { data: agencyStaffList } = useGetAgencyStaffList();

    // TODO - change to laoding state
    if (!slot || (slot.staffId && !staff) || (slot.agencyStaffId && !agencyStaffList) || !location || !role)
        return null;

    const staffName = staff?.name ?? agencyStaffList?.agencyStaffById.get(slot.agencyStaffId!)?.name ?? '';

    const [firstName, lastName] = staffName.split(' ');

    const initials = (firstName.charAt(0) + (lastName?.charAt(0) ?? '')).toLocaleUpperCase();
    const flags = slot.flags.map(({ type }) => type);
    const hasFlags = flags.length > 0;

    return (
        <SegmentedBottomSheet
            isOpen={isOpen}
            onClose={toggle}
            header="Shift Details"
            actions={slotId && <ChangeStaffAction slotId={slotId} />}
            closeButton
        >
            <Stack spacing="20px">
                <Box display="flex" gap="12px" alignItems="center">
                    <Avatar
                        alt={staffName}
                        sx={{
                            width: '48px',
                            height: '48px',
                            borderRadius: '24px',
                            backgroundColor: palette.primary[50] as string,
                            color: palette.primary[400] as string,
                            fontSize: '20px',
                            fontWeight: 700,
                            fontFamily: 'Inter',
                            letterSpacing: '-0.8px',
                        }}
                    >
                        {initials}
                    </Avatar>
                    <Box flex={1}>
                        <Box display="flex" gap="4px" alignItems="center">
                            <Typography variant="body1" color={palette.grey[900]} fontWeight={700} fontSize="16px">
                                {staffName}
                            </Typography>
                            <Flags flags={flags} />
                        </Box>
                        <SlotDetails location={location} role={role} />
                    </Box>
                </Box>
                {hasFlags && slot.flags.map((flag) => <FlagInfo key={flag.type} flag={flag} />)}
                {staff?.phoneNumber ? (
                    <>
                        <HorizontalSeparator mt={hasFlags ? '12px !important' : undefined} />
                        <SlotDetailsActions phoneNumber={staff.phoneNumber} />
                    </>
                ) : null}
            </Stack>
        </SegmentedBottomSheet>
    );
};
