import { ArrowBack, ArrowBackIos, Menu } from '@mui/icons-material';
import { IconButton, Stack, ThemeProvider, styled } from '@mui/material';
import { useSetAtom } from 'jotai';
import React, { ReactNode } from 'react';
import { isIOS } from 'react-device-detect';
import { useNavigate } from 'react-router';

import { SingleLineTypography } from '~/components/Shared/SingleLineTypography';
import { useRouteHandle } from '~/router';
import theme from '~/scheduling/theme';

import { isDrawerOpenAtom } from './MobileDrawer';

const MobileHeaderContainer = styled(Stack)(({ theme: { palette } }) => ({
    flexShrink: 0,
    color: 'white',
    backgroundColor: palette.primary.main,
    height: '56px',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    padding: '0 20px 0 8px',
    zIndex: 10,
}));

const MobileHeaderDrawerButton = () => {
    const navigate = useNavigate();
    const { backOne, backTo } = useRouteHandle();

    const setIsDrawerOpen = useSetAtom(isDrawerOpenAtom);

    const BackIcon = isIOS ? ArrowBackIos : ArrowBack;

    const handleGoBackClick = () => {
        if (backOne) navigate(-1);
        else if (backTo) navigate(backTo, { replace: true });
    };

    return backOne || backTo ? (
        <IconButton size="large" onClick={handleGoBackClick} color="inherit">
            <BackIcon />
        </IconButton>
    ) : (
        <IconButton size="large" onClick={() => setIsDrawerOpen(true)} color="inherit">
            <Menu />
        </IconButton>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MobileHeaderTitle = () => {
    const { title } = useRouteHandle();

    return (
        <SingleLineTypography fontSize="20px" fontWeight={700} lineHeight="32px">
            {title}
        </SingleLineTypography>
    );
};

const MobileHeaderActions = ({ actions }: { actions?: React.ReactNode }) => {
    if (!actions) return null;

    return (
        <Stack
            sx={{
                flexDirection: 'row',
                alignItems: 'center',
                ml: 'auto',
            }}
        >
            {actions}
        </Stack>
    );
};

const MobileHeader = ({ actions }: { actions?: ReactNode }) => (
    // TODO: Remove after applying the Scheduling theme globally
    <ThemeProvider theme={theme}>
        <MobileHeaderContainer>
            <MobileHeaderDrawerButton />
            {/* <MobileHeaderTitle /> */}
            <MobileHeaderActions actions={actions} />
        </MobileHeaderContainer>
    </ThemeProvider>
);

export default MobileHeader;
