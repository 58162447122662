import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import { DateTime } from 'luxon';

import { atomWithToggle, guardAtom } from '../../../../../../utils/atoms';

// Having these separate allows for granular rerendering of the grid/cell components
export const slotItemIsDraggingAtom = atomWithReset(false);
export const slotItemDraggingIdAtom = atomWithReset<number | null>(null);
export const slotItemDraggingCoordsAtom = atomWithReset<[number, number] | null>(null);
export const slotItemDraggingOverIdAtom = atomWithReset<number | null>(null);
export const slotItemCanDropAtom = atomWithReset(false);
export const slotItemCannotDropReasonAtom = atomWithReset<string | null>(null);

export const filledSlotModalSlotIdAtom = atom<number | null>(null);
export const isFilledSlotModalOpenAtom = guardAtom(filledSlotModalSlotIdAtom);

export const filledSlotEditPartialStartTimeAtom = atom<DateTime | null>(null);
export const filledSlotEditPartialEndTimeAtom = atom<DateTime | null>(null);
export const isFilledSlotEditPartialModalOpenAtom = atomWithToggle(false, (_, set, value) => {
    // On modal close
    if (!value) {
        set(filledSlotEditPartialStartTimeAtom, null);
        set(filledSlotEditPartialEndTimeAtom, null);
    }
});

export const moveSlotModalSlotIdsAtom = atom<[number, number] | null>(null);
export const isMoveSlotModalOpenAtom = guardAtom(moveSlotModalSlotIdsAtom);

export const replaceSlotModalSlotIdsAtom = atom<[number, number] | null>(null);
export const isReplaceSlotModalOpenAtom = guardAtom(replaceSlotModalSlotIdsAtom);
