import { useCallback } from 'react';

import { api } from '~/scheduling/api';
import { queryClient, useBranchId, useErrorHandledMutation, useToken } from '~/scheduling/api/common';
import { UpdateSlotRequest } from '~/scheduling/api/types/shift-slot/updateSlotRequest';

export const useUpdateSlotRequest = (shouldInvalidateSlotRequestsQuery: boolean = true) => {
    const branchId = useBranchId()!;
    const token = useToken();

    const mutationFn = useCallback(
        async ({ slotId, requestId, data }: UpdateSlotRequest.Params) => {
            const options = { headers: { Authorization: token } };
            await api.patch(`/scheduling/${branchId}/shift-slot/${slotId}/request/${requestId}`, data, options);
        },
        [branchId, token]
    );

    const onSuccess = useCallback(async () => {
        // Schedule
        await queryClient.invalidateQueries({ queryKey: ['full-schedule', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['suggested-staff', branchId] });

        // Dashboard
        if (shouldInvalidateSlotRequestsQuery) {
            await queryClient.invalidateQueries({ queryKey: ['slot-requests', branchId] });
        }
        await queryClient.invalidateQueries({ queryKey: ['dashboard-alerts', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['open-shifts', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['budgeted-scheduled', branchId] });

        // Reports
        await queryClient.invalidateQueries({ queryKey: ['overtime-staff', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['scheduled-hours', branchId] });
    }, [branchId]);

    return useErrorHandledMutation({ mutationFn, onSuccess });
};
