import { Box, Typography, styled, useTheme } from '@mui/material';
import { DateTime, WeekdayNumbers } from 'luxon';
import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { PiWarningCircleFill } from 'react-icons/pi';

import { useGetCommunitySettings } from '~/scheduling/api/queries/community-settings/getCommunitySettings';
import { useGetWeekInfo } from '~/scheduling/api/queries/community-settings/getWeekInfo';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import { getWeekDays } from '~/scheduling/utils/dates';

import GridContainer from '../shared/GridContainer';

import { ScheduleDay } from './ScheduleDay';
import { mapBiweeklySchedule } from './mapStaffSchedule';
import { StaffScheduleProps } from './shared';

const formatDate = (date: string) => DateTime.fromISO(date).toFormat('ccc, LLL d');

const WeekInfoContainer = styled(Box)({
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '16px',
    paddingBottom: '8px',
    gap: '8px',
});

const WeekNumberLabel = styled(Typography)({
    fontSize: '16px',
    fontWeight: '700',
});

export const BiweeklySchedule = ({ form, schedule, scheduleIndex, isSaving, isEditing }: StaffScheduleProps) => {
    const { palette } = useTheme();
    const { data: roleData } = useGetRoles();
    const { data: communitySettings } = useGetCommunitySettings();
    const { data: weekInfo } = useGetWeekInfo();
    const firstDayOfWeek = communitySettings?.firstDayOfWeek;

    const scheduleData = React.useMemo(
        () =>
            schedule && roleData && firstDayOfWeek
                ? mapBiweeklySchedule(schedule, firstDayOfWeek, roleData.roleShiftById)
                : undefined,
        [schedule, firstDayOfWeek]
    );

    const getWeekLabel = useCallback(
        (weekIndex: number) => {
            if (!weekInfo) return null;

            const week = weekInfo[weekIndex];
            const label = week.current
                ? `(Current. Started ${formatDate(week.start)})`
                : `(Next starts ${formatDate(week.start)})`;

            return <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>{label}</Typography>;
        },
        [weekInfo]
    );

    return (
        <Controller
            name={`schedules.${scheduleIndex}.shifts`}
            control={form.control}
            rules={{
                validate: (data) =>
                    data
                        ?.flat()
                        .slice(0, 14)
                        .some(
                            ({ enabled, staffRoleId, locationId, staffRoleShiftId }) =>
                                enabled && staffRoleId && locationId && staffRoleShiftId
                        )
                        ? undefined
                        : 'At least 1 shift needs to be filled out',
            }}
            render={({ fieldState: { error } }) => (
                <>
                    {error && (
                        <Typography
                            variant="body1"
                            color={palette.error[500] as string}
                            fontWeight={400}
                            fontSize="12px"
                            display="flex"
                            gap="4px"
                            alignItems="center"
                        >
                            <PiWarningCircleFill display="inline-flex" size={12} color={palette.error[500] as string} />{' '}
                            {error.message}
                        </Typography>
                    )}

                    {scheduleData
                        ? scheduleData.map((scheduleDays, weekIndex) => (
                              <>
                                  <WeekInfoContainer>
                                      <WeekNumberLabel>{`Week ${weekIndex + 1}`}</WeekNumberLabel>
                                      {getWeekLabel(weekIndex)}
                                  </WeekInfoContainer>

                                  <GridContainer key={weekIndex} minmax={240}>
                                      {scheduleDays.map((scheduleDay, index) => (
                                          <ScheduleDay
                                              key={index + 'weekly'}
                                              scheduleIndex={scheduleIndex}
                                              title="day"
                                              weekIndex={+weekIndex}
                                              form={form}
                                              dayIndex={index}
                                              scheduleDay={scheduleDay}
                                              weekDay={DateTime.fromObject({
                                                  weekday: scheduleDay.dayIndex as WeekdayNumbers,
                                              }).toFormat('cccc')}
                                              withSwitch
                                              isEditing={isEditing || isSaving}
                                          />
                                      ))}
                                  </GridContainer>
                              </>
                          ))
                        : [0, 1].map((weekIndex) => (
                              <>
                                  <WeekInfoContainer>
                                      <WeekNumberLabel>{`Week ${weekIndex + 1}`}</WeekNumberLabel>
                                      {getWeekLabel(weekIndex)}
                                  </WeekInfoContainer>
                                  <GridContainer key={weekIndex} minmax={240}>
                                      {getWeekDays(DateTime.now(), firstDayOfWeek ?? 7).map(({ weekday }, index) => (
                                          <ScheduleDay
                                              key={index + 'weekly'}
                                              scheduleIndex={scheduleIndex}
                                              title="day"
                                              weekIndex={+weekIndex}
                                              form={form}
                                              dayIndex={index}
                                              weekDay={DateTime.fromObject({ weekday }).toFormat('cccc')}
                                              withSwitch
                                              isEditing={isSaving}
                                          />
                                      ))}
                                  </GridContainer>
                              </>
                          ))}
                </>
            )}
        />
    );
};
