import { Box, CircularProgress, Skeleton, Stack, Typography, styled, useTheme } from '@mui/material';
import { useAtomValue } from 'jotai';
import React, { useEffect, useMemo, useState } from 'react';
import { PiCalendarBlankFill } from 'react-icons/pi';

import { useGetLocations } from '~/scheduling/api/queries/locations/getLocations';
import { useGetSlotRequests } from '~/scheduling/api/queries/shift-slot/getSlotRequests';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import CustomConfetti from '~/scheduling/components/CustomConfetti';

import { handledShiftsAtom } from './atom';
import Header from './component/Header';
import { ShiftApprovalCards } from './component/ShiftApprovalCards';

const IconContainer = styled(Box)(({ theme }) => ({
    width: '56px',
    height: '56px',
    padding: '16px',
    backgroundColor: theme.palette.primary[50],
    borderRadius: '100px',
}));

const EmptyState = () => {
    const { palette } = useTheme();

    return (
        <Stack alignItems="center" height="100%" justifyContent="center" padding="24px">
            <IconContainer>
                <PiCalendarBlankFill size={24} color={palette.primary[600] as string} />
            </IconContainer>
            <Box height="32px" />
            <Typography color={palette.grey[900]} variant="h5" letterSpacing="unset">
                You are all set!
            </Typography>
            <Typography
                color={palette.grey[500]}
                variant="body1"
                textAlign="center"
                sx={{ textWrap: 'balance' }}
                fontWeight={400}
                marginTop="8px"
            >
                You have approved all shift applications. Great job!
            </Typography>
        </Stack>
    );
};

const LoadingState = () => (
    <Box width="100%" height="100%" padding="16px">
        <Skeleton width="100%" height="100%" />
    </Box>
);

const ShiftApprovalCounter = ({ shiftsToHandle }: { shiftsToHandle: number }) => {
    const { palette } = useTheme();
    const handledShifts = useAtomValue(handledShiftsAtom);

    const progress = shiftsToHandle > 0 ? Math.floor((100 * handledShifts) / shiftsToHandle) : 0;

    return (
        <Box display="flex" gap="8px" alignItems="center" color={palette.grey[25]}>
            <Box position="relative" height="24px">
                <CircularProgress
                    sx={{ position: 'absolute', color: 'rgba(255, 255, 255, 0.5)' }}
                    variant="determinate"
                    value={100}
                    size={24}
                    thickness={9}
                />
                <CircularProgress variant="determinate" value={progress} size={24} color="inherit" thickness={9} />
            </Box>
            <Typography variant="body1" fontWeight={500} fontSize="14px">
                <span style={{ fontWeight: 700, fontSize: '16px' }}>
                    {shiftsToHandle ? shiftsToHandle - handledShifts : 0}
                </span>{' '}
                Left
            </Typography>
        </Box>
    );
};

const ShiftApproval = () => {
    const [startParty, setStartParty] = useState(false);
    const handledShifts = useAtomValue(handledShiftsAtom);
    const {
        data: slotRequestsData,
        isLoading: isGetSlotRequestsLoading,
        error: getSlotRequestsError,
    } = useGetSlotRequests();
    const slotRequests = slotRequestsData ?? [];

    const { data: locationsData, isLoading: isGetLocationsLoading, error: getLocationError } = useGetLocations();
    const { data: rolesData, isLoading: isGetRolesLoading, error: getRolesError } = useGetRoles();

    const isLoading = isGetSlotRequestsLoading || isGetLocationsLoading || isGetRolesLoading;

    useEffect(() => {
        if (slotRequests.length && slotRequests.length === handledShifts) setStartParty(true);
    }, [handledShifts]);

    const shiftsToApprove = useMemo(() => {
        return slotRequests.map((slotRequest) => {
            const location = locationsData?.locationById.get(slotRequest.locationId);
            const role = rolesData?.roleById.get(slotRequest.roleId);
            const shift = role?.staffRoleShifts.find((shift) => shift.id === slotRequest.roleShiftId);

            return {
                ...slotRequest,
                // TODO - Use the correct value when backend is updated - AH-1512
                // https://linear.app/alliehealth/issue/AH-1512/shift-approval-refact-page-to-match-with-the-backend-update
                slotsIds: [slotRequest.id],
                // The case where there is no location, role, and shift is
                // handle by the if statement below checking the errors states
                locationName: location?.name ?? '',
                staffRoleName: role?.name ?? '',
                shiftName: shift?.name ?? '',
            };
        });
    }, [locationsData, rolesData, slotRequests]);

    if (getRolesError || getLocationError || getSlotRequestsError) return null;

    return (
        <Stack height="100%">
            <Header actions={<ShiftApprovalCounter shiftsToHandle={shiftsToApprove.length} />} />
            {isLoading ? (
                <LoadingState />
            ) : !shiftsToApprove.length || handledShifts === shiftsToApprove.length ? (
                <EmptyState />
            ) : (
                <ShiftApprovalCards shiftsToApprove={shiftsToApprove} />
            )}
            <CustomConfetti activate={startParty} stop={() => setStartParty(false)} />
        </Stack>
    );
};

export default ShiftApproval;
