import { DateTime } from 'luxon';

import { Scheduling } from '~/scheduling/types';

export const daysInTheMonth = (monthIndex: number, fullYear: number): number[] => {
    const startOfTheMonth = DateTime.fromObject({ year: fullYear, month: monthIndex + 1, day: 1 });
    const endOfTheMonth = startOfTheMonth.endOf('month');

    // diff method returns a float value, so we have to round it to an integer
    const daysBetween = Math.round(endOfTheMonth.diff(startOfTheMonth, 'days').toObject().days!);

    const days = Array.from({ length: daysBetween }, (_, index) => index + 1);

    return days;
};

export const weekIndexOfFirstDay = (monthIndex: number, fullYear: number): number => {
    return new Date(fullYear, monthIndex, 1).getDay();
};

const DAYS_IN_WEEK = 7;

export const daysMatrix = (monthIndex: number, fullYear: number): ('' | number)[][] => {
    const weekIndex = weekIndexOfFirstDay(monthIndex, fullYear);
    const days = daysInTheMonth(monthIndex, fullYear);
    const weekMatrix: ('' | number)[][] = [];

    // Start the first week with empty fields if the first day is not the first day of the week
    let week: ('' | number)[] = Array.from({ length: weekIndex }, () => '');

    days.forEach((day) => {
        // Add the week array to the matrix if the week is full
        if ((week.length + 1) % DAYS_IN_WEEK === 0) {
            week.push(day);
            weekMatrix.push(week);
            week = [];
        } else {
            week.push(day);
        }
    });

    // Add the last week with empty strings if the last day is not the last day of the week
    if (week.length > 0) {
        const emptySlots: ''[] = Array.from({ length: DAYS_IN_WEEK - week.length }, () => '');
        weekMatrix.push([...week, ...emptySlots]);
    }

    return weekMatrix;
};

export const currentMonthString = (monthIndex: number): string => {
    return new Date(new Date().getFullYear(), monthIndex).toLocaleString('en', { month: 'short' });
};

export const formatDateTimeToCalendarDate = (date: DateTime) => ({
    day: date.day,
    month: date.month - 1,
    year: date.year,
});

export const formatCalendarDateToDateTime = (date: Scheduling.CalendarData) => {
    const parsedDate = { ...date, month: date.month + 1 };
    return DateTime.fromObject(parsedDate);
};
