import { isDrawerOpenAtom } from '.';
import { Stack, useTheme } from '@mui/material';
import { Gear, Icon } from '@phosphor-icons/react';
import { useSetAtom } from 'jotai';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { SingleLineTypography } from '~/components/Shared/SingleLineTypography';
import { useSoftMatch } from '~/router/helpers';

const MobileDrawerDestination = ({
    title,
    startIcon: StartIcon,
    path,
}: {
    title: string;
    startIcon: Icon;
    path: string;
}) => {
    const { palette } = useTheme();

    const navigate = useNavigate();
    const isSelected = !!useSoftMatch(path);

    const setIsDrawerOpen = useSetAtom(isDrawerOpenAtom);

    return (
        <Stack
            onClick={() => {
                navigate(path);
                setIsDrawerOpen(false);
            }}
            sx={{
                bgcolor: isSelected ? (palette.primary[100] as string) : (palette.primary[50] as string),
                flexDirection: 'row',
                alignItems: 'center',
                gap: '8px',
                p: '12px 8px',
                borderRadius: '8px',
                userSelect: 'none',
                cursor: 'pointer',
                '&:hover': { bgcolor: palette.primary[200] as string },
                '& > svg': { fontSize: '24px !important' },
            }}
        >
            <StartIcon color={palette.grey[600]} weight="regular" />
            <SingleLineTypography sx={{ fontWeight: 600 }}>{title}</SingleLineTypography>
        </Stack>
    );
};

const MobileDrawerDestinations = () => (
    <Stack sx={{ flex: 1, gap: '8px', p: '16px' }}>
        <MobileDrawerDestination title="Settings" startIcon={Gear} path="/profile" />
    </Stack>
);

export default MobileDrawerDestinations;
