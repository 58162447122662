import { atom } from 'jotai';
import { DateTime } from 'luxon';

import { SELECTOR_ALL_ID } from '~/scheduling/constants';
import { atomWithToggle } from '~/scheduling/utils/atoms';

export const isDatePickerBottomSheetOpenAtom = atomWithToggle(false);
export const isSlotDetailsBottomSheetOpenAtom = atomWithToggle(false);

export const datePickerValueAtom = atom<DateTime>(DateTime.now());
export const selectedTabAtom = atom<number>(SELECTOR_ALL_ID);
export const selectedSlotIdAtom = atom<number | null>(null);
