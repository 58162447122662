import { Box, Typography, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import range from 'lodash/range';
import React from 'react';

import { SingleLineTypography } from '~/components/Shared/SingleLineTypography';
import { FullScheduleSlot } from '~/scheduling/api/queries/shift-slot/getFullSchedule';
import type { ScheduleGridSchema } from '~/scheduling/types';

import ShiftSlotCell from '../ShiftSlotCell';
import { GRID_COLUMNS } from '../shared';

import { Cell, CellContent } from './shared';

const RoleLocationCellContent = styled(CellContent)({
    justifyContent: 'space-between',
    padding: '12px',
});

const LocationChipBox = styled(Box)(({ theme: { palette } }) => ({
    color: palette.primary[500],
    backgroundColor: 'white',
    padding: '0 4px',
    borderRadius: '6px',
}));

const LocationOverBudgetOutline = styled(Box)(({ theme: { palette } }) => ({
    position: 'absolute',
    top: 1,
    left: 1,
    right: 1,
    bottom: 1,
    border: `1px solid ${palette.error.main}`,
    borderRadius: '8px',
    pointerEvents: 'none',
}));

const LocationOverBudgetCount = styled(Box)(({ theme: { palette } }) => ({
    color: 'white',
    backgroundColor: palette.error.main,
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translate(30%, -30%)',
    padding: '4px 10px',
    borderRadius: '12px',
    outline: '1px solid white',
    zIndex: 1,
}));

const RoleLocationCell = ({
    roleIndex,
    role,
    location,
}: {
    roleIndex: number;
    role: ScheduleGridSchema.Role<FullScheduleSlot>;
    location: ScheduleGridSchema.Location<FullScheduleSlot>;
}) => (
    <Cell
        sx={({ palette }) => ({
            bgcolor: palette.primary[300 + roleIndex * 100] as string, // primary.300, primary.400, etc.
            height: '44px',
            gap: '8px',
            borderBottom: '1px solid',
            borderColor: palette.grey[200],
        })}
    >
        <RoleLocationCellContent>
            <SingleLineTypography variant="body1" color="white" maxHeight={20}>
                {role.name}
            </SingleLineTypography>
            <LocationChipBox>
                <Typography variant="body1" fontSize="12px" fontWeight={600}>
                    {location.name}
                </Typography>
            </LocationChipBox>
        </RoleLocationCellContent>
    </Cell>
);

const LocationOverBudget = ({ overBudgetCount }: { overBudgetCount: number }) => (
    <LocationOverBudgetOutline>
        <LocationOverBudgetCount>
            <Typography fontSize="13px" fontWeight={600} lineHeight="16px">
                {/* Put a minus sign to mean "this many staff should be removed to be on budget" */}
                {`-${overBudgetCount}`}
            </Typography>
        </LocationOverBudgetCount>
    </LocationOverBudgetOutline>
);

const RoleLocationDayColumn = ({
    dayIndex,
    days,
    location,
}: {
    dayIndex: number;
    days: ScheduleGridSchema.Day[];
    location: ScheduleGridSchema.Location<FullScheduleSlot>;
}) => {
    const day = days[dayIndex];
    const slots = location.slotsByDay[dayIndex];
    const overBudgetCount = location.overBudgetCountByDay[dayIndex];

    return (
        <Grid
            container
            direction="column"
            sx={({ palette }) => ({
                position: 'relative',
                bgcolor: day.isPreview || day.isWeekend ? 'transparent' : palette.grey[50],
            })}
            xs
        >
            {slots.map((slot, index) => (
                <ShiftSlotCell
                    key={index}
                    slot={slot}
                    bgcolor={
                        // Preview and weekend cells should only have a background if they also have a slot,
                        // but they have different colors. Normal slots should always have a white background.
                        day.isPreview
                            ? slot
                                ? 'white' // To contrast with the gray slot button
                                : 'transparent'
                            : day.isWeekend
                              ? slot
                                  ? 'rgba(255, 255, 255, 0.5)' // To make the background stripes visible
                                  : 'transparent'
                              : 'white'
                    }
                />
            ))}
            {!!overBudgetCount && <LocationOverBudget overBudgetCount={overBudgetCount} />}
        </Grid>
    );
};

const RoleLocationGrid = ({
    roleIndex,
    role,
    days,
}: {
    roleIndex: number;
    role: ScheduleGridSchema.Role<FullScheduleSlot>;
    days: ScheduleGridSchema.Day[];
}) =>
    role.locations.map((location, locationIndex) => {
        const rowCount = location.slotsByDay[0]?.length ?? 0; // Every row should have the same number of slots

        return (
            <Grid key={locationIndex} container xs={GRID_COLUMNS} width="100%">
                <Grid container direction={'column'} xs>
                    {range(rowCount).map((rowIndex) => (
                        <RoleLocationCell key={rowIndex} roleIndex={roleIndex} role={role} location={location} />
                    ))}
                </Grid>
                {days.map((_, dayIndex) => (
                    <RoleLocationDayColumn key={dayIndex} dayIndex={dayIndex} days={days} location={location} />
                ))}
            </Grid>
        );
    });

export default RoleLocationGrid;
