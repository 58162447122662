import { Avatar, Box, Button, Chip, Stack, Typography, styled, useTheme } from '@mui/material';
import { PencilSimple, UserGear } from '@phosphor-icons/react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { DateTime } from 'luxon';
import React from 'react';

import { useGetAgencyStaffList } from '~/scheduling/api/queries/agency-staff/getAgencyStaffList';
import { useGetLocations } from '~/scheduling/api/queries/locations/getLocations';
import { FullScheduleSlot, useGetFullSchedule } from '~/scheduling/api/queries/shift-slot/getFullSchedule';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import { useGetStaffList } from '~/scheduling/api/queries/staff/getStaffList';
import CustomModal from '~/scheduling/components/CustomModal';
import { selectStaffSlotIdAtom } from '~/scheduling/pages/Schedule/Manager/shared/SelectStaff/atoms';

import { addTraineeModalSlotIdAtom, changeStaffModalSlotIdAtom } from '../../../../../atoms';
import {
    filledSlotEditPartialEndTimeAtom,
    filledSlotEditPartialStartTimeAtom,
    filledSlotModalSlotIdAtom,
    isFilledSlotEditPartialModalOpenAtom,
    isFilledSlotModalOpenAtom,
} from '../../../atoms';

import FilledSlotEditPartialModal from './FilledSlotEditPartialModal';

const FilledSlotHeader = () => (
    <Stack alignItems="center" spacing="24px">
        <FilledSlotAvatar />
        <FilledSlotDetails />
    </Stack>
);

const FilledSlotAvatar = () => {
    const { palette } = useTheme();

    return (
        <Box
            sx={{
                bgcolor: palette.grey[50],
                height: '88px',
                p: '32px',
                borderRadius: '50%',
            }}
        >
            <UserGear color={palette.grey[600]} weight="fill" fontSize="24px" />
        </Box>
    );
};

const FilledSlotDetails = () => {
    const slotId = useAtomValue(filledSlotModalSlotIdAtom);

    const { data: staffListData } = useGetStaffList();
    const staffById = staffListData?.staffById;

    const { data: agencyStaffListData } = useGetAgencyStaffList();
    const agencyStaffById = agencyStaffListData?.agencyStaffById;

    const { data: roleData } = useGetRoles();
    const roleById = roleData?.roleById;

    const { data: locationData } = useGetLocations();
    const locationById = locationData?.locationById;

    const { data: fullScheduleData } = useGetFullSchedule();
    const slotById = fullScheduleData?.slotById;

    if (!slotId || !slotById) return null;

    const slot = slotById.get(slotId);

    if (!slot) return null;

    const { shiftDay, roleId, locationId, staffId, agencyStaffId } = slot;

    const dayStr = DateTime.fromISO(shiftDay).toFormat('EEE, MMM d');
    const staff = staffId && staffById?.get(staffId)?.name;
    const agencyStaff = agencyStaffId && agencyStaffById?.get(agencyStaffId)?.name;
    const role = roleById?.get(roleId)?.name;
    const location = locationById?.get(locationId)?.abbreviation;
    // const trainee = null;

    return (
        <Stack alignItems="center" spacing="12px">
            <Typography variant="h4" sx={{ textAlign: 'center', textWrap: 'balance' }}>
                {staff ?? agencyStaff}
            </Typography>
            <FilledSlotChipRow>
                <Chip variant="outlined" color="primary" label={dayStr} />
                <FilledSlotShiftChip slot={slot} />
                <Chip variant="outlined" label={location} />
                <Chip variant="outlined" label={role} />
            </FilledSlotChipRow>
            {/* TODO: Uncomment after reverting https://linear.app/alliehealth/issue/AH-1293 */}
            {/* {trainee ? <FilledSlotTraineeDetails staff={trainee} /> : <FilledSlotAddTrainee />} */}
        </Stack>
    );
};

const FilledSlotShiftChip = ({ slot }: { slot: FullScheduleSlot }) => {
    const { palette } = useTheme();

    const setEditPartialStartTime = useSetAtom(filledSlotEditPartialStartTimeAtom);
    const setEditPartialEndTime = useSetAtom(filledSlotEditPartialEndTimeAtom);
    const toggleEditPartial = useSetAtom(isFilledSlotEditPartialModalOpenAtom);

    const { data: roleData } = useGetRoles();
    const roleShiftById = roleData?.roleShiftById;

    const { roleShiftId, startTime, endTime } = slot;
    const roleShift = roleShiftById?.get(roleShiftId);

    if (!roleShift) return null;

    const { name: shiftName, shiftStartTime, shiftEndTime } = roleShift;

    let formattedShift: string;

    if (startTime.toFormat('HH:mm:ss') === shiftStartTime && endTime.toFormat('HH:mm:ss') === shiftEndTime) {
        // No need to format a standard shift's start and end times (e.g. 'AM')
        formattedShift = shiftName;
    } else {
        // Use the least amount of characters to represent the time
        const startTimeFormat = startTime.minute === 0 ? 'h' : 'h:mm';
        const endTimeFormat = endTime.minute === 0 ? 'h' : 'h:mm';

        // Only show the meridiem if the start and end times have different ones
        const haveSameMeridiem = startTime.toFormat('a') === endTime.toFormat('a');
        const formattedStartTime = startTime.toFormat(haveSameMeridiem ? startTimeFormat : `${startTimeFormat} a`);
        const formattedEndTime = endTime.toFormat(`${endTimeFormat} a`);

        // e.g. '6 AM - 2 PM', '6 - 8 AM', '7:30 AM - 3:30 PM', etc
        formattedShift = `${formattedStartTime} - ${formattedEndTime}`;
    }

    return (
        <Chip
            variant="outlined"
            color="primary"
            label={
                <Stack direction="row" alignItems="center" spacing="6px">
                    <span>{formattedShift}</span>
                    <PencilSimple color={palette.primary.main} weight="fill" fontSize="16px" />
                </Stack>
            }
            sx={{
                cursor: 'pointer',
                '&:hover': { opacity: 0.8 },
                '&:active': { opacity: 0.7 },
            }}
            onClick={() => {
                setEditPartialStartTime(startTime);
                setEditPartialEndTime(endTime);
                toggleEditPartial();
            }}
        />
    );
};

const FilledSlotChipRow = styled(Stack)({
    flexDirection: 'row',
    gap: '8px',
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FilledSlotTraineeDetails = ({ staff }: { staff: string }) => {
    const { palette } = useTheme();

    const filledSlotModalSlotId = useAtomValue(filledSlotModalSlotIdAtom);
    const setAddTraineeModalSlotId = useSetAtom(addTraineeModalSlotIdAtom);

    return (
        <Stack direction="row" alignItems="center" spacing="8px">
            <Avatar sx={{ width: '24px', height: '24px' }} />
            <Typography variant="body1" fontSize="16px" fontWeight={600}>
                {staff}
            </Typography>
            <Box
                onClick={() => setAddTraineeModalSlotId(filledSlotModalSlotId)}
                sx={{
                    color: palette.grey[600],
                    width: '16px',
                    height: '16px',
                    cursor: 'pointer',
                    '&:hover': { opacity: 0.8 },
                    '&:active': { opacity: 0.7 },
                }}
            >
                <PencilSimple weight="fill" fontSize="16px" />
            </Box>
        </Stack>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FilledSlotAddTrainee = () => {
    const filledSlotModalSlotId = useAtomValue(filledSlotModalSlotIdAtom);
    const setAddTraineeModalSlotId = useSetAtom(addTraineeModalSlotIdAtom);

    return (
        <Typography
            variant="body1"
            fontSize="16px"
            fontWeight={700}
            onClick={() => setAddTraineeModalSlotId(filledSlotModalSlotId)}
            sx={{
                textDecoration: 'underline',
                cursor: 'pointer',
                '&:hover': { opacity: 0.8 },
                '&:active': { opacity: 0.7 },
            }}
        >
            Add trainee staff
        </Typography>
    );
};

const FilledSlotActions = () => {
    const slotId = useAtomValue(filledSlotModalSlotIdAtom);
    const toggle = useSetAtom(isFilledSlotModalOpenAtom);

    const setChangeStaffModalSlotId = useSetAtom(changeStaffModalSlotIdAtom);
    const setSelectStaffSlotId = useSetAtom(selectStaffSlotIdAtom);

    const { data: fullScheduleData } = useGetFullSchedule();
    const slotById = fullScheduleData?.slotById;

    if (!slotId || !slotById) return null;

    const slot = slotById.get(slotId);

    if (!slot) return null;

    return (
        <Stack spacing="8px">
            <Button
                size="large"
                onClick={() => {
                    // We only keep track of call-offs for internal staff
                    if (!slot.agencyStaffId) setChangeStaffModalSlotId(slotId);
                    else setSelectStaffSlotId(slotId);
                    toggle();
                }}
            >
                Change Staff
            </Button>
        </Stack>
    );
};

const FilledSlotModal = () => {
    const [isOpen, onClose] = useAtom(isFilledSlotModalOpenAtom);

    return (
        <>
            <CustomModal isOpen={isOpen} onClose={onClose} closeButton>
                <Stack p="32px" spacing="32px">
                    <FilledSlotHeader />
                    <FilledSlotActions />
                </Stack>
            </CustomModal>

            <FilledSlotEditPartialModal />
        </>
    );
};

export default FilledSlotModal;
