import { Skeleton, Stack, Typography, styled } from '@mui/material';
import { ArrowUUpLeft } from '@phosphor-icons/react';
import { DateTime, Interval } from 'luxon';
import React from 'react';

import { useWeekInterval } from '~/scheduling/useWeekInterval';

import { VerticalSeparator } from '../../../../../components/shared';

const formatPeriod = ({ start, end }: Interval<true>) => {
    const formatStart = start.toFormat('MMM d');
    const formatEnd = end.toFormat(start.month === end.month ? 'd, yyyy' : 'MMM d, yyyy');
    return `${formatStart} - ${formatEnd}`; // e.g. "Jan 1 - 7, 1970" or "Jan 31 - Feb 6, 1970"
};

const ItemSection = styled(Stack)({
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
});

const JumpButton = () => {
    const { setWeekInterval } = useWeekInterval();

    return (
        <ItemSection
            onClick={() => setWeekInterval(DateTime.now())}
            // A text button didn't work nicely here because padding and icon size are non-standard
            sx={({ palette }) => ({
                height: '36px',
                px: '8px',
                ml: '12px !important',
                cursor: 'pointer',
                userSelect: 'none',
                borderRadius: '8px',
                '&:hover': { bgcolor: palette.grey[50] },
                '&:active': { bgcolor: palette.grey[100] },
            })}
        >
            <ArrowUUpLeft weight="bold" />
            <Typography variant="body1" fontWeight={600}>
                Jump to this week
            </Typography>
        </ItemSection>
    );
};

const HeaderTitle = () => {
    const { weekInterval } = useWeekInterval();

    const now = DateTime.now();
    const isCurrentWeek = weekInterval?.contains(now);

    return (
        <Stack direction="row" alignItems="center" spacing="20px">
            {!weekInterval ? (
                <Skeleton width="160px" height="36px" />
            ) : (
                <>
                    <Typography variant="h6">{formatPeriod(weekInterval)}</Typography>
                    {!isCurrentWeek && (
                        <>
                            <VerticalSeparator height="20px" />
                            <JumpButton />
                        </>
                    )}
                </>
            )}
        </Stack>
    );
};

export default HeaderTitle;
