/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Skeleton, Stack, ToggleButton, ToggleButtonGroup, styled } from '@mui/material';
import { UserPlus } from '@phosphor-icons/react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { useMemo } from 'react';

import { useGetLocations } from '~/scheduling/api/queries/locations/getLocations';
import { useGetTeams } from '~/scheduling/api/queries/teams/getTeams';

import TeamSelector from '../../../../../components/TeamSelector';
import { VerticalSeparator } from '../../../../../components/shared';
import { SELECTOR_ALL_ID } from '../../../../../constants';
import { isAddShiftModalOpenAtom, selectedCareTypeIdAtom, selectedPeriodAtom } from '../atoms';
import { SchedulePeriod } from '../types';

const periodOptions: { value: SchedulePeriod; label: string }[] = [
    { value: 'day', label: 'Day' },
    { value: 'week', label: 'Week' },
];

const ItemSection = styled(Stack)({
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
});

const LocationSelector = () => {
    const [selectedCareTypeId, setSelectedCareTypeId] = useAtom(selectedCareTypeIdAtom);

    const { data: locationData } = useGetLocations();
    const locations = locationData?.locations ?? [];

    const locationOptions = useMemo(
        () => [
            { value: SELECTOR_ALL_ID, label: 'All' },
            ...locations
                .map(({ id, abbreviation }) => ({ value: id, label: abbreviation }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        ],
        [locations]
    );

    return (
        <ToggleButtonGroup
            value={selectedCareTypeId}
            onChange={(_, locationId: number) => locationId && setSelectedCareTypeId(locationId)}
            exclusive
        >
            {locationOptions.map(({ value, label }) => (
                <ToggleButton key={value} value={value}>
                    {label}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};

const PeriodSelector = () => {
    const [selectedPeriod, setSelectedPeriod] = useAtom(selectedPeriodAtom);

    return (
        <ToggleButtonGroup
            value={selectedPeriod}
            onChange={(_, period: SchedulePeriod) => period && setSelectedPeriod(period)}
            exclusive
        >
            {periodOptions.map(({ label, value }) => (
                <ToggleButton key={value} value={value}>
                    {label}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};

const HeaderActions = () => {
    const { data: teamData, isPending: isTeamsPending } = useGetTeams();
    const teams = teamData?.teams;

    const { data: locationData, isPending: isLocationsPending } = useGetLocations();
    const locations = locationData?.locations;

    const selectedPeriod = useAtomValue(selectedPeriodAtom);

    const toggleAddShiftModal = useSetAtom(isAddShiftModalOpenAtom);

    return (
        <Stack direction="row" alignItems="center" spacing="20px">
            <ItemSection>
                {isTeamsPending ? (
                    <Skeleton width="120px" height="36px" />
                ) : (
                    teams && teams.length > 1 && <TeamSelector />
                )}

                {isLocationsPending ? (
                    <Skeleton width="120px" height="36px" />
                ) : (
                    locations && locations.length > 1 && <LocationSelector />
                )}

                {/* TODO: Uncomment after reverting https://linear.app/alliehealth/issue/AH-1291 */}
                {/* <PeriodSelector /> */}
            </ItemSection>

            {/* TODO: Uncomment after reverting https://linear.app/alliehealth/issue/AH-1291 */}
            {/* <VerticalSeparator height="20px" />

            {selectedPeriod === 'day' ? (
                <Button size="small" startIcon={<UserPlus weight="bold" />} onClick={toggleAddShiftModal}>
                    Add Shift
                </Button>
            ) : (
                <Button
                    size="small"
                    startIcon={<UserPlus weight="bold" />}
                    onClick={() => {}} // TODO: Implement notify open shifts
                    sx={{ fontSize: '12px' }}
                >
                    Notify Open Shifts
                </Button>
            )} */}
        </Stack>
    );
};

export default HeaderActions;
