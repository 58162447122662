import { Box, Stack } from '@mui/material';
import momentTz from 'moment-timezone';
import React from 'react';
import { useSelector } from 'react-redux';

import { getOrderedShifts } from '@allie/utils/src/shifts';

import { useBranchShifts } from '~/api/queries/branch';
import { useRewardsHistoryQuery } from '~/api/queries/caregiverRewards';
import { pxToRem } from '~/components/theme/typography';
import PageStructure from '~/pages/PageStructure';
import { HistoryItem } from '~/types/myRewards';
import { ReduxStore } from '~/types/redux';

import { CheckInCalendar } from '../home/CheckInCalendar';

import { ShiftDaySection } from './ShiftDaySection';

const HistoryPage = () => {
    const { userId, branchId } = useSelector((state: ReduxStore) => state.session.sessionData);

    const branchShifts = useBranchShifts(branchId);
    const orderedShiftOptions = getOrderedShifts(branchShifts);
    const dynamicShiftOrder = orderedShiftOptions.reduce((acc, shift, index) => {
        acc[shift.id] = index + 1;
        return acc;
    }, {});

    const { data: rewardsHistoryData } = useRewardsHistoryQuery(userId, Number(branchId));

    const itemsByShiftDayDate: Record<string, HistoryItem[]> = {};
    rewardsHistoryData?.items.forEach((item) => {
        const shiftDayDate = item.shiftDayDate;
        if (!itemsByShiftDayDate[shiftDayDate]) {
            itemsByShiftDayDate[shiftDayDate] = [];
        }
        itemsByShiftDayDate[shiftDayDate].push(item);
    });

    const shiftDayDateItems = Object.values(itemsByShiftDayDate);
    shiftDayDateItems.sort((a, b) => momentTz(b[0].shiftDayDate).valueOf() - momentTz(a[0].shiftDayDate).valueOf());

    shiftDayDateItems.forEach((shiftDayDate) => {
        shiftDayDate.sort((a, b) => {
            if (!a.datetimeUtc && b.datetimeUtc) {
                return -1;
            }

            if (a.datetimeUtc && !b.datetimeUtc) {
                return 1;
            }

            if (!a.datetimeUtc && !b.datetimeUtc) {
                if (dynamicShiftOrder[a.shiftId] < dynamicShiftOrder[b.shiftId]) {
                    return -1;
                }

                if (dynamicShiftOrder[a.shiftId] > dynamicShiftOrder[b.shiftId]) {
                    return 1;
                }

                return 0;
            }

            return momentTz.utc(a.datetimeUtc).diff(momentTz.utc(b.datetimeUtc));
        });
    });

    const checkInByShiftDate = rewardsHistoryData?.checkInsByShiftDate ?? [];

    return (
        <PageStructure>
            {rewardsHistoryData && (
                <>
                    {checkInByShiftDate.length > 0 && <CheckInCalendar checkInByShiftDate={checkInByShiftDate} />}
                    <Stack spacing="24px">
                        {shiftDayDateItems.map((items) => (
                            <ShiftDaySection items={items} key={items[0].shiftDayDate} branchShifts={branchShifts} />
                        ))}
                    </Stack>
                </>
            )}
        </PageStructure>
    );
};

export default HistoryPage;
