import { Typography, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';

import { FullScheduleSlot } from '~/scheduling/api/queries/shift-slot/getFullSchedule';
import type { ScheduleGridSchema } from '~/scheduling/types';

import { GRID_COLUMNS } from '../shared';

import RoleLocationGrid from './RoleLocationGrid';
import { Cell, CellContent } from './shared';

const ShiftNameCell = styled(Cell)(({ theme: { palette } }) => ({
    backgroundColor: palette.primary[100],
    height: '40px',
    borderRight: '1px solid',
    borderColor: palette.grey[100],
}));

const ShiftNameCellContent = styled(CellContent)({
    padding: '8px 12px',
});

const ShiftHeaderCell = styled(Cell)({
    height: '40px',
    borderRight: '1px solid',
});

const ShiftHeader = ({ name, days }: { name: string; days: ScheduleGridSchema.Day[] }) => (
    <>
        <ShiftNameCell xs>
            <ShiftNameCellContent>
                <Typography color="primary.600" variant="h6">
                    {name}
                </Typography>
            </ShiftNameCellContent>
        </ShiftNameCell>
        {days.map((day, index) => (
            <ShiftHeaderCell
                key={index}
                xs
                sx={
                    day.isPreview || day.isWeekend
                        ? { bgcolor: 'transparent', borderColor: 'white' }
                        : { bgcolor: 'primary.50', borderColor: 'grey.100' }
                }
            />
        ))}
    </>
);

const ShiftSlotGrid = ({
    shift: { name, roles },
    days,
}: {
    shift: ScheduleGridSchema.Shift<FullScheduleSlot>;
    days: ScheduleGridSchema.Day[];
}) => (
    <Grid container xs={GRID_COLUMNS} width="100%">
        <ShiftHeader name={name} days={days} />
        {roles.map((role, roleIndex) => (
            <RoleLocationGrid key={role.id} roleIndex={roleIndex} role={role} days={days} />
        ))}
    </Grid>
);

export default ShiftSlotGrid;
