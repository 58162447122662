import { TabPanel, TabPanelProps } from '@mui/lab';
import { Box, Stack, Typography, styled } from '@mui/material';
import {
    Icon,
    NumberCircleFive,
    NumberCircleFour,
    NumberCircleOne,
    NumberCircleThree,
    NumberCircleTwo,
} from '@phosphor-icons/react';
import { useAtomValue } from 'jotai';
import React from 'react';

import { FullScheduleSlot } from '~/scheduling/api/queries/shift-slot/getFullSchedule';

import { selectStaffSlotIdAtom } from '../atoms';

import NotOngoingNotifyStaffTabPanel from './NotOngoingNotifyStaffTabPanel';
import OngoingNotifyStaffTabPanel from './OngoingNotifyStaffTabPanel';

export const numberIcons = [
    NumberCircleOne,
    NumberCircleTwo,
    NumberCircleThree,
    NumberCircleFour,
    NumberCircleFive,
] as Icon[];

export const NotifyStaffTimelineConnector = styled(Box)(({ theme: { palette } }) => ({
    backgroundColor: palette.grey[100],
    width: '1px',
    position: 'absolute',
    top: '8px',
    bottom: '40px',
    left: '16px',
}));

export const NotifyStaffGroupsStack = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    position: 'relative',
});

export const NotifyStaffGroupContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
});

export const NotifyStaffGroupIconNameContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
});

export const NotifyStaffGroupIconBox = styled(Box)(({ theme: { palette } }) => ({
    backgroundColor: 'white',
    width: '32px',
    height: '32px',
    display: 'grid',
    placeItems: 'center',
    borderRadius: '50%',
    border: '1px solid',
    borderColor: palette.grey[100],
    zIndex: 2,
}));

export const NotifyStaffGroupNameContainer = styled(Box)(({ theme: { palette } }) => ({
    flex: 1,
    backgroundColor: palette.grey[25],
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    padding: '8px',
    borderRadius: '6px',
}));

export const NotifyStaffGroupName = styled(Typography)({
    fontSize: '15px',
});

export const NotifyStaffGroupCaptionRow = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '48px',
    gap: '4px',
    '& .MuiTypography-root': {
        fontSize: '12px',
        lineHeight: '16px',
    },
});

export const NotifyStaffGroupCaptionMessage = styled(Typography)(({ theme: { palette } }) => ({
    color: palette.grey[500],
    fontWeight: 400,
}));

export const NotifyStaffGroupCaptionValue = styled(Typography)<{ color?: string }>(({ theme: { palette }, color }) => ({
    color: color ?? palette.primary.main,
    fontWeight: 700,
}));

export const NotifyStaffGroupNotesContainer = styled(Box)(({ theme: { palette } }) => ({
    display: 'flex',
    gap: '8px',
    padding: '10px 12px',
    backgroundColor: palette.grey[25],
    color: palette.grey[500],
    border: '1px solid',
    borderColor: palette.grey[100],
    borderRadius: '8px',
}));

export const NotifyStaffGroupNotesIconBox = styled(Box)({
    width: '20px',
    height: '20px',
    display: 'grid',
    placeItems: 'center',
});

export const NotifyStaffGroupNotes = styled(Typography)({
    fontWeight: 400,
});

interface NotifyStaffTabPanelProps extends TabPanelProps {
    slotById: Map<number, FullScheduleSlot> | undefined;
}

const NotifyStaffTabPanel = (props: NotifyStaffTabPanelProps) => {
    const slotId = useAtomValue(selectStaffSlotIdAtom);

    if (!slotId || !props.slotById) return null;

    const slot = props.slotById.get(slotId);

    return (
        <TabPanel {...props}>
            <Stack spacing="16px">
                {slot?.teamNotifications.length ? (
                    <OngoingNotifyStaffTabPanel teamNotifications={slot.teamNotifications} />
                ) : (
                    <NotOngoingNotifyStaffTabPanel />
                )}
            </Stack>
        </TabPanel>
    );
};

export default NotifyStaffTabPanel;
