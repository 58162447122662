import { useAtomValue } from 'jotai';
import React from 'react';

import DesktopHeader from '~/components/Layout/DesktopHeader';
import WithHeader from '~/components/Layout/WithHeader';

import ConfirmStaffModal from '../shared/SelectStaff/ConfirmStaffModal';

import { selectedPeriodAtom } from './atoms';
import HeaderActions from './components/HeaderActions';
import HeaderTitle from './components/HeaderTitle';
import AddShiftModal from './components/Modal/AddShiftModal';
import AddTraineeModal from './components/Modal/AddTraineeModal';
import ChangeStaffModal from './components/Modal/ChangeStaffModal';
import SelectStaffModal from './components/Modal/SelectStaffModal';
import Week from './components/Week';

const Schedule = () => {
    const selectedPeriod = useAtomValue(selectedPeriodAtom);

    return (
        <WithHeader header={<DesktopHeader title={<HeaderTitle />} actions={<HeaderActions />} />}>
            {selectedPeriod === 'week' && <Week />}

            <AddShiftModal />
            <AddTraineeModal />
            <ChangeStaffModal />
            <SelectStaffModal />
            <ConfirmStaffModal />
        </WithHeader>
    );
};

export default Schedule;
