import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import React, { ComponentProps, memo, useEffect, useMemo, useRef } from 'react';

import { SHIFT_SLOT_STATUS } from '@allie/utils/src/constants/scheduling/shift-slot.constants';

import { useGetAgencyStaffList } from '~/scheduling/api/queries/agency-staff/getAgencyStaffList';
import { FullScheduleSlot, useGetFullSchedule } from '~/scheduling/api/queries/shift-slot/getFullSchedule';
import { useGetStaffList } from '~/scheduling/api/queries/staff/getStaffList';
import { isSlotInTheFuture } from '~/scheduling/pages/Schedule/Manager/shared/isSlotInTheFuture';

import {
    filledSlotModalSlotIdAtom,
    replaceSlotModalSlotIdsAtom,
    slotItemCanDropAtom,
    slotItemCannotDropReasonAtom,
    slotItemDraggingCoordsAtom,
    slotItemDraggingIdAtom,
    slotItemDraggingOverIdAtom,
    slotItemIsDraggingAtom,
} from '../../atoms';

import { NonDraggingSlotItemButton, SlotItemBox, SlotItemButton, SlotItemText, isInsideRect } from './shared';

const SLOT_ITEM_BUTTON_COLOR = '#D7E8EA';

// Rect bounds, offsetX/Y or clientX/Y are all relative to the first static parent position,
// so we need to them into consideration (shouldn't loop more than 3-4 times)
const getElementCoords = (element: HTMLElement) => {
    let x = 0;
    let y = 0;

    // Offsets must be calculated relative to the first static parent
    let currentElement: HTMLElement | null = element;
    while (currentElement) {
        x += currentElement.offsetLeft;
        y += currentElement.offsetTop;
        currentElement = currentElement.offsetParent as HTMLElement | null;
    }

    // Scroll must be calculated all the way up to the window
    currentElement = element;
    while (currentElement) {
        x -= currentElement.scrollLeft;
        y -= currentElement.scrollTop;
        currentElement = currentElement.parentElement;
    }

    return [x, y];
};

const DraggingFilledSlotItem = ({ slot: { status, staffId, agencyStaffId } }: { slot: FullScheduleSlot }) => {
    const { palette } = useTheme();

    const { data: staffListData } = useGetStaffList();
    const staff = staffId ? staffListData?.staffById.get(staffId)?.name : undefined;

    const { data: agencyStaffListData } = useGetAgencyStaffList();
    const agencyStaff = agencyStaffId ? agencyStaffListData?.agencyStaffById.get(agencyStaffId)?.name : undefined;

    const ref = useRef<HTMLDivElement | null>(null);
    const { current } = ref;

    const [slotItemDraggingX, slotItemDraggingY] = useAtomValue(slotItemDraggingCoordsAtom) ?? [0, 0];
    const [x, y] = current ? getElementCoords(current) : [0, 0];

    const deltaX = slotItemDraggingX - x;
    const deltaY = slotItemDraggingY - y;

    const slotItemCanDrop = useAtomValue(slotItemCanDropAtom);
    const slotItemCannotDropReason = useAtomValue(slotItemCannotDropReasonAtom);

    const colors =
        status === SHIFT_SLOT_STATUS.DRAFT
            ? {
                  text: palette.grey[600],
                  background: palette.grey[50],
              }
            : {
                  text: palette.grey[900],
                  background: SLOT_ITEM_BUTTON_COLOR,
              };

    return (
        <SlotItemBox>
            <Tooltip
                title={
                    <Box p="4px 8px">
                        <Typography color={palette.grey[900]}>{slotItemCannotDropReason}</Typography>
                    </Box>
                }
                placement="top"
                open={!slotItemCanDrop && !!slotItemCannotDropReason}
                TransitionProps={{ timeout: 0 }}
            >
                <SlotItemButton
                    ref={ref}
                    sx={{
                        color: colors.text,
                        bgcolor: colors.background,
                        width: '100%',
                        position: 'relative', // Fixes z-index
                        transform: current ? `translate(calc(${deltaX}px - 50%), calc(${deltaY}px - 50%))` : undefined,
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        zIndex: 10,
                        cursor: slotItemCanDrop ? 'move' : 'not-allowed',
                    }}
                >
                    <SlotItemText text={(staff ?? agencyStaff)!} />
                    {/* Not putting flags here because they're slot-specific */}
                </SlotItemButton>
            </Tooltip>
        </SlotItemBox>
    );
};

const NonDraggingFilledSlotItemButton = ({ slot }: { slot: FullScheduleSlot }) => {
    const { palette } = useTheme();

    const { id, status, staffId, agencyStaffId } = slot;

    const { data: staffListData } = useGetStaffList();
    const staff = staffId ? staffListData?.staffById.get(staffId)?.name : undefined;

    const { data: agencyStaffListData } = useGetAgencyStaffList();
    const agencyStaff = agencyStaffId ? agencyStaffListData?.agencyStaffById.get(agencyStaffId)?.name : undefined;

    const ref = useRef<HTMLDivElement | null>(null);

    const setSlotItemIsDragging = useSetAtom(slotItemIsDraggingAtom);
    const setSlotItemDraggingId = useSetAtom(slotItemDraggingIdAtom);
    const setSlotItemDraggingCoords = useSetAtom(slotItemDraggingCoordsAtom);
    const setFilledSlotModalSlotId = useSetAtom(filledSlotModalSlotIdAtom);

    const startSlotItemDragging = (x: number, y: number) => {
        setSlotItemIsDragging(true);
        setSlotItemDraggingId(id);
        setSlotItemDraggingCoords([x, y]);
    };

    useEffect(() => {
        const { current } = ref;
        if (!current) return;

        const handleTouchStart = (event: TouchEvent) => {
            event.preventDefault(); // Prevent scrolling

            const { clientX, clientY } = event.touches[0];
            startSlotItemDragging(clientX, clientY);
        };

        // Manually add event as 'passive: false' to allow preventDefault()
        // https://developer.chrome.com/blog/passive-event-listeners#how_it_works
        current.addEventListener('touchstart', handleTouchStart, { passive: false });

        return () => current.removeEventListener('touchstart', handleTouchStart);
    }, []);

    const slotInTheFuture = useMemo(() => isSlotInTheFuture(slot), [slot]);

    const colors =
        status === SHIFT_SLOT_STATUS.DRAFT
            ? {
                  text: palette.grey[600],
                  background: palette.grey[50],
              }
            : {
                  text: palette.grey[900],
                  background: SLOT_ITEM_BUTTON_COLOR,
              };

    return (
        <NonDraggingSlotItemButton
            ref={ref}
            onMouseDown={({ button, clientX, clientY }) =>
                button === 0 && slotInTheFuture && startSlotItemDragging(clientX, clientY)
            }
            onClick={() => !slotInTheFuture && setFilledSlotModalSlotId(slot.id)}
            color={colors.text}
            bgcolor={colors.background}
            slot={slot}
            text={(staff ?? agencyStaff)!}
            sx={{ cursor: slotInTheFuture ? 'move' : 'pointer' }}
        />
    );
};

const MemoizedNonDraggingFilledSlotItemButton = memo(NonDraggingFilledSlotItemButton);

const NonDraggingFilledSlotItemButtonBox = ({
    isDraggingOver,
    canDropHere,
    ...props
}: {
    slot: FullScheduleSlot;
    isDraggingOver: boolean;
    canDropHere: boolean;
}) => (
    <Box
        sx={
            isDraggingOver && canDropHere
                ? ({ palette }) => ({
                      outline: `2px dashed ${palette.primary[500]}`,
                      borderRadius: '6px',
                  })
                : undefined
        }
    >
        {/* This makes it so even hovering over this element won't trigger
        updates to the inner button if the data doesn't change */}
        <MemoizedNonDraggingFilledSlotItemButton {...props} />
    </Box>
);

const MemoizedNonDraggingFilledSlotItemButtonBox = memo(NonDraggingFilledSlotItemButtonBox);

const NonDraggingFilledSlotItem = ({
    slot,
    ...props
}: {
    slot: FullScheduleSlot;
} & Omit<ComponentProps<typeof SlotItemBox>, 'slot'>) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const { current } = ref;

    const setFilledSlotModalSlotId = useSetAtom(filledSlotModalSlotIdAtom);
    const setReplaceSlotModalSlotIds = useSetAtom(replaceSlotModalSlotIdsAtom);

    const slotItemIsDragging = useAtomValue(slotItemIsDraggingAtom);
    const slotItemDraggingId = useAtomValue(slotItemDraggingIdAtom);
    const resetSlotItemDraggingId = useResetAtom(slotItemDraggingIdAtom);
    const [slotItemDraggingX, slotItemDraggingY] = useAtomValue(slotItemDraggingCoordsAtom) ?? [];

    const { data: fullScheduleData } = useGetFullSchedule();
    const slotById = fullScheduleData?.slotById;
    const slotItemDraggingSlot = slotById?.get(slotItemDraggingId!);

    const [slotItemDraggingOverId, setSlotItemDraggingOverId] = useAtom(slotItemDraggingOverIdAtom);
    const setSlotItemCanDrop = useSetAtom(slotItemCanDropAtom);
    const setSlotItemCannotDropReason = useSetAtom(slotItemCannotDropReasonAtom);

    // Standard events don't work because this is under the element being dragged
    const isMouseOver = useMemo(() => {
        if (!current || !slotItemDraggingX || !slotItemDraggingY) return false;
        return isInsideRect(slotItemDraggingX, slotItemDraggingY, current.getBoundingClientRect());
    }, [current, slotItemDraggingX, slotItemDraggingY]);

    const isDraggingOver = slotItemIsDragging && isMouseOver;

    const isSameStaff = useMemo(
        () =>
            slotItemDraggingSlot?.staffId == slot.staffId && slotItemDraggingSlot?.agencyStaffId == slot.agencyStaffId,
        [slotItemDraggingSlot, slot]
    );

    const isInTheFuture = useMemo(() => isSlotInTheFuture(slot), [slot]);

    const canDropHere = useMemo(
        () => (slotItemDraggingId === slot.id || !isSameStaff) && isInTheFuture,
        [slotItemDraggingSlot, slot, isSameStaff]
    );

    useEffect(() => {
        if (isDraggingOver) {
            const sameStaffMessage = isSameStaff ? 'Staff is already on this shift!' : null;
            const slotInThePastMessage = !isInTheFuture ? 'This shift is in the past!' : null;
            const cannotDropReason = slotInThePastMessage || sameStaffMessage;

            setSlotItemDraggingOverId(slot.id);
            setSlotItemCanDrop(canDropHere);
            setSlotItemCannotDropReason(cannotDropReason);
        } else if (slotItemDraggingOverId === slot.id) {
            setSlotItemDraggingOverId(null);
            setSlotItemCanDrop(false);
            setSlotItemCannotDropReason(null);
        } else if (!slotItemDraggingOverId) {
            setSlotItemCanDrop(false);
        }
    }, [slotItemDraggingOverId, isDraggingOver, canDropHere, isSameStaff]);

    useEffect(() => {
        if (!slotItemIsDragging && slotItemDraggingId && isMouseOver && canDropHere) {
            if (slotItemDraggingId === slot.id) setFilledSlotModalSlotId(slot.id);
            else setReplaceSlotModalSlotIds([slotItemDraggingId, slot.id]);

            resetSlotItemDraggingId();
        }
    }, [slotItemIsDragging, slotItemDraggingId, isMouseOver, canDropHere]);

    return (
        <SlotItemBox ref={ref} {...props}>
            {/* This makes it so things down the line will only rerender
            if the params change, saving a lot of performance */}
            <MemoizedNonDraggingFilledSlotItemButtonBox
                slot={slot}
                isDraggingOver={slotItemIsDragging && isMouseOver}
                canDropHere={canDropHere}
            />
        </SlotItemBox>
    );
};

const FilledSlotItem = ({ slot }: { slot: FullScheduleSlot }) => {
    const slotItemIsDragging = useAtomValue(slotItemIsDraggingAtom);
    const slotItemDraggingId = useAtomValue(slotItemDraggingIdAtom);
    const isThisDragging = slotItemIsDragging && slotItemDraggingId === slot.id;

    return (
        <>
            {/* Using a separate dragging component makes it easier to customize */}
            {isThisDragging ? <DraggingFilledSlotItem slot={slot} /> : null}
            <NonDraggingFilledSlotItem
                slot={slot}
                // The clicked element must still exist after a touch event takes place, otherwise
                // the chain (touchstart -> touchmove) gets cancelled altogether, so we just hide it
                sx={{ display: isThisDragging ? 'none' : undefined }}
            />
        </>
    );
};

export default FilledSlotItem;
