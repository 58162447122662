import { Box, Button, Typography, styled } from '@mui/material';

import { pxToRem } from '~/components/theme/typography';

export const FullScreenContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        bgcolor: 'white',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: pxToRem(32),
        gap: pxToRem(32),
        zIndex: 9999,
    })
);

export const CenteredText = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: '#6F6F79',
        fontSize: pxToRem(16),
        textAlign: 'center',
    })
);

export const CustomButtonContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        gap: '8px',
        width: { xs: 'calc(100% - 2rem)', lg: 'auto' },
        position: { xs: 'absolute', lg: 'static' },
        bottom: { xs: '1rem', lg: 'auto' },
    })
);

export const CustomButton = styled(Button)(({ theme }) =>
    theme.unstable_sx({
        width: { xs: '100%', lg: 'auto' },
        whiteSpace: 'nowrap',
        fontSize: pxToRem(16),
        px: pxToRem(32),
        py: pxToRem(16),
        boxShadow: 'none',
        '&:hover': { boxShadow: 'none' },
        '&:active': { boxShadow: 'none' },
    })
);
