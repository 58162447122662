import { useAtomValue } from 'jotai';
import { DateTime } from 'luxon';
import { useCallback } from 'react';

import { api } from '~/scheduling/api';
import { GetFullSchedule } from '~/scheduling/api/types/shift-slot/getFullSchedule';
import { selectedCareTypeIdAtom } from '~/scheduling/pages/Schedule/Manager/Desktop/atoms';
import { useWeekInterval } from '~/scheduling/useWeekInterval';

import { queryClient, useBranchId, useErrorHandledQuery, useToken } from '../../common';

export type GetFullScheduleResult = NonNullable<ReturnType<typeof useGetFullSchedule>['data']>;
export type FullScheduleSlot = GetFullScheduleResult['slots'][number];

interface UseGetFullScheduleParams {
    startDay?: DateTime<true>;
    endDay?: DateTime<true>;
    teamId?: number;
    locationId?: number;
}

export const useGetFullSchedule = (props?: UseGetFullScheduleParams) => {
    const branchId = useBranchId()!;
    const token = useToken();

    const { weekInterval } = useWeekInterval();
    // const teamId = useAtomValue(teamIdAtom);
    const selectedLocationId = useAtomValue(selectedCareTypeIdAtom);

    const params = weekInterval
        ? ({
              startDay: (props?.startDay ?? weekInterval.start).toISODate(),
              endDay: (props?.endDay ?? weekInterval.end).toISODate(),
              // TODO: Uncomment after reverting https://linear.app/alliehealth/issue/AH-1269
              teamId: props?.teamId, // ?? teamId,
              locationId: props?.locationId ?? (selectedLocationId > 0 ? selectedLocationId : undefined),
          } satisfies GetFullSchedule.Params)
        : null;

    const queryFn = useCallback(async () => {
        const options = { headers: { Authorization: token }, params };
        const { data } = await api.get<GetFullSchedule.Response>(
            `/scheduling/${branchId}/shift-slot/full-schedule`,
            options
        );

        const slots = data.response.map(remapSlot);
        const slotById = new Map(slots.map((slot) => [slot.id, slot]));

        await queryClient.invalidateQueries({ queryKey: ['suggested-staff', branchId] });

        return { slots, slotById };
    }, [branchId, params, token]);

    return useErrorHandledQuery({
        queryKey: ['full-schedule', branchId, params],
        queryFn,
        enabled: !!params,
        staleTime: 30000,
    });
};

const remapSlot = ({ startTime, endTime, teamNotifications, ...slot }: GetFullSchedule.Slot) => ({
    ...slot,
    startTime: fromISO(startTime),
    endTime: fromISO(endTime),
    teamNotifications: teamNotifications.map(remapStaffTypeNotification),
});

const remapStaffTypeNotification = ({ notifyAt, ...notification }: GetFullSchedule.TeamNotification) => ({
    ...notification,
    notifyAt: fromISO(notifyAt),
});

const fromISO = (date: string) => DateTime.fromISO(date, { setZone: true }) as DateTime<true>;
