import { Box, Tooltip, Typography, styled, useTheme } from '@mui/material';
import { Clock, Icon, Info, ProhibitInset, Users, Warning } from '@phosphor-icons/react';
import { useAtomValue } from 'jotai';
import React, { ComponentProps, forwardRef } from 'react';

import { SHIFT_SLOT_FLAG } from '@allie/utils/src/constants/scheduling/shift-slot.constants';

import { SingleLineTypography } from '~/components/Shared/SingleLineTypography';
import { FullScheduleSlot } from '~/scheduling/api/queries/shift-slot/getFullSchedule';

import palette from '../../../../../../../../theme/palette';
import { slotItemIsDraggingAtom } from '../../atoms';

const FLAG_DETAILS_BY_FLAG: Record<SHIFT_SLOT_FLAG, { title: string; icon: Icon; color: string }> = {
    WORK_RESTRICTION: { title: 'Work Restriction', icon: ProhibitInset, color: '#FFB800' },
    OVERTIME: { title: 'Overtime', icon: Warning, color: palette.secondary!['main'] as string },
    TOO_FEW_HOURS: { title: 'Too Few Hours', icon: Clock, color: palette.primary![500] as string },
    WITH_TRAINEE: { title: 'With Trainee', icon: Users, color: palette.grey![400] as string },
    PARTIAL_SHIFT: { title: 'Partial Shift', icon: Info, color: palette.grey![500] as string },
    NOTIFIED_REGULAR: { title: 'Team Notified', icon: Clock, color: palette.secondary![500] as string },
    NOTIFIED_ATTENTION: { title: 'Team Notified', icon: Clock, color: palette.error![600] as string },
};

export const isInsideRect = (x: number, y: number, rect: DOMRect) => {
    const { left, right, top, bottom } = rect;
    return x >= left && x <= right && y >= top && y <= bottom;
};

export const SlotItemBox = styled(Box)({
    // Make everything inside a cell render on the same place so we can
    // detect when a button is dropped on itself and call it a "click"
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: '4px',
});

export const SlotItemButton = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    gap: '8px',
    borderRadius: '6px',
    '& > svg': { flexShrink: 0 },
});

export const NonDraggingSlotItemButton = forwardRef(function NonDraggingSlotItemButton(
    {
        slot,
        text,
        sx,
        ...props
    }: { slot: FullScheduleSlot; text: string } & Omit<ComponentProps<typeof SlotItemButton>, 'slot'>,
    ref
) {
    const slotItemIsDragging = useAtomValue(slotItemIsDraggingAtom);

    return (
        <SlotItemButton
            ref={ref}
            sx={{
                width: '100%',
                transition: 'all 0.1s linear',
                transitionProperty: 'opacity, transform',
                '&:hover': { opacity: 0.75 },
                ...(slotItemIsDragging && {
                    opacity: 0.75,
                    transform: 'scale(0.9)',
                }),
                ...sx,
            }}
            {...props}
        >
            <SlotItemText text={text} />
            {slot.flags?.map((flag, index) => <SlotItemFlag key={index} flag={flag} />)}
        </SlotItemButton>
    );
});

export const SlotItemText = ({ text }: { text: string }) => (
    <SingleLineTypography variant="body1" fontWeight={600} maxHeight={20}>
        {text}
    </SingleLineTypography>
);

const SlotItemFlagTitle = ({ title, message }: { title: string; message?: string }) => {
    const { palette } = useTheme();

    return (
        <Box p="4px 8px">
            <Typography variant="body1" color={palette.grey[900]} fontSize="13px" fontWeight={600} lineHeight="16px">
                {title}
            </Typography>
            {message && (
                <Typography
                    variant="body1"
                    color={palette.grey[600]}
                    fontSize="11px"
                    fontWeight={400}
                    lineHeight="16px"
                >
                    {message}
                </Typography>
            )}
        </Box>
    );
};

const SlotItemFlag = ({ flag: { type, message } }: { flag: FullScheduleSlot['flags'][number] }) => {
    const { title, icon: Icon, color } = FLAG_DETAILS_BY_FLAG[type];
    return (
        <Tooltip title={<SlotItemFlagTitle title={title} message={message} />} placement="right">
            <Icon color={color} size={14} weight="fill" />
        </Tooltip>
    );
};
