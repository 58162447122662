import { Box, Stack, Typography, styled, useTheme } from '@mui/material';
import { useSetAtom } from 'jotai';
import React from 'react';
import { PiClockFill, PiPlusCircleFill } from 'react-icons/pi';

import { SHIFT_SLOT_FLAG } from '@allie/utils/src/constants/scheduling/shift-slot.constants';

import { selectStaffSlotIdAtom, selectStaffTabAtom } from '../../../../shared/SelectStaff/atoms';
import { isSlotInTheFuture } from '../../../../shared/isSlotInTheFuture';

import { SlotContainer, SlotDetails, SlotProps } from './shared';

const createFancyBorder = (colors: string[]): string => {
    return `linear-gradient(93deg, ${colors.map((c, i) => `${c} ${(i / (colors.length - 1)) * 100}%`).join(', ')})`;
};

const defaultGradient = ['#BF2828', '#EC3232', '#F7A7A7', '#F05959', '#F7A7A7', '#EC3232', '#BF2828'];
const notifiedGradient = ['#CB7E63', '#FA9C7A', '#FDD4C6', '#FCC2AD', '#FDD4C6', '#FA9C7A', '#CB7E63'];

const FancyBorder = styled(Box)(() => ({
    padding: '2px',
    backgroundOrigin: 'border-box',
    borderRadius: '10px',
}));

const OpenSlot = (props: Omit<SlotProps, 'staffName'>) => {
    const { palette } = useTheme();

    const isInTheFuture = isSlotInTheFuture(props);
    const setSelectStaffSlotId = useSetAtom(selectStaffSlotIdAtom);
    const setSelectStaffSelectedTab = useSetAtom(selectStaffTabAtom);

    const hasFlags = props.flags.length > 0;
    const notifiedRegular = props.flags.some((flag) => flag === SHIFT_SLOT_FLAG.NOTIFIED_REGULAR);
    const notifiedAttention = props.flags.some((flag) => flag === SHIFT_SLOT_FLAG.NOTIFIED_ATTENTION);
    const isNotified = notifiedRegular || notifiedAttention;

    return (
        <FancyBorder sx={{ background: createFancyBorder(notifiedRegular ? notifiedGradient : defaultGradient) }}>
            <SlotContainer
                onClick={() => {
                    setSelectStaffSlotId(props.id);
                    setSelectStaffSelectedTab(!isInTheFuture ? 'search' : isNotified ? 'notify' : 'suggested');
                }}
                sx={{ border: 'none' }}
                isPublished={props.isPublished}
            >
                <Stack spacing="4px">
                    <Stack spacing="4px" flexDirection="row">
                        <Typography
                            variant="body1"
                            color={(notifiedRegular ? palette.secondary[500] : palette.error[600]) as string}
                            fontWeight={700}
                            fontSize="16px"
                        >
                            Open Shift
                        </Typography>
                    </Stack>
                    <SlotDetails location={props.location} role={props.role} />
                </Stack>
                {!hasFlags && <PiPlusCircleFill color={palette.error[500] as string} size={20} />}
                {notifiedAttention ? (
                    <PiClockFill color={palette.error[500] as string} size={20} />
                ) : notifiedRegular ? (
                    <PiClockFill color={palette.secondary[500] as string} size={20} />
                ) : null}
            </SlotContainer>
        </FancyBorder>
    );
};

export default OpenSlot;
