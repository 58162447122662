import { TabList } from '@mui/lab';
import { Box, Button, Chip, CircularProgress, Stack, Tab, Typography, useTheme } from '@mui/material';
import { StackProps } from '@mui/material';
import { PencilSimple } from '@phosphor-icons/react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';

import { STANDARDIZED_STAFF_TYPES_LABELS } from '@allie/utils/src/constants/scheduling/staff-types.constants';

import { useGetLocations } from '~/scheduling/api/queries/locations/getLocations';
import { useChangeSlotStaff } from '~/scheduling/api/queries/shift-slot/changeSlotStaff';
import { useGetFullSchedule } from '~/scheduling/api/queries/shift-slot/getFullSchedule';
import { useGetSlotStaffWarnings } from '~/scheduling/api/queries/shift-slot/getSlotStaffWarnings';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import { useGetStaffList } from '~/scheduling/api/queries/staff/getStaffList';
import { teamIdAtom } from '~/scheduling/atoms';
import CustomBottomSheet from '~/scheduling/components/CustomBottomSheet';
import CustomTabContext from '~/scheduling/components/CustomTabContext';
import { HorizontalSeparator } from '~/scheduling/components/shared';
import { SELECTOR_ALL_ID } from '~/scheduling/constants';

import { SelectStaffSize } from '../../../../shared/SelectStaff';
import NotifyStaffTabPanel from '../../../../shared/SelectStaff/NotifyStaffTabPanel';
import SearchStaffTabPanel from '../../../../shared/SelectStaff/SearchStaffTabPanel';
import StaffOption from '../../../../shared/SelectStaff/StaffOption';
import SuggestedStaffTabPanel from '../../../../shared/SelectStaff/SuggestedStaffTabPanel';
import {
    confirmStaffWarningIndexAtom,
    isSelectStaffOpenAtom,
    selectStaffSelectedStaffIdAtom,
    selectStaffSlotIdAtom,
    selectStaffTabAtom,
} from '../../../../shared/SelectStaff/atoms';
import { isSlotInTheFuture } from '../../../../shared/isSlotInTheFuture';
import { datePickerValueAtom, selectedTabAtom } from '../../atom';

const SelectStaffHeader = (props: StackProps) => (
    <Stack spacing="8px" {...props}>
        <Typography fontSize="16px" fontWeight={700}>
            Select Staff
        </Typography>
        <SelectStaffHeaderChips />
    </Stack>
);

const SelectStaffHeaderChips = () => {
    const slotId = useAtomValue(selectStaffSlotIdAtom);

    const { data: roleData } = useGetRoles();
    const roleById = roleData?.roleById;
    const roleShiftById = roleData?.roleShiftById;

    const { data: locationData } = useGetLocations();
    const locationById = locationData?.locationById;

    const selectedLocationId = useAtomValue(selectedTabAtom);
    const selectedDate = useAtomValue(datePickerValueAtom);
    const teamId = useAtomValue(teamIdAtom);

    const { data: fullScheduleData } = useGetFullSchedule({
        startDay: selectedDate,
        endDay: selectedDate,
        locationId: selectedLocationId !== SELECTOR_ALL_ID ? selectedLocationId : undefined,
        teamId,
    });
    const slotById = fullScheduleData?.slotById;

    if (!slotId || !slotById) return null;

    const slot = slotById.get(slotId);

    if (!slot) return null;

    const { shiftDay, roleId, roleShiftId, locationId } = slot;

    const dayStr = DateTime.fromISO(shiftDay).toFormat('EEE, MMM d');
    const role = roleById?.get(roleId)?.name;
    const roleShift = roleShiftById?.get(roleShiftId)?.name;
    const location = locationById?.get(locationId)?.abbreviation;

    return (
        <Stack direction="row" spacing="4px">
            <Chip variant="outlined" size="small" color="primary" label={dayStr} />
            <Chip variant="outlined" size="small" color="primary" label={roleShift} />
            <Chip variant="outlined" size="small" label={location} />
            <Chip variant="outlined" size="small" label={role} />
        </Stack>
    );
};

export const SelectStaffTabs = ({ size }: { size: SelectStaffSize }) => {
    const slotId = useAtomValue(selectStaffSlotIdAtom);
    const [selectedTab, setSelectedTab] = useAtom(selectStaffTabAtom);

    const selectedLocationId = useAtomValue(selectedTabAtom);
    const selectedDate = useAtomValue(datePickerValueAtom);
    const teamId = useAtomValue(teamIdAtom);

    const { data: fullScheduleData } = useGetFullSchedule({
        startDay: selectedDate,
        endDay: selectedDate,
        locationId: selectedLocationId !== SELECTOR_ALL_ID ? selectedLocationId : undefined,
        teamId,
    });
    const slotById = fullScheduleData?.slotById;

    const isInTheFuture = useMemo(() => {
        const slot = slotById && slotId ? slotById.get(slotId) : null;
        return slot ? isSlotInTheFuture(slot) : null;
    }, [slotById, slotId]);

    return (
        <CustomTabContext value={selectedTab}>
            {isInTheFuture ? (
                <TabList onChange={(_, newTab: typeof selectedTab) => setSelectedTab(newTab)} sx={{ px: '24px' }}>
                    <Tab label="Recommended Staff" value="suggested" />
                    <Tab label="Notify Team" value="notify" />
                    <Tab label="Search" value="search" />
                </TabList>
            ) : (
                <TabList onChange={(_, newTab: typeof selectedTab) => setSelectedTab(newTab)} sx={{ px: '24px' }}>
                    <Tab label="Search" value="search" />
                </TabList>
            )}

            <HorizontalSeparator sx={{ bgcolor: 'grey.100' }} />

            <Box p="24px">
                <SuggestedStaffTabPanel value="suggested" size={size} />
                <NotifyStaffTabPanel value="notify" slotById={slotById} />
                <SearchStaffTabPanel value="search" size={size} />
            </Box>
        </CustomTabContext>
    );
};

const SelectStaffSelected = () => (
    <>
        <HorizontalSeparator />
        <Box p="20px 24px">
            <Stack spacing="4px">
                <Typography variant="body1" fontWeight={700}>
                    Change to
                </Typography>
                <SelectStaffSelectedStaffOption size="full" />
            </Stack>
        </Box>
        <HorizontalSeparator />
        <SelectStaffSelectedActions />
    </>
);

const SelectStaffSelectedStaffOption = ({ size }: { size: SelectStaffSize }) => {
    const { palette } = useTheme();

    const setSelectStaffModalTab = useSetAtom(selectStaffTabAtom);
    const [staffId, setStaffId] = useAtom(selectStaffSelectedStaffIdAtom);

    const { data: staffListData } = useGetStaffList();
    const staffById = staffListData?.staffById;

    if (!staffId || !staffById) return null;

    const { name, staffType } = staffById.get(staffId)!;

    return (
        <StaffOption
            name={name}
            details={[STANDARDIZED_STAFF_TYPES_LABELS[staffType]]} // TODO: Add phone number
            actions={
                <Button
                    variant="outlined"
                    size="small"
                    startIcon={<PencilSimple color={palette.grey[600]} weight="fill" />}
                    onClick={() => {
                        setStaffId(null);
                        setSelectStaffModalTab('search');
                    }}
                >
                    Change
                </Button>
            }
            size={size}
            borderRadius="8px"
            border={`1px solid ${palette.grey[100]}`}
        />
    );
};

const SelectStaffSelectedActions = () => {
    const toggle = useSetAtom(isSelectStaffOpenAtom);

    const slotId = useAtomValue(selectStaffSlotIdAtom);
    const staffId = useAtomValue(selectStaffSelectedStaffIdAtom);

    const { data: warnings, isLoading: isWarningsLoading } = useGetSlotStaffWarnings(slotId, staffId);
    const setWarningIndex = useSetAtom(confirmStaffWarningIndexAtom);

    const { mutateAsync: changeSlotStaff, isPending: isChangeSlotStaffPending } = useChangeSlotStaff();

    if (!slotId || !staffId) return null;

    return (
        <Box p="20px 24px">
            <Button
                onClick={async () => {
                    if (warnings?.length) setWarningIndex(0);
                    else {
                        await changeSlotStaff({ slotId, data: { staffId } });
                        toggle();
                    }
                }}
                disabled={isWarningsLoading || isChangeSlotStaffPending}
                fullWidth
            >
                {isChangeSlotStaffPending ? (
                    <CircularProgress size={20} thickness={4} sx={{ color: 'white' }} />
                ) : (
                    'Confirm'
                )}
            </Button>
        </Box>
    );
};

const SelectStaffBottomSheet = () => {
    const [isOpen, toggle] = useAtom(isSelectStaffOpenAtom);
    const selectedStaffId = useAtomValue(selectStaffSelectedStaffIdAtom);

    return (
        <CustomBottomSheet
            isOpen={isOpen}
            onClose={toggle}
            closeButton
            dialogProps={{
                PaperProps: {
                    sx: {
                        height: !selectedStaffId ? '80%' : 'auto',
                    },
                },
            }}
        >
            <SelectStaffHeader p="20px 24px" />
            {!selectedStaffId ? <SelectStaffTabs size="compact" /> : <SelectStaffSelected />}
        </CustomBottomSheet>
    );
};

export default SelectStaffBottomSheet;
