import { useAtomValue } from 'jotai';
import { DateTime } from 'luxon';
import React from 'react';

import { useGetBudgetedScheduledByDay } from '~/scheduling/api/queries/dashboard/getBudgetedScheduledByDay';
import { GetDashboardBudgetedScheduledByDay } from '~/scheduling/api/types/dashboard/getBudgetedScheduledByDay';
import { teamIdAtom } from '~/scheduling/atoms';
import { formatCalendarDateToDateTime } from '~/scheduling/components/calendar/util';

import { printScheduleDateRange } from './atom';
import { Dashboard } from './types';

const remapData = (data: GetDashboardBudgetedScheduledByDay.BudgetedScheduledByDay[]): Dashboard.Budget[] => {
    return [
        {
            name: 'Budgeted',
            type: 'Budgeted',
            days: data.map((res) => ({ budget: res.budgetedHours, day: DateTime.fromISO(res.day) })),
        },
        {
            name: 'Scheduled',
            type: 'Scheduled',
            days: data.map((res) => ({ budget: res.scheduledHours, day: DateTime.fromISO(res.day) })),
        },
        {
            name: 'Overtime',
            type: 'Overtime',
            days: data.map((res) => ({ budget: res.overtimeHours, day: DateTime.fromISO(res.day) })),
        },
    ];
};

export const useBudgetedHours = (locationId?: number) => {
    const dateRange = useAtomValue(printScheduleDateRange);
    const { startDay, endDay } = {
        startDay: formatCalendarDateToDateTime(dateRange.startDate),
        endDay: formatCalendarDateToDateTime(dateRange.endDate),
    };
    const teamId = useAtomValue(teamIdAtom);
    const { data, isLoading } = useGetBudgetedScheduledByDay({ teamId, startDay, endDay, locationId });

    return React.useMemo(
        () => ({
            budgets: data ? remapData(data) : [],
            isLoading,
        }),
        [data, isLoading]
    );
};
