import { Box, styled } from '@mui/material';

export const LayoutBackground = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[25],
    width: '100%',
    height: '100%',
    padding: '32px 24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    overflowY: 'auto',
}));
