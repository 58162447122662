import { Box, Stack, Typography, styled, useTheme } from '@mui/material';
import { Clock, Icon, Info, ProhibitInset, Users, Warning } from '@phosphor-icons/react';
import { DateTime } from 'luxon';
import React from 'react';
import { IoEllipse } from 'react-icons/io5';

import { SHIFT_SLOT_FLAG } from '@allie/utils/src/constants/scheduling/shift-slot.constants';

import palette from '~/scheduling/theme/palette';

export const iconByFlagType: Record<SHIFT_SLOT_FLAG, { title: string; Icon: Icon; color: string; bgcolor: string }> = {
    [SHIFT_SLOT_FLAG.NOTIFIED_ATTENTION]: {
        title: 'Team Notified',
        Icon: Clock,
        color: palette.error![600] as string,
        bgcolor: palette.error![50] as string,
    },
    [SHIFT_SLOT_FLAG.NOTIFIED_REGULAR]: {
        title: 'Team Notified',
        Icon: Clock,
        color: palette.secondary![500] as string,
        bgcolor: palette.secondary![50] as string,
    },
    [SHIFT_SLOT_FLAG.PARTIAL_SHIFT]: {
        title: 'Partial Shift',
        Icon: Info,
        color: palette.grey![500] as string,
        bgcolor: palette.grey![50] as string,
    },
    [SHIFT_SLOT_FLAG.WITH_TRAINEE]: {
        title: 'With Trainee',
        Icon: Users,
        color: palette.grey![400] as string,
        bgcolor: palette.grey![50] as string,
    },
    [SHIFT_SLOT_FLAG.TOO_FEW_HOURS]: {
        title: 'Too Few Hours',
        Icon: Clock,
        color: palette.primary![500] as string,
        bgcolor: palette.primary![50] as string,
    },
    [SHIFT_SLOT_FLAG.OVERTIME]: {
        title: 'Overtime',
        Icon: Warning,
        color: palette.secondary![500] as string,
        bgcolor: palette.secondary![50] as string,
    },
    [SHIFT_SLOT_FLAG.WORK_RESTRICTION]: {
        title: 'Work Restriction',
        Icon: ProhibitInset,
        color: '#FFB800',
        bgcolor: '#FDF8EA',
    },
};

export const SlotContainer = styled(Stack)<{ isPublished: boolean }>(({ theme, isPublished }) => ({
    backgroundColor: isPublished ? 'white' : theme.palette.grey[50],
    padding: '12px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    gap: '16px',
    alignItems: 'center',
    borderRadius: '8px',
    border: `2px solid ${theme.palette.grey[100]}`,
    cursor: 'pointer',
    '&:hover': { backgroundColor: theme.palette.grey[25] },
    '&:active': { backgroundColor: theme.palette.grey[50] },
}));

export interface SlotProps {
    id: number;
    role: string;
    location: string;
    staffName: string;
    flags: SHIFT_SLOT_FLAG[];
    isPublished: boolean;
    startTime: DateTime<true>;
}

export const Flags = ({ flags }: { flags: SHIFT_SLOT_FLAG[] }) => {
    return flags.map((flag, index) => {
        const { Icon, color } = iconByFlagType[flag];
        return <Icon key={index} color={color} fontSize="16px" weight="fill" />;
    });
};

interface SlotDetailsProps {
    role: string;
    location: string;
}

export const SlotDetails = (props: SlotDetailsProps) => {
    const { palette } = useTheme();

    return (
        <Box display="flex" gap="6px" flexWrap="wrap" alignItems="center">
            <Typography variant="body1" fontSize="15px" color={palette.grey[600]} fontWeight={600}>
                {props.role}
            </Typography>
            <IoEllipse type="bold" size={6} color={palette.grey[300]} />
            <Typography variant="body1" fontSize="15px" color={palette.grey[600]} fontWeight={600}>
                {props.location}
            </Typography>
        </Box>
    );
};
