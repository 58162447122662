import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { Duration } from 'luxon';

import { atomWithToggle, guardAtom } from '~/scheduling/utils/atoms';

import { getNotifyStaffDefaultValues, getNotifyStaffTimeOptions } from '../../Desktop/utils/notifyStaff';

const interval = Duration.fromObject({ hour: 1 }); // TODO: Get from community settings

const _selectStaffSlotIdAtom = atom<number | null>(null);
export const selectStaffSlotIdAtom = atom(
    (get) => get(_selectStaffSlotIdAtom),
    (_, set, slotId) => {
        set(_selectStaffSlotIdAtom, slotId);

        // On 'select staff' modal open
        if (slotId) {
            set(selectStaffNotifySelectedTimesAtom, getNotifyStaffDefaultValues(interval));
            set(selectStaffNotifyTimeOptionsAtom, getNotifyStaffTimeOptions(interval));
        }
    }
);
export const selectStaffTabAtom = atom<'suggested' | 'search' | 'notify'>('suggested');

export const _selectStaffSelectedStaffIdAtom = atom<number | null>(null);
export const selectStaffSelectedStaffIdAtom = atom(
    (get) => get(_selectStaffSelectedStaffIdAtom),
    (_, set, staffId) => {
        set(_selectStaffSelectedStaffIdAtom, staffId);

        // On cancel staff selection
        if (!staffId) {
            set(selectStaffNotifySelectedTimesAtom, getNotifyStaffDefaultValues(interval));
            set(selectStaffNotifyTimeOptionsAtom, getNotifyStaffTimeOptions(interval));
            set(selectStaffNotifyNotesAtom, null);

            set(selectStaffSearchValueAtom, '');
        }
    }
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const selectStaffNotifiedStaffIdsAtomFamily = atomFamily((id: number) => atom<boolean | null>(false));

export const selectStaffNotifySelectedTimesAtom = atom<ReturnType<typeof getNotifyStaffDefaultValues>>([]);
export const selectStaffNotifyTimeOptionsAtom = atom<ReturnType<typeof getNotifyStaffTimeOptions>>([]);
export const selectStaffNotifyNotesAtom = atom<string | null>(null);
export const isSelectStaffNotifyNotesOpenAtom = atomWithToggle(false);

export const selectStaffSearchValueAtom = atom('');
export const addAgencyStaffNameAtom = atom<string | null>(null);
export const isAddAgencyStaffOpenAtom = atomWithToggle(false, (_, set) => set(addAgencyStaffNameAtom, null));

export const isSelectStaffOpenAtom = guardAtom(selectStaffSlotIdAtom, (set) => {
    // On modal close
    set(selectStaffTabAtom, 'suggested');

    // https://github.com/pmndrs/jotai/discussions/1121#discussioncomment-2628583
    selectStaffNotifiedStaffIdsAtomFamily.setShouldRemove(() => true);
    selectStaffNotifiedStaffIdsAtomFamily.setShouldRemove(null);

    set(selectStaffNotifySelectedTimesAtom, []);
    set(selectStaffNotifyTimeOptionsAtom, []);
    set(selectStaffNotifyNotesAtom, null);

    set(selectStaffSearchValueAtom, '');

    set(_selectStaffSelectedStaffIdAtom, null);
});

export const confirmStaffWarningIndexAtom = atom<number | null>(null);
export const isConfirmStaffWarningOpenAtom = guardAtom(confirmStaffWarningIndexAtom);
