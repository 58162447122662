import { Button, CircularProgress, Stack } from '@mui/material';
import { XCircle } from '@phosphor-icons/react';
import { useSetAtom } from 'jotai';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { deleteStaffModalIdAtom } from '~/scheduling/pages/StaffList/StaffDetails/atom';

const HeaderActions = ({
    isSaving,
    isLoading,
    formHasChanges,
}: {
    isSaving: boolean;
    isLoading: boolean;
    formHasChanges: boolean;
}) => {
    const params = useParams<{ id: string }>();
    const navigate = useNavigate();
    const setDeleteStaffModalId = useSetAtom(deleteStaffModalIdAtom);

    const handleCancel = () => {
        navigate('/scheduling/staff-list');
    };

    const handleDelete = () => {
        if (params.id) {
            setDeleteStaffModalId(+params.id);
        }
    };

    const isEditingStaff = !!params.id;

    const isDisabled = isSaving || isLoading;
    const isDisabledWithNoChanges = isSaving || isLoading || !formHasChanges;

    return (
        <Stack direction="row" alignItems="center" spacing="16px">
            {isEditingStaff && (
                <Button
                    type="button"
                    sx={{ color: 'error.dark' }}
                    size="small"
                    variant="text"
                    onClick={handleDelete}
                    disabled={isDisabled}
                    endIcon={<XCircle size={20} weight="fill" />}
                >
                    Remove Staff
                </Button>
            )}
            <Button type="button" variant="outlined" size="small" onClick={handleCancel} disabled={isDisabled}>
                Cancel
            </Button>
            <Button type="submit" size="small" disabled={isDisabledWithNoChanges}>
                {isSaving ? <CircularProgress size={20} thickness={4} sx={{ color: 'white' }} /> : 'Save Changes'}
            </Button>
        </Stack>
    );
};

export default HeaderActions;
