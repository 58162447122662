import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/system';
import React, { ReactNode, useMemo } from 'react';

import componentsOverride from '~/components/theme/overrides';
import palette from '~/components/theme/palette';
import shape from '~/components/theme/shape';
import typography from '~/components/theme/typography';
import {
    CustomShape,
    PaletteAppColors,
    PaletteChartColors,
    PaletteColorScales,
    PaletteNavbar,
    PaletteNavigation,
    PaletteSocialMediaColors,
} from '~/types/theme';

declare module '@mui/material/styles' {
    interface Palette {
        app: PaletteAppColors;
        badge: string;
        black: string;
        blue: PaletteColorScales;
        border: string;
        charts: PaletteChartColors;
        green: PaletteColorScales;
        navbar: PaletteNavbar;
        navigation: PaletteNavigation;
        orange: PaletteColorScales;
        shadow: string;
        socialMedia: PaletteSocialMediaColors;
        tableHeader: string;
        yellow: string;
    }
    interface PaletteOptions {
        app: PaletteAppColors;
        badge: string;
        black: string;
        blue: PaletteColorScales;
        border: string;
        charts: PaletteChartColors;
        green: PaletteColorScales;
        navbar: PaletteNavbar;
        navigation: PaletteNavigation;
        orange: PaletteColorScales;
        shadow: string;
        socialMedia: PaletteSocialMediaColors;
        tableHeader: string;
        yellow: string;
    }
    interface Theme {
        shape: CustomShape;
    }
    interface ThemeOptions {
        shape: CustomShape;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        primary: true;
        secondary: true;
        blue: true;
        green: true;
        orange: true;
        yellow: true;
        shadow: true;
        socialMedia: true;
        border: true;
    }
}

type Props = {
    children: ReactNode;
};

const ThemeConfig = ({ children }: Props) => {
    const themeOptions = useMemo(
        () => ({
            palette,
            shape,
            typography,
        }),
        []
    );
    const theme = createTheme(themeOptions);
    theme.components = componentsOverride(theme);

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeConfig;
