import { Box, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { PiSparkleFill } from 'react-icons/pi';

import DesktopHeader from '~/components/Layout/DesktopHeader';
import WithHeader from '~/components/Layout/WithHeader';

import { LayoutBackground } from '../../components/LayoutBackground';

import BudgetOverviewCard from './components/BudgetOverviewCard';
import HeaderActions from './components/HeaderActions';
import RecommendationList from './components/RecommendationList';
import SaveOptimizationModal from './components/SaveOptimizationModal';
import ShiftCalendar from './components/ShiftCalendar';

const HeaderTitle = () => {
    const { palette } = useTheme();

    return (
        <Stack direction="row" alignItems="center" spacing="4px">
            <Typography variant="h6">Staff Optimizations</Typography>
            <PiSparkleFill color={palette.primary.main} size={16} />
        </Stack>
    );
};

const StaffOptimizations = () => {
    const headerHeight = '84px';

    return (
        <WithHeader header={<DesktopHeader title={<HeaderTitle />} actions={<HeaderActions />} />}>
            <LayoutBackground sx={{ flexDirection: 'row', height: `calc(100% - ${headerHeight})` }}>
                <ShiftCalendar />
                <Box display="flex" gap="24px" flexDirection="column">
                    <BudgetOverviewCard />
                    <RecommendationList />
                </Box>
            </LayoutBackground>

            <SaveOptimizationModal />
        </WithHeader>
    );
};

export default StaffOptimizations;
