import {
    NotifyStaffGroupCaptionMessage,
    NotifyStaffGroupCaptionRow,
    NotifyStaffGroupCaptionValue,
    NotifyStaffGroupContainer,
    NotifyStaffGroupIconBox,
    NotifyStaffGroupIconNameContainer,
    NotifyStaffGroupName,
    NotifyStaffGroupNameContainer,
    NotifyStaffGroupNotes,
    NotifyStaffGroupNotesContainer,
    NotifyStaffGroupNotesIconBox,
    NotifyStaffGroupsStack,
    NotifyStaffTimelineConnector,
    numberIcons,
} from '.';
import { Box, Button, styled, useTheme } from '@mui/material';
import { Note } from '@phosphor-icons/react';
import { useSetAtom } from 'jotai';
import { DateTime } from 'luxon';
import React from 'react';

import { FullScheduleSlot } from '~/scheduling/api/queries/shift-slot/getFullSchedule';

import {
    NOTIFY_STAFF_GROUPS,
    NOTIFY_STAFF_TIME_FORMAT_BY_DAY,
    NOTIFY_STAFF_TIME_OPTIONS,
    NOTIFY_STAFF_TITLE_BY_GROUP,
    NotifyStaffTimeOption,
} from '../../../Desktop/utils/notifyStaff';
import { selectStaffTabAtom } from '../atoms';

type TeamNotification = FullScheduleSlot['teamNotifications'][number];

const isGroupNotified = (index: number, dateTimes: (DateTime | null)[]): boolean => {
    if (index < 0) return true; // Base case: no previous groups

    // A group is considered 'notified' if: it's either skipped or in the past,
    // and all previous groups were also 'notified'
    const dateTime = dateTimes[index];
    return (!dateTime || dateTime < DateTime.now()) && isGroupNotified(index - 1, dateTimes);
};

const getTimeOptionAndFormat = (dateTime: DateTime | null) => {
    const now = DateTime.now();
    const yesterday = now.minus({ day: 1 });
    const tomorrow = now.plus({ day: 1 });

    let timeOption: NotifyStaffTimeOption;
    let format: keyof typeof NOTIFY_STAFF_TIME_FORMAT_BY_DAY;

    if (dateTime?.hasSame(now, 'day')) {
        timeOption = dateTime > now ? NOTIFY_STAFF_TIME_OPTIONS.futureRelative : NOTIFY_STAFF_TIME_OPTIONS.pastRelative;
        format = 'today';
    } else if (dateTime?.hasSame(yesterday, 'day')) {
        timeOption = NOTIFY_STAFF_TIME_OPTIONS.pastRelative;
        format = 'yesterday';
    } else if (dateTime?.hasSame(tomorrow, 'day')) {
        timeOption = NOTIFY_STAFF_TIME_OPTIONS.futureRelative;
        format = 'tomorrow';
    } else {
        timeOption = dateTime
            ? dateTime > now
                ? NOTIFY_STAFF_TIME_OPTIONS.futureRelative
                : NOTIFY_STAFF_TIME_OPTIONS.pastRelative
            : NOTIFY_STAFF_TIME_OPTIONS.pastSkip;
        format = 'other';
    }

    return { timeOption, format };
};

const NotifiedTimelineConnector = styled(Box)<{ isFirstIndex: boolean; isLastIndex: boolean }>(
    ({ theme: { palette }, isFirstIndex, isLastIndex }) => ({
        backgroundColor: palette.primary.main,
        width: '1px',
        position: 'absolute',
        top: isFirstIndex ? '8px' : '-32px',
        bottom: isLastIndex ? '40px' : '4px',
        left: '16px',
        zIndex: 1,
    })
);

const OngoingNotifyStaffGroups = ({ teamNotifications }: { teamNotifications: TeamNotification[] }) => {
    const dateTimes = NOTIFY_STAFF_GROUPS.map(
        (group) => teamNotifications?.find((notification) => notification.group === group)?.notifyAt.toLocal() ?? null
    );

    return (
        <NotifyStaffGroupsStack>
            {NOTIFY_STAFF_GROUPS.map((_, index) => (
                <OngoingNotifyStaffGroup key={index} index={index} dateTimes={dateTimes} />
            ))}
            <NotifyStaffTimelineConnector />
        </NotifyStaffGroupsStack>
    );
};

const OngoingNotifyStaffGroup = ({ index, dateTimes }: { index: number; dateTimes: (DateTime | null)[] }) => {
    const { palette } = useTheme();

    const dateTime = dateTimes[index];
    const isNotified = isGroupNotified(index, dateTimes);

    const title = NOTIFY_STAFF_TITLE_BY_GROUP[NOTIFY_STAFF_GROUPS[index]];
    const Icon = numberIcons[index];

    return (
        <NotifyStaffGroupContainer position="relative">
            <NotifyStaffGroupIconNameContainer>
                <NotifyStaffGroupIconBox
                    sx={
                        isNotified
                            ? {
                                  bgcolor: palette.primary[50] as string,
                                  borderColor: palette.primary.main,
                              }
                            : {}
                    }
                >
                    <Icon color={isNotified ? palette.primary.main : palette.grey[300]} fontSize={16} weight="fill" />
                </NotifyStaffGroupIconBox>
                <NotifyStaffGroupNameContainer>
                    <NotifyStaffGroupName
                        color={palette.grey[300]}
                        sx={{ textDecoration: isNotified ? 'line-through' : 'none' }}
                    >
                        {title} Staff
                    </NotifyStaffGroupName>
                </NotifyStaffGroupNameContainer>
            </NotifyStaffGroupIconNameContainer>
            <OngoingNotifyStaffGroupCaption dateTime={dateTime} />

            {/* Timeline connector */}
            {isNotified && (
                <NotifiedTimelineConnector
                    isFirstIndex={index === 0}
                    isLastIndex={index === NOTIFY_STAFF_GROUPS.length - 1}
                />
            )}
        </NotifyStaffGroupContainer>
    );
};

const OngoingNotifyStaffGroupCaption = ({ dateTime }: { dateTime: DateTime | null }) => {
    const {
        timeOption: { message, caption, color },
        format,
    } = getTimeOptionAndFormat(dateTime);

    const formattedDateTime = dateTime?.toFormat(NOTIFY_STAFF_TIME_FORMAT_BY_DAY[format]);

    return (
        <NotifyStaffGroupCaptionRow>
            <NotifyStaffGroupCaptionMessage>{message}</NotifyStaffGroupCaptionMessage>
            <NotifyStaffGroupCaptionValue color={color}>{formattedDateTime ?? caption}</NotifyStaffGroupCaptionValue>
        </NotifyStaffGroupCaptionRow>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OngoingNotifyStaffNotes = ({ teamNotifications }: { teamNotifications: TeamNotification[] }) => {
    const notes = teamNotifications.find(({ notes }) => notes)?.notes;

    return (
        <NotifyStaffGroupNotesContainer>
            <NotifyStaffGroupNotesIconBox>
                <Note fontSize={16} weight="fill" />
            </NotifyStaffGroupNotesIconBox>
            <NotifyStaffGroupNotes>{notes ?? 'No notes were added'}</NotifyStaffGroupNotes>
        </NotifyStaffGroupNotesContainer>
    );
};

const OngoingNotifyStaffActions = () => {
    const setSelectedTab = useSetAtom(selectStaffTabAtom);

    return (
        <Button variant="outlined" onClick={() => setSelectedTab('search')}>
            Select Staff
        </Button>
    );
};

const OngoingNotifyStaffTabPanel = ({ teamNotifications }: { teamNotifications: TeamNotification[] }) => (
    <>
        <OngoingNotifyStaffGroups teamNotifications={teamNotifications} />
        {/* TODO: uncomment when we are ready to add notes back */}
        {/* <OngoingNotifyStaffNotes teamNotifications={teamNotifications} /> */}
        <OngoingNotifyStaffActions />
    </>
);

export default OngoingNotifyStaffTabPanel;
