import { TabContext } from '@mui/lab';
import { Stack } from '@mui/material';
import React, { ComponentProps } from 'react';

const MobileTabContext = (props: ComponentProps<typeof TabContext>) => (
    <Stack
        sx={({ palette }) => ({
            height: 'calc(100% - 56px)', // TODO: Improve dependency on TabList and correctly infer height
            '& .MuiTabs-root': { flexShrink: 0, bgcolor: palette.primary.main },
            '& .MuiTab-root': {
                flex: 1,
                color: 'white !important',
                height: '48px',
                fontSize: '16px',
                fontWeight: 400,
                '&.Mui-selected': { fontWeight: 700 },
            },
            '& .MuiTabs-indicator': { bgcolor: 'white' },
        })}
    >
        <TabContext {...props} />
    </Stack>
);

export default MobileTabContext;
