import { useTheme } from '@mui/material';
import { DateTime, Duration, Interval } from 'luxon';
import React, { useEffect, useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { PiArrowDown, PiArrowUp } from 'react-icons/pi';

import { FormDropdown } from '~/scheduling/components/form/FormDropdown';
import { buildShiftInterval } from '~/scheduling/utils/dates';

import { StaffDetailsFormFields } from '../../../types';
import { useRoleShift } from '../useRoleShift';

interface CustomTimeFieldsProps {
    form: UseFormReturn<StaffDetailsFormFields>;
    isEditing?: boolean;
    disabled?: boolean;
    fieldName: `schedules.${number}.shifts.${number}.${number}`;
    defaultStartTime?: number;
    defaultEndTime?: number;
}

export const CustomTimeFields = ({
    form,
    isEditing,
    disabled,
    fieldName,
    defaultEndTime,
    defaultStartTime,
}: CustomTimeFieldsProps) => {
    const { palette } = useTheme();

    const [customStartTime, customEndTime] = useWatch({
        name: [`${fieldName}.customStartTime`, `${fieldName}.customEndTime`],
        control: form.control,
    });

    const { roleShift } = useRoleShift({ fieldName, form });

    const shiftInterval = useMemo(() => {
        if (!roleShift) return null;

        const { shiftStartTime, shiftEndTime } = roleShift;
        return buildShiftInterval(DateTime.now(), shiftStartTime, shiftEndTime);
    }, [roleShift]);

    const { startTimeOptions, endTimeOptions } = useMemo(() => {
        let expandedShiftInterval: Interval<true> | undefined;

        if (shiftInterval) {
            // Build expanded shift interval to account for staff who work non-standard hours
            expandedShiftInterval = Interval.fromDateTimes(
                shiftInterval.start.minus({ hours: 2 }),
                shiftInterval.end.plus({ hours: 2 })
            ) as Interval<true>;
        }
        const splitInterval =
            (expandedShiftInterval?.splitBy(Duration.fromObject({ minutes: 30 })) as Interval<true>[]) ?? [];

        const startTimeOptions = splitInterval.map(({ start }) => ({
            value: +start,
            label: start.toFormat('hh:mm a'),
        }));
        const endTimeOptions = splitInterval.map(({ end }) => ({ value: +end, label: end.toFormat('hh:mm a') }));

        return { startTimeOptions, endTimeOptions };
    }, [shiftInterval]);

    // Reset custom start and end times when shift changes
    useEffect(() => {
        // Do not reset if not in edit mode
        if (isEditing || !shiftInterval || customStartTime || customEndTime) return;

        form.setValue(`${fieldName}.customStartTime`, +shiftInterval.start);
        form.setValue(`${fieldName}.customEndTime`, +shiftInterval.end);
    }, [shiftInterval]);

    // Reset custom start and end times when shift changes
    useEffect(() => {
        // Do not reset if not in edit mode
        if (isEditing || !shiftInterval || customStartTime || customEndTime) return;

        form.setValue(`${fieldName}.customStartTime`, +shiftInterval.start);
        form.setValue(`${fieldName}.customEndTime`, +shiftInterval.end);
    }, [shiftInterval]);

    // If in edit mode, clamp start time to the nearest value less than the new end time
    useEffect(() => {
        if (!isEditing && customStartTime && customEndTime && customStartTime >= customEndTime) {
            form.setValue(
                `${fieldName}.customStartTime`,
                startTimeOptions.findLast(({ value }) => value < customEndTime)?.value
            );
        }
    }, [customEndTime]);

    // If in edit mode, clamp end time to the nearest value greater than the new start time
    useEffect(() => {
        if (!isEditing && customStartTime && customEndTime && customStartTime >= customEndTime) {
            form.setValue(
                `${fieldName}.customEndTime`,
                endTimeOptions.find(({ value }) => value > customStartTime)?.value
            );
        }
    }, [customStartTime]);

    return (
        <>
            <FormDropdown
                name={`${fieldName}.customStartTime`}
                placeholder="Start Time"
                icon={<PiArrowUp size={20} color={palette.grey[300]} />}
                form={form}
                options={startTimeOptions}
                selectProps={{ disabled, defaultValue: defaultStartTime }}
            />
            <FormDropdown
                name={`${fieldName}.customEndTime`}
                placeholder="End Time"
                icon={<PiArrowDown size={20} color={palette.grey[300]} />}
                form={form}
                options={endTimeOptions}
                selectProps={{ disabled, defaultValue: defaultEndTime }}
            />
        </>
    );
};
