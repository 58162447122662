import { Button } from '@mui/material';
import { Dialog, Slide, SlideProps, styled, useTheme } from '@mui/material';
import { X } from '@phosphor-icons/react';
import React, { ComponentProps, ReactNode } from 'react';

const CustomDialog = styled(Dialog)({
    '& .MuiDialog-container': {
        alignItems: 'flex-end',

        '& .MuiDialog-paper': {
            color: 'inherit',
            position: 'relative',
            width: '100%',
            margin: 0,
            borderRadius: 0,
            overflow: 'hidden',
        },
    },
});

const CloseButton = ({ sx, ...props }: ComponentProps<typeof Button>) => {
    const { palette } = useTheme();

    return (
        <Button
            variant="outlined"
            size="small"
            sx={{
                position: 'absolute',
                top: '20px',
                right: '24px',
                minWidth: '36px',
                height: '36px',
                p: 0,
                borderRadius: '50%',
                opacity: props.disabled ? 0.5 : 1,
                ...sx,
            }}
            {...props}
        >
            <X color={palette.grey[600]} weight="bold" />
        </Button>
    );
};

const CustomBottomSheet = ({
    isOpen,
    onClose,
    dialogProps,
    closeButton,
    closeButtonProps,
    children,
}: {
    isOpen: boolean;
    onClose: () => void;
    dialogProps?: Partial<ComponentProps<typeof Dialog>>;
    closeButton?: true;
    closeButtonProps?: ComponentProps<typeof Button>;
    children: ReactNode;
}) => (
    <CustomDialog
        {...dialogProps}
        open={isOpen}
        onClose={(_, reason) => reason !== 'backdropClick' && reason !== 'escapeKeyDown' && onClose()}
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'up', timeout: 300 } as SlideProps}
    >
        {closeButton && <CloseButton onClick={onClose} {...closeButtonProps} />}
        {children}
    </CustomDialog>
);

export default CustomBottomSheet;
