import { Box } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';

import { Scheduling } from '../../types';

import { CalendarDayColumn } from './CalendarDayColumns';
import { CalendarHeader } from './CalendarHeader';
import { CalendarWeekRow } from './CalendarWeekRow';
import { LAST_MONTH_OF_YEAR } from './constants';
import { daysMatrix, formatCalendarDateToDateTime } from './util';

interface CalendarProps {
    startDate: Scheduling.CalendarData;
    endDate?: Scheduling.CalendarData;
    month?: number;
    year?: number;
    disablePast?: boolean;
    hidePreviousButton?: boolean;
    hideNextButton?: boolean;
    singleSelection?: boolean;
    onPreviousMonthClick?: () => void;
    onNextMonthClick?: () => void;
    onChangeValue?: (dates: Scheduling.DateRange) => void;
    disableFn?: (date: DateTime) => boolean;
}

export const Calendar = (props: CalendarProps) => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    const [calendarYear, setCalendarYear] = React.useState<number>(currentYear);
    const [calendarMonth, setCalendarMonth] = React.useState<number>(currentMonth);

    // this component can be controlled or not
    const month = React.useMemo(() => props.month ?? calendarMonth, [props.month, calendarMonth]);
    const year = React.useMemo(() => props.year ?? calendarYear, [props.year, calendarYear]);
    const endDate = React.useMemo(() => props.endDate ?? props.startDate, [props.endDate, props.startDate]);

    const handleClick = (day: number) => {
        const currentDate = { day, month, year };
        const currentDateTime = formatCalendarDateToDateTime(currentDate);

        // skip range checks if single selection
        if (props.singleSelection) {
            return props.onChangeValue?.({ startDate: currentDate, endDate: currentDate });
        }

        const startDateAsDate = formatCalendarDateToDateTime(props.startDate);
        const endDateAsDate = formatCalendarDateToDateTime(endDate);

        const isAfterEndDate = currentDateTime > endDateAsDate;
        const isBeforeStartDate = currentDateTime < startDateAsDate;
        const isTheSameDate = currentDateTime.equals(startDateAsDate) || currentDateTime.equals(endDateAsDate);

        // This is the core of the component
        // if we need to change or add anything in how the calendar works
        // this is the spot to do it
        if (isAfterEndDate) {
            props.onChangeValue?.({ startDate: props.startDate, endDate: { day, month, year } });
        } else if (isBeforeStartDate) {
            props.onChangeValue?.({ endDate: endDate, startDate: { day, month, year } });
        } else if (isTheSameDate) {
            props.onChangeValue?.({
                endDate: { day, month, year },
                startDate: { day, month, year },
            });
        } else {
            props.onChangeValue?.({ startDate: props.startDate, endDate: { day, month, year } });
        }
    };

    const handleNextMonth = () => {
        // controlled case
        if (props.month != null) {
            return props.onNextMonthClick?.();
        }

        // uncontrolled case
        if (month === LAST_MONTH_OF_YEAR) {
            setCalendarYear((prev) => prev + 1);
            setCalendarMonth(0);
        } else setCalendarMonth((prev) => prev + 1);
    };

    const handlePreviousMonth = () => {
        // controlled case
        if (props.month != null) {
            return props.onPreviousMonthClick?.();
        }

        // uncontrolled case
        if (month - 1 === -1) {
            setCalendarYear((prev) => prev - 1);
        }
        setCalendarMonth((prev) => (prev + LAST_MONTH_OF_YEAR) % 12);
    };

    return (
        <Box maxWidth="400px" width="100%" minWidth="224px" marginInline="auto">
            <CalendarHeader
                hideLeftArrow={props.hidePreviousButton}
                hideRightArrow={props.hideNextButton}
                monthIndex={month}
                currentYear={year}
                onNextMonth={handleNextMonth}
                onPreviousMonth={handlePreviousMonth}
            />
            <CalendarWeekRow />
            {daysMatrix(month, year).map((week, i) => (
                <Box key={'week' + i} display="flex">
                    {week.map((day, j) => {
                        if (day === '') {
                            return (
                                <CalendarDayColumn key={'day' + j} disabled>
                                    {day}
                                </CalendarDayColumn>
                            );
                        }

                        // parsing to dateTimes to ease the comparison
                        const currentDate = DateTime.fromObject({ day, month: month + 1, year });
                        const startDateTime = formatCalendarDateToDateTime(props.startDate);
                        const endDateTime = formatCalendarDateToDateTime(endDate);

                        const selectedDay = currentDate.equals(startDateTime) || currentDate.equals(endDateTime);

                        const insidePeriod = currentDate >= startDateTime && currentDate <= endDateTime;

                        // checking if the current date is the start or end to
                        // change the style, changing the linear-gradient in each cases
                        const isStart = currentDate.equals(startDateTime) && !startDateTime.equals(endDateTime);
                        const isEnd = currentDate.equals(endDateTime) && !startDateTime.equals(endDateTime);

                        const disabled = props.disableFn
                            ? props.disableFn(currentDate)
                            : props.disablePast && currentDate < DateTime.now().startOf('day');

                        return (
                            <CalendarDayColumn
                                key={'day' + j}
                                disabled={disabled}
                                selected={selectedDay}
                                insidePeriod={insidePeriod}
                                isStart={isStart}
                                isEnd={isEnd}
                                onClick={() => handleClick(day)}
                            >
                                {day}
                            </CalendarDayColumn>
                        );
                    })}
                </Box>
            ))}
        </Box>
    );
};
