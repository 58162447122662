import { connectRouter } from 'connected-react-router';
import { loadingBarReducer } from 'react-redux-loading';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import History from '~/helpers/History';
import dailyTasks from '~/redux/reducers/dailyTasks';
import filters from '~/redux/reducers/filters';
import messages from '~/redux/reducers/messages';
import opsDashboard from '~/redux/reducers/opsDashboard';
import residents from '~/redux/reducers/residents';
import session from '~/redux/reducers/session';
import shiftNotesCategories from '~/redux/reducers/shiftNotesCategories';
import taskStatuses from '~/redux/reducers/taskStatuses';
import users from '~/redux/reducers/users';
import zones from '~/redux/reducers/zones';

const reducersList = {
    dailyTasks,
    form: formReducer,
    loadingBar: loadingBarReducer,
    messages,
    residents,
    router: connectRouter(History),
    session,
    shiftNotesCategories,
    taskStatuses,
    users,
    zones,
    filters,
    opsDashboard,
};

export default combineReducers(reducersList);
