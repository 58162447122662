import { Button, FormControl, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { useMemo } from 'react';

import { useGetFullSchedule } from '~/scheduling/api/queries/shift-slot/getFullSchedule';
import { useGetStaffList } from '~/scheduling/api/queries/staff/getStaffList';

import SegmentedModal from '../../../../../../components/SegmentedModal';
import { addTraineeIdAtom, isAddTraineeModalOpenAtom } from '../../atoms';
import { useShiftActions } from '../../useShiftActions';
import { filledSlotModalSlotIdAtom } from '../Week/atoms';

const AddTraineeSelector = ({ staffId, traineeId }: { staffId: number; traineeId?: number }) => {
    const setNewTraineeId = useSetAtom(addTraineeIdAtom);

    const { data: staffListData } = useGetStaffList();
    const staffList = staffListData?.staffList;

    const traineeOptions = useMemo(
        () =>
            staffList
                ?.filter((staff) => staff.id !== staffId && staff.isActive)
                .map(({ id, name }) => ({ value: id, label: name }))
                .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
        [staffList, staffId]
    );

    return (
        <Stack spacing="4px">
            <Typography variant="body1" fontWeight={700}>
                Select trainee
            </Typography>
            <FormControl>
                <Select
                    displayEmpty
                    defaultValue={traineeId ?? ''}
                    onChange={(e) => setNewTraineeId(Number(e.target.value))}
                >
                    <MenuItem disabled value="">
                        Select trainee
                    </MenuItem>
                    {traineeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    );
};

const AddTraineeActions = ({ oldTraineeId }: { oldTraineeId?: number }) => {
    const addTraineeModalSlotId = useAtomValue(filledSlotModalSlotIdAtom);
    const toggle = useSetAtom(isAddTraineeModalOpenAtom);

    const newTraineeId = useAtomValue(addTraineeIdAtom);
    const { setShiftSlotTrainee } = useShiftActions();

    return (
        <>
            {oldTraineeId ? (
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                        setShiftSlotTrainee(addTraineeModalSlotId!, undefined);
                        toggle();
                    }}
                >
                    Remove Trainee
                </Button>
            ) : (
                <Button variant="outlined" onClick={toggle}>
                    Cancel
                </Button>
            )}
            <Button
                onClick={() => {
                    if (oldTraineeId !== newTraineeId) setShiftSlotTrainee(addTraineeModalSlotId!, newTraineeId!);
                    toggle();
                }}
                disabled={!newTraineeId || oldTraineeId === newTraineeId}
            >
                Confirm
            </Button>
        </>
    );
};

const AddTraineeModal = () => {
    const [isOpen, toggle] = useAtom(isAddTraineeModalOpenAtom);
    const slotId = useAtomValue(filledSlotModalSlotIdAtom);

    const { data: fullScheduleData } = useGetFullSchedule();
    const slotById = fullScheduleData?.slotById;

    if (!slotId || !slotById) return null;

    const { staffId } = slotById.get(slotId)!;
    const traineeId = undefined; // TODO: Rethink trainee staff

    return (
        <SegmentedModal
            isOpen={isOpen}
            onClose={toggle}
            header={`${traineeId ? 'Change' : 'Add'} Trainee Staff`}
            actions={<AddTraineeActions oldTraineeId={traineeId} />}
            closeButton
        >
            <AddTraineeSelector staffId={staffId!} traineeId={traineeId} />
        </SegmentedModal>
    );
};

export default AddTraineeModal;
