import { Box, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

export const Cell = styled(Grid)({
    display: 'flex',
    alignItems: 'center',
});

export const CellContent = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
});
