import { Button, Switch, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useAtom, useAtomValue } from 'jotai';
import { DateTime } from 'luxon';
import React from 'react';

import { useGetCommunitySettings } from '~/scheduling/api/queries/community-settings/getCommunitySettings';
import { useGetLocations } from '~/scheduling/api/queries/locations/getLocations';
import { useGetPublishedDateRange } from '~/scheduling/api/queries/shift-slot/getPublishedDateRange';
import { teamIdAtom } from '~/scheduling/atoms';
import { formatCalendarDateToDateTime, formatDateTimeToCalendarDate } from '~/scheduling/components/calendar/util';
import { Scheduling } from '~/scheduling/types';

import SegmentedModal from '../../../../components/SegmentedModal';
import { MultiCalendar } from '../../../../components/calendar';
import { Dropdown } from '../../../../components/form/Dropdown';
import { isPrintScheduleModalOpenAtom, printScheduleDateRange } from '../../atom';
import { ALL_LOCATIONS_ID, PRINT_SCHEDULE_PERIOD_OPTIONS } from '../../constants';
import { Dashboard } from '../../types';
import { useBudgetedHours } from '../../useBudgetedHours';
import { usePrintOutShiftSlots } from '../../usePrintOutShiftSlots';

import { downloadBlob, formatPeriodString, isSameCalendarDate, mapPrintSchedulePeriodDates, renderPdf } from './util';

const PrintScheduleActions = ({ onPrint, disableSubmit }: { disableSubmit: boolean; onPrint: () => void }) => {
    const dateRange = useAtomValue(printScheduleDateRange);

    return (
        <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
            <Typography variant="body1" fontWeight={700} color="grey.500">
                {formatPeriodString(dateRange)}
            </Typography>

            <Button disabled={disableSubmit} onClick={onPrint}>
                Print Schedule
            </Button>
        </Box>
    );
};

const SHOULD_SHOW_SWITCHES = false; // TODO - remove this after MVP was launched

const PrintSchedule = () => {
    const teamId = useAtomValue(teamIdAtom);

    const [isOpen, toggle] = useAtom(isPrintScheduleModalOpenAtom);
    // TODO - change this atom to use luxon
    const [dateRange, setDateRange] = useAtom(printScheduleDateRange);
    const dateTimeRange = {
        startDate: formatCalendarDateToDateTime(dateRange.startDate),
        endDate: formatCalendarDateToDateTime(dateRange.endDate),
    };

    const [location, setLocation] = React.useState<number>(ALL_LOCATIONS_ID);
    const [period, setPeriod] = React.useState<Dashboard.PrintSchedulePeriod>('CUSTOM_PERIOD');
    const [, setShowHoursCountPerDay] = React.useState(false);
    const [, setShowStaffMembersPhoneNumber] = React.useState(false);

    const { data: communitySettings, isLoading: isCommunitySettingsLoading } = useGetCommunitySettings();
    const firstDayOfWeek = communitySettings?.firstDayOfWeek;

    const { data: locationData, isLoading: isLocationLoading } = useGetLocations();
    const locations = locationData?.locations ?? [];

    const interval = React.useMemo(() => {
        const diffDays = dateTimeRange.endDate.diff(dateTimeRange.startDate, 'days').days + 1;
        return [...new Array(diffDays).keys()].map((i) => dateTimeRange.startDate.plus({ days: i }));
    }, [dateRange]);
    const locationId = location !== ALL_LOCATIONS_ID ? location : undefined;

    const { shifts, isPending: isScheduleLoading } = usePrintOutShiftSlots({ interval, locationId, teamId });
    const { budgets } = useBudgetedHours(locationId);

    const { data: publishedDateRange, isLoading: isPublishedDateRangeLoading } = useGetPublishedDateRange(teamId);
    const { firstPublishedDate, lastPublishedDate } = publishedDateRange ?? {};

    const modifyDateRange = React.useCallback(
        (dateRange: Scheduling.DateRange) => {
            // TODO - change component to use luxon
            // parsing to DateTime to ease the comparations
            const rangeDateTime = {
                startDate: formatCalendarDateToDateTime(dateRange.startDate),
                endDate: formatCalendarDateToDateTime(dateRange.endDate),
            };

            // if last published date is less than start date, set start date to last published date
            if (lastPublishedDate && lastPublishedDate < rangeDateTime.startDate) {
                dateRange.startDate = formatDateTimeToCalendarDate(lastPublishedDate);
            }

            // if last published date is less than end date, set end date to last published date
            if (lastPublishedDate && lastPublishedDate < rangeDateTime.endDate) {
                dateRange.endDate = formatDateTimeToCalendarDate(lastPublishedDate);
            }

            // if first published date is greater than start date, set start date to first published date
            if (firstPublishedDate && firstPublishedDate > rangeDateTime.startDate) {
                dateRange.startDate = formatDateTimeToCalendarDate(firstPublishedDate);
            }

            // if first published date is greater than end date, set end date to first published date
            if (firstPublishedDate && firstPublishedDate > rangeDateTime.endDate) {
                dateRange.endDate = formatDateTimeToCalendarDate(firstPublishedDate);
            }

            return dateRange;
        },
        [lastPublishedDate, dateRange, setDateRange]
    );

    React.useEffect(() => {
        // useEffect to catch the moment of lastPublishedDate loads
        if (lastPublishedDate && dateRange) {
            const modifiedRange = modifyDateRange(dateRange);

            const startDateChanged = !isSameCalendarDate(modifiedRange.startDate, dateRange.startDate);
            const endDateChanged = !isSameCalendarDate(modifiedRange.endDate, dateRange.endDate);

            // only change state if the dates have changed
            // otherwise, we'll get an infinite loop
            if (startDateChanged || endDateChanged) {
                setDateRange(modifiedRange);
            }
        }
    }, [modifyDateRange, lastPublishedDate, dateRange]);

    const locationOptions = [
        { label: 'All locations', value: ALL_LOCATIONS_ID },
        ...locations.map(({ id, name }) => ({ label: name, value: id })),
    ];

    const handlePeriodChange = (value: Dashboard.PrintSchedulePeriod) => {
        setPeriod(value);

        if (value === 'CUSTOM_PERIOD') return;

        // modifying the date range to respect the lastPublishedDate
        const modifiedRange = modifyDateRange(mapPrintSchedulePeriodDates[value](DateTime.now(), firstDayOfWeek));
        setDateRange(modifiedRange);
    };

    const handleCustomPeriodChange = (dateRange: Scheduling.DateRange) => {
        setPeriod('CUSTOM_PERIOD');

        // modifying the date range to respect the lastPublishedDate
        const modifiedRange = modifyDateRange(dateRange);
        setDateRange(modifiedRange);
    };

    const handlePrint = async () => {
        const pdfBlob = await renderPdf(shifts, budgets, 'weekly');
        // TODO - improve this
        const filename = 'weekly_shifts';

        downloadBlob(pdfBlob, filename);
    };

    const disableNonPublishedDates = (date: DateTime) => {
        if (!lastPublishedDate && !firstPublishedDate) return false;

        return lastPublishedDate! < date || firstPublishedDate! > date;
    };

    const isLoading =
        isScheduleLoading || isLocationLoading || isPublishedDateRangeLoading || isCommunitySettingsLoading;

    return (
        <SegmentedModal
            minWidth={'512px'}
            center
            closeButton
            isOpen={isOpen}
            onClose={toggle}
            header="Print Schedule"
            actions={<PrintScheduleActions onPrint={handlePrint} disableSubmit={isLoading || !budgets} />}
        >
            <Box>
                <Box display="flex" gap="12px">
                    <Box flex={1}>
                        <Typography variant="label" component="p" color="grey.900" paddingBottom="4px">
                            Select locations
                        </Typography>
                        <Dropdown options={locationOptions} value={location} onChange={setLocation} />
                    </Box>
                    <Box flex={1}>
                        <Typography variant="label" component="p" color="grey.900" paddingBottom="4px">
                            Select period time
                        </Typography>
                        <Dropdown
                            options={PRINT_SCHEDULE_PERIOD_OPTIONS}
                            value={period}
                            onChange={handlePeriodChange}
                        />
                    </Box>
                </Box>
                <MultiCalendar
                    dateRange={dateRange}
                    setDateRange={handleCustomPeriodChange}
                    disableFn={disableNonPublishedDates}
                />
                {SHOULD_SHOW_SWITCHES && (
                    <>
                        <Box display="flex" gap="8px" paddingTop="24px">
                            <Typography variant="body1" color="grey.600">
                                Show hours summary
                            </Typography>
                            <Switch onChange={(event) => setShowHoursCountPerDay(event.target.checked)} />
                        </Box>
                        <Box display="flex" gap="8px" paddingTop="12px">
                            <Typography variant="body1" color="grey.600">
                                Show phone number
                            </Typography>
                            <Switch onChange={(event) => setShowStaffMembersPhoneNumber(event.target.checked)} />
                        </Box>
                    </>
                )}
            </Box>
        </SegmentedModal>
    );
};

export default PrintSchedule;
