import { Stack } from '@mui/material';
import React, { ComponentProps } from 'react';

import { pxToRem } from '~/components/theme/typography';

const PageStructure = ({ sx, ...props }: ComponentProps<typeof Stack>) => (
    <Stack
        {...props}
        sx={{
            flex: 1,
            p: { xs: pxToRem(8), lg: pxToRem(16) },
            overflowY: 'auto',
            ...sx,
        }}
    />
);

export default PageStructure;
