import { Box, Stack, Typography, styled, useTheme } from '@mui/material';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';

import WithHeader from '~/components/Layout/WithHeader';
import { useGetLocations } from '~/scheduling/api/queries/locations/getLocations';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import { HorizontalSeparator, VerticalSeparator } from '~/scheduling/components/shared';
import { useScheduling } from '~/scheduling/useScheduling';
import { compareValue } from '~/scheduling/utils/compare';

import { Notifications } from '../types';

const NotificationStatusBox = styled(Box)({
    padding: '2px 6px',
    borderRadius: '4px',
});

const NotificationStatusText = styled(Typography)({
    color: 'white',
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '16px',
    textTransform: 'uppercase',
});

const NotificationDotSeparator = styled(Box)(({ theme: { palette } }) => ({
    backgroundColor: palette.grey[300],
    width: '4px',
    height: '4px',
    borderRadius: '50%',
}));

const NotificationItem = ({
    notification: { day, shiftId, roleId, careTypeId, status },
}: {
    notification: Notifications.Item;
}) => {
    const { palette } = useTheme();

    const { data: roleData } = useGetRoles();
    const roleById = roleData?.roleById;
    const roleShiftById = roleData?.roleShiftById;

    const { data: locationData } = useGetLocations();
    const locationById = locationData?.locationById;

    const { day: dayStr, weekdayShort: weekdayStr, monthShort: monthStr } = day;
    const role = roleById?.get(roleId)!.name;
    const roleShift = roleShiftById?.get(shiftId)!.name;
    const location = locationById?.get(careTypeId)!.abbreviation;

    return (
        <Stack
            direction="row"
            alignItems="center"
            p="12px"
            spacing="16px"
            borderRadius="8px"
            border={`1px solid ${palette.grey[100]}`}
        >
            <Stack alignItems="center">
                <Typography color={palette.grey[600]} fontSize="12px" lineHeight="16px">
                    {monthStr}
                </Typography>
                <Typography color={palette.primary.main} variant="h5">
                    {dayStr}
                </Typography>
                <Typography color={palette.grey[600]} fontSize="12px" lineHeight="16px">
                    {weekdayStr}
                </Typography>
            </Stack>

            <VerticalSeparator height="48px" />

            <Stack flex={1} spacing="4px">
                <Stack direction="row" alignItems="center" spacing="8px">
                    <Typography color={palette.grey[900]} fontSize="16px" fontWeight={700}>
                        {roleShift} Shift
                    </Typography>
                    <NotificationStatusBox
                        bgcolor={status === 'approved' ? palette.primary.main : (palette.error[600] as string)}
                    >
                        <NotificationStatusText>{status === 'approved' ? 'Approved' : 'Denied'}</NotificationStatusText>
                    </NotificationStatusBox>
                </Stack>

                <Stack direction="row" alignItems="center" spacing="6px">
                    <Typography color={palette.secondary.main} fontWeight={600}>
                        {role}
                    </Typography>
                    <NotificationDotSeparator />
                    <Typography color={palette.grey[900]} fontWeight={400}>
                        {location}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
};

const NotificationsCollapseButton = ({
    isCollapsed,
    setIsCollapsed,
}: {
    isCollapsed: boolean;
    setIsCollapsed: (value: boolean) => void;
}) => {
    const { palette } = useTheme();

    const Icon = isCollapsed ? CaretDown : CaretUp;

    return (
        <Stack
            direction="row"
            alignItems="center"
            p="8px"
            spacing="8px"
            borderRadius="8px"
            sx={{
                cursor: 'pointer',
                '&:hover': { bgcolor: palette.grey[50] },
                '&:active': { bgcolor: palette.grey[100] },
            }}
            onClick={() => setIsCollapsed(!isCollapsed)}
        >
            <HorizontalSeparator flex={1} />
            <Typography color={palette.grey[600]} fontSize="14px" fontWeight={500}>
                Past
            </Typography>
            <Icon color={palette.grey[600]} fontSize="14px" weight="bold" />
            <HorizontalSeparator flex={1} />
        </Stack>
    );
};

const NotificationsPage = () => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const { notifications } = useScheduling();

    const sortedNotifications = useMemo(
        () =>
            [...notifications].sort(
                (a, b) =>
                    compareValue(b.day, a.day) || // Descending
                    compareValue(a.shiftId, b.shiftId) // Ascending
            ),
        [notifications]
    );

    const startOfToday = DateTime.now().startOf('day');

    const currentNotifications = useMemo(
        () => sortedNotifications.filter(({ day }) => day >= startOfToday),
        [sortedNotifications]
    );

    const pastNotifications = useMemo(
        () => sortedNotifications.filter(({ day }) => day < startOfToday),
        [sortedNotifications]
    );

    return (
        <WithHeader mobileHeader>
            <Stack height="100%" p="24px" spacing="12px" sx={{ overflowY: 'auto' }}>
                <Stack spacing="12px">
                    {currentNotifications.map((notification, index) => (
                        <NotificationItem key={index} notification={notification} />
                    ))}
                </Stack>
                <NotificationsCollapseButton isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                {!isCollapsed && (
                    <Stack spacing="12px">
                        {pastNotifications.map((notification, index) => (
                            <NotificationItem key={index} notification={notification} />
                        ))}
                    </Stack>
                )}
            </Stack>
        </WithHeader>
    );
};

export default NotificationsPage;
