import { SelectStaffSize } from '.';
import { Box, Stack, StackProps, Typography, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

import { STAFF_FLAG_LEVEL } from '@allie/utils/src/constants/scheduling/staff.constants';

import { SingleLineTypography } from '~/components/Shared/SingleLineTypography';
import { GetSlotSuggestedStaff } from '~/scheduling/api/types/shift-slot/getSlotSuggestedStaff';
import { formatPhoneNumber } from '~/scheduling/utils/phoneNumber';

const StaffOptionFlagChip = ({ label, color }: { label: string; color: STAFF_FLAG_LEVEL }) => {
    const { palette } = useTheme();

    const colorByLevel = {
        [STAFF_FLAG_LEVEL.POSITIVE]: {
            backgroundColor: palette.primary[50] as string,
            color: palette.primary.main,
            borderColor: palette.primary[100] as string,
        },
        [STAFF_FLAG_LEVEL.NEUTRAL]: {
            backgroundColor: palette.secondary[50] as string,
            color: palette.secondary[600] as string,
            borderColor: palette.secondary[100] as string,
        },
        [STAFF_FLAG_LEVEL.NEGATIVE]: {
            backgroundColor: palette.error[50] as string,
            color: palette.error.main,
            borderColor: palette.error[100] as string,
        },
    };

    return (
        <Box
            sx={{
                p: '2px 8px',
                borderRadius: '10px',
                border: '1px solid',
                ...colorByLevel[color],
                width: 'auto',
            }}
        >
            <Typography
                variant="body1"
                sx={{
                    fontSize: '11px',
                    fontWeight: 600,
                    lineHeight: '16px',
                    textAlign: 'center',
                    textWrap: 'nowrap',
                }}
            >
                {label}
            </Typography>
        </Box>
    );
};

const StaffOptionDetail = ({ children }: { children: string }) => (
    <Typography
        variant="body1"
        sx={({ palette }) => ({
            color: palette.grey[600],
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '16px',
        })}
    >
        {isPossiblePhoneNumber(children) ? formatPhoneNumber(children) : children}
    </Typography>
);

const StaffOptionDotSeparator = () => (
    <Box
        sx={({ palette }) => ({
            backgroundColor: palette.grey[200],
            width: '4px',
            height: '4px',
            borderRadius: '50%',
        })}
    />
);

const StaffOption = ({
    name,
    flags,
    details,
    actions,
    size,
    ...props
}: {
    name: string;
    flags?: GetSlotSuggestedStaff.Flag[];
    details?: string[];
    actions: ReactNode;
    size: SelectStaffSize;
} & StackProps) => {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            p="12px"
            spacing="12px"
            {...props}
        >
            <Stack spacing="4px">
                <Stack
                    direction={size === 'full' ? 'row' : 'column-reverse'}
                    alignItems={size === 'full' ? 'flex-end' : 'flex-start'}
                    spacing="4px"
                >
                    <SingleLineTypography fontSize="15px" fontWeight={600}>
                        {name}
                    </SingleLineTypography>
                    <Stack direction="row" sx={{ flexWrap: 'wrap', gap: '4px' }}>
                        {flags?.map(({ text, level }) => <StaffOptionFlagChip key={text} label={text} color={level} />)}
                    </Stack>
                </Stack>
                {details && (
                    <Stack direction="row" alignItems="center" spacing="8px">
                        {details.map((detail, index) => (
                            <>
                                {index > 0 && <StaffOptionDotSeparator />}
                                <StaffOptionDetail key={detail}>{detail}</StaffOptionDetail>
                            </>
                        ))}
                    </Stack>
                )}
            </Stack>
            <Box flexShrink={0}>{actions}</Box>
        </Stack>
    );
};

export default StaffOption;
