import { SHIFT_SLOT_CHANGE_REASON } from '@allie/utils/src/constants/scheduling/shift-slot.constants';

export type ChangeStaffReasonOption = { value: SHIFT_SLOT_CHANGE_REASON; label: string; withNotes?: true };

export const CHANGE_STAFF_REASON_OPTIONS: ChangeStaffReasonOption[] = [
    { value: SHIFT_SLOT_CHANGE_REASON.CALL_OFF, label: 'Call off', withNotes: true },
    { value: SHIFT_SLOT_CHANGE_REASON.SWAP, label: 'Swap with another staff', withNotes: true },
    { value: SHIFT_SLOT_CHANGE_REASON.TIME_OFF, label: 'Time off' },
    { value: SHIFT_SLOT_CHANGE_REASON.END_EMPLOYMENT, label: 'End of employment' },
    { value: SHIFT_SLOT_CHANGE_REASON.MISPLACEMENT, label: 'Accidentally placed on shift' },
    { value: SHIFT_SLOT_CHANGE_REASON.OTHER, label: 'Other', withNotes: true },
];
