import { Capacitor } from '@capacitor/core';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { usePostHog } from 'posthog-js/react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import GeolocationPrompt from '~/assets/geolocation-prompt.svg';
import { pxToRem } from '~/components/theme/typography';
import { useGeolocation } from '~/contexts/GeolocationContext';
import { ReduxStore } from '~/types/redux';

const OutsideGeofence = () => {
    const isMobile = useMediaQuery('(max-width:1024px)');
    const platform = Capacitor.getPlatform() as 'android' | 'ios' | 'web';

    const { userId } = useSelector((state: ReduxStore) => state.session.sessionData);
    const { updatePosition, isPositionAccurate, distance, position } = useGeolocation();
    const posthog = usePostHog();

    useEffect(() => {
        posthog.capture('geofencing:outside', {
            user_id: userId,
            isPositionAccurate,
            distance,
            positionAccuracy: position?.coords.accuracy,
        });
    }, [userId, posthog]);

    const Container = styled(Box)(({ theme }) =>
        theme.unstable_sx({
            maxWidth: '65ch',
            width: {
                xs: '85%',
                lg: 'auto',
            },
            mx: 'auto',
            pt: {
                xs: platform === 'ios' ? 'calc(env(safe-area-inset-top) - 20px)' : pxToRem(32),
                lg: pxToRem(80),
            },
            pb: {
                xs: platform === 'ios' ? 'calc(env(safe-area-inset-bottom) + 50px)' : pxToRem(48),
                lg: pxToRem(32),
            },
        })
    );

    const Title = styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: '#2C2D3B',
            fontSize: {
                xs: pxToRem(24),
                lg: pxToRem(40),
            },

            lineHeight: pxToRem(34),
            fontWeight: 700,
            marginBottom: pxToRem(16),
            textAlign: 'center',
        })
    );

    const Subtitle = styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: '#6F6F79',
            fontSize: {
                xs: pxToRem(14),
                lg: pxToRem(16),
            },
            lineHeight: 1.5,
            fontWeight: 400,
            marginBottom: pxToRem(24),
            textAlign: 'left',
        })
    );

    const RefreshButton = styled(Button)(({ theme }) =>
        theme.unstable_sx({
            width: '100%',
            borderRadius: pxToRem(4),
            py: pxToRem(16),
            fontWeight: 700,
            fontSize: {
                xs: pxToRem(16),
            },
            lineHeight: {
                xs: 1.5,
            },
            color: '##fff !important',
            backgroundColor: '#006B75 !important',
            marginBottom: pxToRem(32),
            '&:disabled': {
                backgroundColor: '#61A3A9 !important',
            },
        })
    );

    return (
        <Container>
            <img
                style={{
                    width: pxToRem(isMobile ? 80 : 120),
                    marginBottom: pxToRem(24),
                    marginInline: 'auto',
                }}
                src={GeolocationPrompt}
                alt="icon"
            />

            <Title>Outside of Community</Title>
            <Subtitle>
                It looks like you are not near your community. This page will be available once you go back.
            </Subtitle>
            <RefreshButton onClick={updatePosition}>Refresh</RefreshButton>
        </Container>
    );
};

export default OutsideGeofence;
