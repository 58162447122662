import { Typography, useTheme } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { PiWarningCircleFill } from 'react-icons/pi';

import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import { StaffList } from '~/scheduling/pages/StaffList/types';

import GridContainer from '../shared/GridContainer';

import { ScheduleDay } from './ScheduleDay';
import { mapBaylorSchedule } from './mapStaffSchedule';
import { StaffScheduleProps } from './shared';

export const BaylorSchedule = ({ form, schedule, scheduleIndex, isSaving, isEditing }: StaffScheduleProps) => {
    const { palette } = useTheme();
    const { data: roleData } = useGetRoles();

    const scheduleData = React.useMemo(
        () => schedule && roleData && mapBaylorSchedule(schedule, roleData.roleShiftById),
        [schedule, roleData]
    );

    const checkEmptyShifts = (data?: StaffList.ScheduleConfigData[][]) =>
        data
            ?.flat()
            .slice(0, 4)
            .some(
                ({ staffRoleId, locationId, staffRoleShiftId, dayOfWeek }) =>
                    !staffRoleId || !locationId || !staffRoleShiftId || !dayOfWeek
            );

    const checkDuplicatedChoices = (data?: StaffList.ScheduleConfigData[][]) =>
        data
            ?.flat()
            .slice(0, 4)
            .some(({ staffRoleShiftId, dayOfWeek }, index) =>
                data
                    .flat()
                    .find(
                        (value, idx) =>
                            idx !== index &&
                            value.dayOfWeek === dayOfWeek &&
                            value.staffRoleShiftId === staffRoleShiftId
                    )
            );

    return (
        <Controller
            control={form.control}
            name={`schedules.${scheduleIndex}.shifts`}
            rules={{
                validate: (data) =>
                    checkEmptyShifts(data)
                        ? 'All four shifts need to be filled out'
                        : checkDuplicatedChoices(data)
                          ? 'One or more shifts are duplicated'
                          : undefined,
            }}
            render={({ fieldState: { error } }) => (
                <>
                    {error && (
                        <Typography
                            variant="body1"
                            color={palette.error[500] as string}
                            fontWeight={400}
                            fontSize="12px"
                            display="flex"
                            gap="4px"
                            alignItems="center"
                        >
                            <PiWarningCircleFill display="inline-flex" size={12} color={palette.error[500] as string} />{' '}
                            {error.message}
                        </Typography>
                    )}
                    <GridContainer minmax={240}>
                        {scheduleData
                            ? scheduleData.map((scheduleDay, index) => (
                                  <ScheduleDay
                                      key={index + 'baylor'}
                                      scheduleIndex={scheduleIndex}
                                      title="shift"
                                      form={form}
                                      dayIndex={index}
                                      withDayOfWeekField
                                      scheduleDay={scheduleDay}
                                      isEditing={isEditing || isSaving}
                                  />
                              ))
                            : [...(new Array(4) as unknown[])].map((_, index) => (
                                  <ScheduleDay
                                      key={index + 'baylor'}
                                      scheduleIndex={scheduleIndex}
                                      title="shift"
                                      form={form}
                                      dayIndex={index}
                                      withDayOfWeekField
                                      isEditing={isSaving}
                                  />
                              ))}
                    </GridContainer>
                </>
            )}
        />
    );
};
