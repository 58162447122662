import { Box } from '@mui/material';
import momentTz from 'moment-timezone';
import React from 'react';

import { ShiftOption } from '@allie/utils/src/shifts';

import { pxToRem } from '~/components/theme/typography';
import { HistoryItem } from '~/types/myRewards';

import { PointHistoryItem } from './PointHistoryItem';

type Props = {
    items: HistoryItem[];
    branchShifts: ShiftOption[];
};

export const ShiftDaySection = ({ items, branchShifts }: Props) => {
    const dateText = momentTz(items[0].shiftDayDate).calendar(null, {
        sameDay: '[Today], D MMM YYYY',
        lastDay: '[Yesterday], D MMM YYYY',
        nextDay: 'D MMM YYYY',
        nextWeek: 'D MMM YYYY',
        lastWeek: 'D MMM YYYY',
        sameElse: 'D MMM YYYY',
    });
    return (
        <Box>
            <Box
                sx={{
                    fontSize: pxToRem(14),
                    fontWeight: 700,
                    lineHeight: pxToRem(24),
                    color: '#2C2D3B',
                    marginBottom: pxToRem(16),
                }}
            >
                {dateText}
            </Box>
            {items.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <PointHistoryItem key={index} {...item} branchShifts={branchShifts} />
            ))}
        </Box>
    );
};
