import { useMediaQuery } from '@mui/material';
import React from 'react';
import Confetti from 'react-confetti';

import { useWindowSize } from './useWindowSize';

interface CustomConfettiProps {
    activate: boolean;
    stop: () => void;
}

const CustomConfetti = ({ activate, stop }: CustomConfettiProps) => {
    const [width, height] = useWindowSize();
    const isMobile = useMediaQuery('(max-width:1024px)');

    const windowWidth = width || window.innerWidth;
    const windowHeight = height || window.innerHeight;

    const amountOfPieces = isMobile ? 500 : 2000;

    return (
        <Confetti
            // In Desktop subtract 15 from width to prevent horizontal scroll bar
            width={isMobile ? windowWidth : windowWidth - 15}
            height={windowHeight}
            numberOfPieces={activate ? amountOfPieces : 0}
            recycle={false}
            onConfettiComplete={(confetti) => {
                confetti?.reset();
                stop();
            }}
            tweenDuration={1500}
            gravity={0.1}
        />
    );
};

export default CustomConfetti;
