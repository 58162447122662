import { Theme } from '@mui/material';
import { useMediaQuery } from '@mui/system';
import React from 'react';

import DesktopSchedule from './Desktop';
import MobileSchedule from './Mobile/Daily';

const Schedule = () => {
    const isMobile = useMediaQuery<Theme>(({ breakpoints }) => breakpoints.down('sm'));

    return isMobile ? <MobileSchedule /> : <DesktopSchedule />;
};

export default Schedule;
