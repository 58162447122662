import { Box, Typography, styled } from '@mui/material';
import React, { useEffect, useRef } from 'react';

import { WEEK_DAYS } from './constants';

const WeekColumn = styled(Typography)(({ theme }) => ({
    flex: 1,
    height: '100%',
    aspectRatio: 1,
    padding: '8px',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 700,
    color: theme.palette.grey[600],
}));

export const CalendarWeekRow = () => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [cellHeight, setCellHeight] = React.useState<number>(0);

    useEffect(() => {
        const newCellHeight = (containerRef.current?.clientHeight || 0) - 16;
        if (containerRef.current && newCellHeight !== cellHeight) {
            setCellHeight(newCellHeight);
        }
    });

    return (
        <Box display="flex">
            {WEEK_DAYS.map((day, i) => (
                <WeekColumn ref={containerRef} key={'week day' + i} variant="body1" lineHeight={cellHeight + 'px'}>
                    {day}
                </WeekColumn>
            ))}
        </Box>
    );
};
