import { atom, useAtom, useAtomValue } from 'jotai';
import range from 'lodash/range';
import sortBy from 'lodash/sortBy';
import { DateTime, Interval } from 'luxon';
import { useMemo } from 'react';

import { SHIFT_SLOT_STATUS } from '@allie/utils/src/constants/scheduling/shift-slot.constants';

import { GetLocationsResult, useGetLocations } from '~/scheduling/api/queries/locations/getLocations';
import { FullScheduleSlot, useGetFullSchedule } from '~/scheduling/api/queries/shift-slot/getFullSchedule';
import { GetRolesResult, useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';

import { useGetMasterSchedule } from './api/queries/master-schedule/getMasterSchedule';
import { GetMasterSchedule } from './api/types/master-schedule/getMasterSchedule';
import { teamIdAtom } from './atoms';
import { ScheduleGridSchema } from './types';

const scheduleGridSchemaAtom = atom<ScheduleGridSchema.Schema<number> | null>(null);

class NoMatchingSlotError extends Error {
    constructor(slot: FullScheduleSlot) {
        super(`No matching id found in schema for slot #${slot.id}`);
    }
}

/**
 * Merges two arrays into one, with the following rules for each index:
 * - If both arrays have a non-nullish value, it's impossible to merge.
 * - If one array has a value and the other doesn't (or it's nullish), that value is used.
 * - If neither array has a value (or they're nullish), the value becomes `null`.
 *
 * @returns Merged array, or `null` if impossible.
 */
const mergeArrays = <T>(arrA: T[], arrB: T[]) => {
    const length = Math.max(arrA.length, arrB.length);

    const mergedArray = Array<T | undefined>(length);
    for (let i = 0; i < length; i++) {
        if (arrA[i] && arrB[i]) return null; // Can't decide between two non-nullish values

        if (arrA[i] !== undefined) mergedArray[i] = arrA[i];
        else if (arrB[i] !== undefined) mergedArray[i] = arrB[i];
        else mergedArray[i] = undefined;
    }

    return mergedArray;
};

/**
 * Reduces a matrix as much as possible by merging its rows.
 *
 * @returns Reduced matrix (might be the input arrays, if not reducible).
 */
const reduceMatrix = <T>(matrix: T[][]) => {
    let reducedMatrix: (T | undefined)[][] = matrix;

    for (let i = 0; i < matrix.length - 1; i++) {
        for (let j = i + 1; j < matrix.length; j++) {
            const mergedRow = mergeArrays(matrix[i], matrix[j]);

            // Recurse until no further reduction is possible
            const newMatrix = mergedRow
                ? reduceMatrix([mergedRow, ...matrix.filter((_, index) => index !== i && index !== j)])
                : matrix; // This is the best we can do for this branch

            if (newMatrix.length < reducedMatrix.length) reducedMatrix = newMatrix;
        }
    }

    return reducedMatrix;
};

const transposeMatrix = <T>(matrix: T[][]) => {
    if (!matrix.length || !matrix[0].length) return [];

    const noOfRows = matrix.length;
    const noOfCols = matrix[0].length;
    const result = range(noOfCols).map(() => Array<T>(noOfRows));

    for (let i = 0; i < noOfRows; i++) {
        for (let j = 0; j < noOfCols; j++) {
            result[j][i] = matrix[i][j];
        }
    }

    return result;
};

/**
 * Schedule grids (e.g. scheduler view and print out) are rendered with a few matrices
 * of shift slots already in the order they'll appear on screen. This sorts that data
 * by grouping slots into shift -> role -> location -> staff -> day segments.
 */
const generateScheduleGridSchema = ({
    roleById,
    roleShiftById,
    locationById,
    slots,
    days,
}: {
    roleById: GetRolesResult['roleById'];
    roleShiftById: GetRolesResult['roleShiftById'];
    locationById: GetLocationsResult['locationById'];
    slots: FullScheduleSlot[];
    days: DateTime<true>[];
}): ScheduleGridSchema.Schema<number> => {
    // Buffer shifts grid creation, as they're split by shift/role/location. Using index because
    // even if two roles have different-named but similar shifts (e.g. 'AM' and 'Morning') they
    // should still be displayed together, since they have the same index (zero, in this example).
    const shiftByIndex: Record<number, ScheduleGridSchema.Shift<number>> = {};

    const indexByDayStr = Object.fromEntries(days.map((day, index) => [day.toFormat('yyyy-MM-dd'), index]));
    const previewDays = Array<boolean>(7).fill(false);

    // Group shifts, then roles, then locations, then days
    const sortedShiftSlots = sortBy(slots, ({ roleShiftId, roleId, locationId, shiftDay }) => {
        const roleShift = roleShiftById.get(roleShiftId)!;
        return [roleShift.index, roleId, locationId, shiftDay];
    });

    let currShiftId: number;
    let currRoleId: number;
    let currLocationId: number;
    let currFilledSlotsByStaffAndDay: Record<string, number[]>;
    let currOpenSlotsByDay: number[][];

    // Transform current shift/role/location combination for the
    // given days into the grid representation and flush them
    const flushCurrent = () => {
        if (!currShiftId) return;

        // Transform shift (create if doesn't exist)
        const newShift = roleShiftById.get(currShiftId)!;
        const foundShift = (shiftByIndex[newShift.index] ??= { index: newShift.index, name: newShift.name, roles: [] });

        // Transform role (create if doesn't exist)
        const newRole = roleById.get(currRoleId)!;
        let foundRole = foundShift.roles.find((role) => role.id === currRoleId);
        if (!foundRole) {
            foundRole = { id: currRoleId, name: newRole.name, locations: [] };
            foundShift.roles.push(foundRole);
        }

        // Transform location (create if doesn't exist)
        const newLocation = locationById.get(currLocationId)!;
        let foundLocation = foundRole.locations.find((location) => location.id === currLocationId);
        if (!foundLocation) {
            foundLocation = {
                id: currLocationId,
                name: newLocation.abbreviation,
                slotsByDay: [],
                overBudgetCountByDay: days.map(() => 0),
            };
            foundRole.locations.push(foundLocation);
        }

        const reducedFilledSlotsByStaffAndDay = sortBy(
            // Merge different staff into the same row where possible to save space...
            // reduceMatrix(Object.values(currFilledSlotsByStaffAndDay)),
            Object.values(currFilledSlotsByStaffAndDay),
            // ...and keep the rows with most filled slots first
            (row) => -row.filter(Boolean).length
        );

        // [staffRow][dayIndex] -> [dayColumn][slotIndex]
        const slotsByDay: (number | null | undefined)[][] = transposeMatrix(reducedFilledSlotsByStaffAndDay);

        // Redistribute last rows' slots to fill gaps in previous ones
        slotsByDay.forEach((daySlots) => {
            let endIndex = daySlots.length - 1;

            for (let i = 0; i < daySlots.length - 1; i++) {
                // If a gap is found...
                if (!daySlots[i]) {
                    // ...find the last non-null element and...
                    while (endIndex > i && !daySlots[endIndex]) endIndex--;

                    // ...move it up, if possible
                    if (endIndex > i) {
                        daySlots[i] = daySlots[endIndex];
                        daySlots[endIndex] = null;
                        endIndex--;
                    }
                }
            }
        });

        let noOfRows = 0;

        // Number of rows has to be the same for every day
        for (let i = 0; i < days.length; i++) {
            const daySlots = (slotsByDay[i] ??= []);
            const filledSlotsCount = daySlots.filter(Boolean).length;
            const openSlotsCount = currOpenSlotsByDay[i].length;
            noOfRows = Math.max(noOfRows, filledSlotsCount + openSlotsCount);
        }

        // Fill the gaps in each day with open slots or nulls to keep the matrix rectangular
        for (let i = 0; i < days.length; i++) {
            const daySlots = slotsByDay[i];
            for (let j = 0; j < noOfRows; j++) {
                if (!daySlots[j]) daySlots[j] = currOpenSlotsByDay[i].shift() ?? null;
            }
            slotsByDay[i] = daySlots.slice(0, noOfRows); // Trim empty rows
        }

        foundLocation.slotsByDay = slotsByDay as (number | null)[][];
    };

    const resetCurrent = (roleShiftId: number, roleId: number, locationId: number) => {
        currShiftId = roleShiftId;
        currRoleId = roleId;
        currLocationId = locationId;
        currFilledSlotsByStaffAndDay = {};
        currOpenSlotsByDay = days.map(() => []);
    };

    // Begin a new grouping upon reaching a new shift/role/location permutation and remap
    // slots into the correct staff and day, so we line them up nicely in the same row
    sortedShiftSlots.forEach((slot) => {
        const { shiftDay, roleId, roleShiftId, locationId } = slot;

        if (currRoleId !== roleId || currShiftId !== roleShiftId || currLocationId !== locationId) {
            flushCurrent();
            resetCurrent(roleShiftId, roleId, locationId);
        }

        const dayIndex = indexByDayStr[shiftDay];

        let key: { staffId: number } | { agencyStaffId: number } | null = null;
        if (slot.staffId) key = { staffId: slot.staffId };
        else if (slot.agencyStaffId) key = { agencyStaffId: slot.agencyStaffId };

        if (key) {
            // Filled slot
            const currSlotsByDay = (currFilledSlotsByStaffAndDay[JSON.stringify(key)] ??= Array(days.length));
            currSlotsByDay[dayIndex] = slot.id;
        } else {
            // Open slot
            const currSlotsByDay = (currOpenSlotsByDay[dayIndex] ??= []);
            currSlotsByDay.push(slot.id);
        }

        if (slot.status === SHIFT_SLOT_STATUS.DRAFT) previewDays[dayIndex] = true;
    });

    flushCurrent(); // For last shift/role/location combination

    const scheduleGridSchema = {
        days: days.map((dateTime, index) => ({
            dateTime,
            isPreview: previewDays[index],
            isWeekend: dateTime.isWeekend, // Leaving this as its own property for now as we'll rename and reuse it for holidays
        })),
        shifts: Object.values(shiftByIndex),
    };

    return scheduleGridSchema;
};

/**
 * Replaces slot ids in the schedule grid schema with the actual slot objects and calculates over-budget slots.
 *
 * @throws {NoMatchingSlotError} If a new slot that wasn't in the schema appears, which means the schema is dirty.
 */
const remapScheduleGridSchema = ({
    scheduleGridSchema,
    slotById,
    masterSchedule,
}: {
    scheduleGridSchema: ScheduleGridSchema.Schema<number>;
    slotById: Map<number, FullScheduleSlot>;
    masterSchedule: MasterSchedule;
}): ScheduleGridSchema.Schema<FullScheduleSlot> => {
    slotById = new Map([...slotById.entries()]);

    // Replace slot ids with actual slot objects
    const remappedScheduleGridSchema = {
        ...scheduleGridSchema,
        shifts: scheduleGridSchema.shifts.map((shift) => ({
            ...shift,
            roles: shift.roles.map((role) => ({
                ...role,
                locations: role.locations.map((location) => ({
                    ...location,
                    slotsByDay: location.slotsByDay.map((slots) =>
                        slots.map((slotId) => {
                            if (!slotId) return null;

                            const slot = slotById.get(slotId);
                            slotById.delete(slotId);

                            return slot ?? null;
                        })
                    ),
                })),
            })),
        })),
    };

    if (slotById.size) throw new NoMatchingSlotError(slotById.values().next().value as FullScheduleSlot);

    // Calculate over-budget slots
    for (const { index: shiftIndex, roles } of remappedScheduleGridSchema.shifts) {
        for (const { id: roleId, locations } of roles) {
            for (const { id: locationId, slotsByDay, overBudgetCountByDay } of locations) {
                for (let i = 0; i < remappedScheduleGridSchema.days.length; i++) {
                    const day = remappedScheduleGridSchema.days[i];
                    const weekday = day.dateTime.weekday;

                    const daySlots = slotsByDay[i];
                    const filledSlotsCount = daySlots.filter((slot) => slot?.staffId || slot?.agencyStaffId).length;
                    const budgetedSlotsCount = masterSchedule[shiftIndex]?.[roleId]?.[locationId]?.[weekday] ?? 0;

                    overBudgetCountByDay[i] = Math.max(0, filledSlotsCount - budgetedSlotsCount);
                }
            }
        }
    }

    return remappedScheduleGridSchema;
};

const emptyScheduleGridSchema = (days: DateTime<true>[]): ScheduleGridSchema.Schema<FullScheduleSlot> => ({
    days: days.map((dateTime) => ({ dateTime, isPreview: true, isWeekend: false })), // Assume it's a preview if empty
    shifts: [],
});

// Record<shiftId, <roleId, Record<locationId, Record<weekday, budgetedSlotsCount>>>>
type MasterSchedule = Record<number, Record<number, Record<number, Record<number, number>>>>;

const remapMasterSchedule = (masterSchedule: GetMasterSchedule.MasterSchedule) => {
    const map: MasterSchedule = {};

    for (const {
        staffRoleShift: { index: shiftIndex },
        staffRole: { id: roleId },
        location: { id: locationId },
        weekday,
        shiftSlotsAmount,
    } of masterSchedule.shifts) {
        map[shiftIndex] ??= {};
        map[shiftIndex][roleId] ??= {};
        map[shiftIndex][roleId][locationId] ??= {};
        map[shiftIndex][roleId][locationId][weekday] = shiftSlotsAmount;
    }

    return map;
};

// TODO: Break down by period to avoid recalculation on back-and-forth calendar changes
export const useScheduleGridSchema = ({
    interval,
}: {
    interval?: Interval<true>;
}): ScheduleGridSchema.Schema<FullScheduleSlot> | null => {
    const [scheduleGridSchema, setScheduleGridSchema] = useAtom(scheduleGridSchemaAtom);
    let currScheduleGridSchema = scheduleGridSchema;

    const { data: roleData } = useGetRoles();
    const roleById = roleData?.roleById;
    const roleShiftById = roleData?.roleShiftById;

    const { data: locationData } = useGetLocations();
    const locationById = locationData?.locationById;

    const days = interval?.splitBy({ days: 1 }).map((day) => day.start!);

    // TODO: Separate schedule API calls (weekly and custom) and not even call them if we don't have a valid interval
    const { data: fullScheduleData } = useGetFullSchedule(days ? { startDay: days.at(0), endDay: days.at(-1) } : {});
    const slots = fullScheduleData?.slots;
    const slotById = fullScheduleData?.slotById;

    const teamId = useAtomValue(teamIdAtom);
    const { data: rawMasterSchedule } = useGetMasterSchedule(teamId);
    const masterSchedule = rawMasterSchedule ? remapMasterSchedule(rawMasterSchedule) : null;

    // TODO: Make this run less often
    const remappedScheduleGridSchema = useMemo(() => {
        if (
            !roleById ||
            !roleShiftById?.size ||
            !locationById ||
            !slots ||
            !slotById ||
            !days?.length ||
            !masterSchedule
        )
            return null;

        if (!slots.length) return emptyScheduleGridSchema(days); // Loaded, but no slots (assume it's a preview)

        const regenScheduleGridSchema = () => {
            currScheduleGridSchema = generateScheduleGridSchema({
                roleById,
                roleShiftById,
                locationById,
                slots,
                days,
            });
            setScheduleGridSchema(currScheduleGridSchema);

            return currScheduleGridSchema;
        };

        if (!currScheduleGridSchema) regenScheduleGridSchema();

        try {
            return remapScheduleGridSchema({ scheduleGridSchema: currScheduleGridSchema!, slotById, masterSchedule });
        } catch (error) {
            if (error instanceof NoMatchingSlotError) {
                // Dirty schema, regenerate with new slots
                return remapScheduleGridSchema({
                    scheduleGridSchema: regenScheduleGridSchema(),
                    slotById,
                    masterSchedule,
                });
            } else throw error;
        }
    }, [roleById, roleShiftById, locationById, slots, slotById, days]);

    return remappedScheduleGridSchema;
};
