export const RESOURCE_ACTIONS = [
    { resource: 'Company', action: 'read-company' },
    { resource: 'Company', action: 'read-operations-dashboard' },
    { resource: 'Region', action: 'read-region' },
    { resource: 'Region', action: 'read-operations-dashboard' },
    { resource: 'Community', action: 'read-branch' },
    { resource: 'Community', action: 'read-all-users' },
    { resource: 'Community', action: 'read-reward' },
    { resource: 'Community', action: 'update-reward' },
    { resource: 'Community', action: 'read-resident' },
    { resource: 'Community', action: 'read-resident-deep-dive' },
    { resource: 'Community', action: 'create-resident-action' },
    { resource: 'Community', action: 'read-resident-action' },
    { resource: 'Community', action: 'update-resident-action' },
    { resource: 'Community', action: 'delete-resident-action' },
    { resource: 'Community', action: 'undertake-resident-action' },
    { resource: 'Community', action: 'read-all-resident-actions' },
    { resource: 'Community', action: 'read-operations-dashboard' },
    { resource: 'Community', action: 'read-performance-dashboard' },
    { resource: 'Community', action: 'receive-notification' },
    { resource: 'Community', action: 'require-geofencing' },
    { resource: 'Community', action: 'view-notification-center' },
    { resource: 'Community', action: 'export-data' },
    { resource: 'Community', action: 'scheduling-read-dashboard' },
    { resource: 'Community', action: 'scheduling-read-full-schedule' },
    { resource: 'Community', action: 'scheduling-read-staff' },
    { resource: 'Community', action: 'scheduling-read-master-schedule' },
    { resource: 'Community', action: 'scheduling-read-reports' },
    { resource: 'Community', action: 'scheduling-resolve-slot-request' },
    { resource: 'Community', action: 'scheduling-read-my-schedule' },
] as const;

export type ResourceActionTuple = (typeof RESOURCE_ACTIONS)[number];
export type ResourceType = ResourceActionTuple['resource'];

type ResourceActionsByType = {
    [Resource in ResourceType]: Extract<
        ResourceActionTuple['action'],
        Extract<ResourceActionTuple, { resource: Resource }>['action']
    >[];
};

export type ResourceActions<Resource extends ResourceType> = ResourceActionsByType[Resource][number];
