import { Box, Button, InputAdornment, Stack, TextField, Typography, styled } from '@mui/material';
import { useSetAtom } from 'jotai';
import React, { useCallback, useState } from 'react';
import { PiMagnifyingGlassBold, PiUserPlusBold } from 'react-icons/pi';
import { useNavigate } from 'react-router';

import DesktopHeader from '~/components/Layout/DesktopHeader';
import WithHeader from '~/components/Layout/WithHeader';
import { LayoutBackground } from '~/scheduling/components/LayoutBackground';

import { isStaffScheduleModalOpenAtom } from './atom';
import { StaffScheduleModal } from './components/StaffScheduleModal';
import { StaffTable } from './components/StaffTable';
import { StaffList as StaffListType } from './types';

const Container = styled(Box)({
    flex: 1,
    display: 'flex',
    backgroundColor: 'white',
    flexDirection: 'column',
    padding: '24px',
    borderRadius: '8px',
});

const HeaderContainer = styled(Stack)({
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px',
});

const AddStaffButton = () => {
    const navigate = useNavigate();

    return (
        <Button
            size="small"
            startIcon={<PiUserPlusBold />}
            sx={{ fontSize: '12px' }}
            onClick={() => navigate('staff-details')}
        >
            Add Staff
        </Button>
    );
};

const StaffList = () => {
    const toggle = useSetAtom(isStaffScheduleModalOpenAtom);
    const [staffNameFilter, setStaffNameFilter] = useState('');
    const [selectedStaff, setSelectedStaff] = useState<StaffListType.StaffData>();

    // Being used in StaffTable DataGrid.
    // This is used by one of the columns and column re-renders cause column width to break.
    const handleRowClick = useCallback(
        (staff: StaffListType.StaffData) => {
            toggle();
            setSelectedStaff(staff);
        },
        [toggle, setSelectedStaff]
    );

    return (
        <WithHeader header={<DesktopHeader actions={<AddStaffButton />} />}>
            <LayoutBackground>
                <Container>
                    <HeaderContainer>
                        <Typography variant="h6" fontWeight={700}>
                            Staff
                        </Typography>
                        <TextField
                            size="small"
                            variant="outlined"
                            placeholder="Search staff"
                            value={staffNameFilter}
                            onChange={(e) => setStaffNameFilter(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                        sx={({ palette }) => ({ color: palette.grey[500] })}
                                    >
                                        <PiMagnifyingGlassBold size={20} />
                                    </InputAdornment>
                                ),
                                sx: ({ palette }) => ({
                                    backgroundColor: `${palette.grey[25]} !important`,
                                }),
                            }}
                        />
                    </HeaderContainer>
                    <StaffTable staffNameFilter={staffNameFilter} onRowClick={handleRowClick} />
                </Container>
            </LayoutBackground>

            {selectedStaff && <StaffScheduleModal staff={selectedStaff} />}
        </WithHeader>
    );
};

export default StaffList;
