import { SelectStaffSize } from '.';
import { TabPanel, TabPanelProps } from '@mui/lab';
import { Box, Button, CircularProgress, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { Bell, BellRinging, CheckCircle } from '@phosphor-icons/react';
import { useAtomValue, useSetAtom } from 'jotai';
import range from 'lodash/range';
import React, { useMemo } from 'react';

import { SHIFT_SLOT_STAFF_NOTIFICATION_STATUS } from '@allie/utils/src/constants/scheduling/shift-slot.constants';

import { useCreateSlotStaffNotification } from '~/scheduling/api/queries/shift-slot/createSlotStaffNotification';
import { useGetSlotSuggestedStaff } from '~/scheduling/api/queries/shift-slot/getSlotSuggestedStaff';
import { GetSlotSuggestedStaff } from '~/scheduling/api/types/shift-slot/getSlotSuggestedStaff';

import StaffOption from './StaffOption';
import { selectStaffNotifiedStaffIdsAtomFamily, selectStaffSlotIdAtom } from './atoms';

const notificationSuffixByStatus: Record<SHIFT_SLOT_STAFF_NOTIFICATION_STATUS, string> = {
    [SHIFT_SLOT_STAFF_NOTIFICATION_STATUS.PENDING]: 'Pending',
    [SHIFT_SLOT_STAFF_NOTIFICATION_STATUS.DECLINED]: 'Declined',
    [SHIFT_SLOT_STAFF_NOTIFICATION_STATUS.ACCEPTED]: 'Accepted', // Shouldn't happen, but who knows
};

const SuggestedStaffOptions = ({ size }: { size: SelectStaffSize }) => {
    const slotId = useAtomValue(selectStaffSlotIdAtom);

    const { data: suggestedStaffData, isPending } = useGetSlotSuggestedStaff(slotId);
    const suggestedStaffList = suggestedStaffData?.suggestedStaff ?? [];
    const staffOptions = useMemo(() => suggestedStaffList.slice(0, 3), [suggestedStaffList]);

    return (
        <Stack spacing="8px">
            {!isPending
                ? staffOptions.map((staffOption) => (
                      <SuggestedStaffOption key={staffOption.name} size={size} {...staffOption} />
                  ))
                : range(3).map((i) => <Skeleton key={i} height={64} />)}
        </Stack>
    );
};

const SuggestedStaffOption = ({
    id,
    firstName,
    name,
    details,
    notificationStatus,
    size,
}: {
    id: number;
    firstName: string;
    name: string;
    details?: string[];
    notificationStatus: GetSlotSuggestedStaff.StaffSuggestion['notificationStatus'];
    size: SelectStaffSize;
}) => {
    const { palette } = useTheme();

    const isNotified = useAtomValue(selectStaffNotifiedStaffIdsAtomFamily(id));

    return (
        <Stack spacing="4px" alignItems="end">
            <Box
                sx={{
                    width: '100%',
                    bgcolor: palette.grey[25],
                    border: `1px solid ${palette.grey[100]}`,
                    borderRadius: '8px',
                }}
            >
                <StaffOption
                    name={name}
                    details={details}
                    actions={<SuggestedStaffActions staffId={id} notificationStatus={notificationStatus} size={size} />}
                    size={size}
                />
            </Box>
            {!!isNotified && (
                <Stack direction="row" alignItems="center" p="8px" spacing="4px">
                    <CheckCircle color={palette.primary.main} weight="fill" fontSize={14} />
                    <Typography variant="body1" fontSize="12px" lineHeight="16px">
                        {firstName} notified, waiting for response
                    </Typography>
                </Stack>
            )}
        </Stack>
    );
};

const SuggestedStaffActions = ({
    staffId,
    notificationStatus,
    size,
}: {
    staffId: number;
    notificationStatus: GetSlotSuggestedStaff.StaffSuggestion['notificationStatus'];
    size: SelectStaffSize;
}) => {
    const { palette } = useTheme();

    const slotId = useAtomValue(selectStaffSlotIdAtom);

    const { mutateAsync: createSlotStaffNotification, isPending } = useCreateSlotStaffNotification();
    const setIsNotified = useSetAtom(selectStaffNotifiedStaffIdsAtomFamily(staffId));

    if (!slotId) return null;

    return notificationStatus ? (
        <Button
            disabled
            variant="outlined"
            size="small"
            startIcon={<BellRinging color={palette.grey[200]} weight="fill" />}
        >
            {size === 'full' ? 'Notified - ' : ''}
            {notificationSuffixByStatus[notificationStatus]}
        </Button>
    ) : (
        <Button
            variant="outlined"
            size="small"
            startIcon={!isPending && <Bell color={palette.grey[600]} weight="fill" />}
            onClick={async () => {
                await createSlotStaffNotification({ slotId, data: { staffId } });
                setIsNotified(true);
            }}
            disabled={isPending}
        >
            {!isPending ? size === 'full' ? 'Notify and Ask' : 'Ask' : <CircularProgress size={20} thickness={4} />}
        </Button>
    );
};

const SuggestedStaffTabPanel = ({ size, ...props }: { size: SelectStaffSize } & TabPanelProps) => (
    <TabPanel {...props}>
        <SuggestedStaffOptions size={size} />
    </TabPanel>
);

export default SuggestedStaffTabPanel;
