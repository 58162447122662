import { CircularProgress, Radio, Stack, Typography, useTheme } from '@mui/material';
import { Button } from '@mui/material';
import { ArrowLeft } from '@phosphor-icons/react';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';

import { useMarkSlotOpen } from '~/scheduling/api/queries/shift-slot/markSlotOpen';
import SegmentedBottomSheet from '~/scheduling/components/SegmentedBottomSheet';

import { selectStaffSlotIdAtom } from '../../../../shared/SelectStaff/atoms';
import { CHANGE_STAFF_REASON_OPTIONS } from '../../../../shared/changeStaffReasonOptions';
import { isSlotDetailsBottomSheetOpenAtom, selectedSlotIdAtom } from '../../atom';

import {
    isReasonSheetOpenAtom,
    notesSheetSelectedReasonAtom,
    notesSheetSlotIdAtom,
    reasonSheetSelectedReasonAtom,
    reasonSheetSlotIdAtom,
} from './atoms';

const loadingButtonAtom = atom<'open' | 'select' | null>(null);

const ReasonBottomSheetHeader = () => {
    const { palette } = useTheme();

    const slotId = useAtomValue(reasonSheetSlotIdAtom);
    const toggleReasonSheet = useSetAtom(isReasonSheetOpenAtom);
    const isLoading = !!useAtomValue(loadingButtonAtom);

    const setSlotDetailsSheetSlotId = useSetAtom(selectedSlotIdAtom);
    const toggleSlotDetailsSheet = useSetAtom(isSlotDetailsBottomSheetOpenAtom);

    if (!slotId) return null;

    return (
        <Stack direction="row" alignItems="center" ml="-8px" spacing="4px">
            <Button
                variant="text"
                onClick={() => {
                    setSlotDetailsSheetSlotId(slotId);
                    toggleSlotDetailsSheet();
                    toggleReasonSheet();
                }}
                sx={{
                    color: palette.grey[600],
                    minWidth: 0,
                    width: '36px',
                    height: '36px',
                    p: '8px',
                    borderRadius: '50%',
                }}
                disabled={isLoading}
            >
                <ArrowLeft size={20} />
            </Button>
            <Typography fontSize="16px" fontWeight={700} lineHeight="36px">
                Choose a Reason
            </Typography>
        </Stack>
    );
};

const ReasonBottomSheetActions = () => {
    const [loadingButton, setLoadingButton] = useAtom(loadingButtonAtom);

    const slotId = useAtomValue(reasonSheetSlotIdAtom);
    const selectedReason = useAtomValue(reasonSheetSelectedReasonAtom);
    const toggle = useSetAtom(isReasonSheetOpenAtom);

    const setSelectStaffSlotId = useSetAtom(selectStaffSlotIdAtom);

    const { mutateAsync: markSlotOpen, isPending } = useMarkSlotOpen();

    if (!slotId) return null;

    const handleMarkOpen = async () => {
        if (!selectedReason) return;

        await markSlotOpen({ slotId, data: { reason: selectedReason.value } });
        toggle();
    };

    const onMarkOpen = async () => {
        setLoadingButton('open');

        try {
            await handleMarkOpen();
        } finally {
            setLoadingButton(null);
        }
    };

    const onSelectStaff = async () => {
        setLoadingButton('select');

        try {
            await handleMarkOpen();
        } finally {
            setLoadingButton(null);
        }

        setSelectStaffSlotId(slotId);
    };

    return (
        <>
            <Button variant="outlined" color="error" onClick={onMarkOpen} disabled={isPending}>
                {loadingButton === 'open' ? (
                    <CircularProgress size={20} thickness={4} sx={{ color: 'error.100' }} />
                ) : (
                    'Mark as Open Shift'
                )}
            </Button>
            <Button onClick={onSelectStaff} disabled={isPending}>
                {loadingButton === 'select' ? (
                    <CircularProgress size={20} thickness={4} sx={{ color: 'white' }} />
                ) : (
                    'Select Staff'
                )}
            </Button>
        </>
    );
};

const ReasonBottomSheetReasons = () => {
    const slotId = useAtomValue(reasonSheetSlotIdAtom);
    const toggle = useSetAtom(isReasonSheetOpenAtom);
    const [selectedReason, setSelectedReason] = useAtom(reasonSheetSelectedReasonAtom);

    const setReasonNotesSheetSlotId = useSetAtom(notesSheetSlotIdAtom);
    const setReasonNotesSheetSelectedReason = useSetAtom(notesSheetSelectedReasonAtom);

    return (
        <Stack spacing="12px">
            {CHANGE_STAFF_REASON_OPTIONS.map((reason) => {
                const { value, label, withNotes } = reason;

                return (
                    <Button
                        key={value}
                        variant="outlined"
                        onClick={() => {
                            if (withNotes) {
                                setReasonNotesSheetSlotId(slotId);
                                setReasonNotesSheetSelectedReason(reason);
                                toggle();
                            } else setSelectedReason(reason);
                        }}
                        sx={({ palette }) => ({
                            bgcolor: palette.grey[25],
                            justifyContent: 'flex-start',
                            gap: '8px',
                            p: '16px 12px',
                            fontWeight: 600,
                        })}
                        fullWidth
                    >
                        <Radio checked={value === selectedReason?.value} sx={{ pointerEvents: 'none' }} />
                        {label}
                    </Button>
                );
            })}
        </Stack>
    );
};

const ReasonBottomSheet = () => {
    const [isOpen, toggle] = useAtom(isReasonSheetOpenAtom);
    const selectedReason = useAtomValue(reasonSheetSelectedReasonAtom);
    const isLoading = !!useAtomValue(loadingButtonAtom);

    return (
        <SegmentedBottomSheet
            isOpen={isOpen}
            onClose={toggle}
            header={<ReasonBottomSheetHeader />}
            actions={selectedReason && !selectedReason.withNotes && <ReasonBottomSheetActions />}
            closeButton
            closeButtonProps={{ disabled: isLoading }}
        >
            <ReasonBottomSheetReasons />
        </SegmentedBottomSheet>
    );
};

export default ReasonBottomSheet;
