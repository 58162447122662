import { Typography, styled } from '@mui/material';

export const SingleLineTypography = styled(Typography)({
    overflow: 'hidden',
    textAlign: 'start',
    wordBreak: 'break-all',
    // Webkit-specific properties for line-clamping:
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
});
