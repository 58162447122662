import { Box, Stack, Theme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ReactElement, ReactNode, Ref, forwardRef } from 'react';

import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

const LayoutContainer = styled(Stack)({
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
});

const ContentContainer = styled(Box)({
    position: 'sticky',
    top: 0,
    zIndex: 10,
});

type ReactElementLike = true | ReactElement;

type AtLeastOne<T, K extends keyof T = keyof T> = Partial<T> & { [P in K]: Required<Pick<T, P>> }[K];

// Either a shared header or separate headers for desktop and mobile
type HeaderProps =
    | { header: ReactElementLike; desktopHeader?: never; mobileHeader?: never }
    | ({ header?: never } & AtLeastOne<{ desktopHeader: ReactElementLike; mobileHeader: ReactElementLike }>);

const WithHeader = forwardRef(function WithHeader(
    { children, ...props }: { children?: ReactNode } & HeaderProps,
    ref: Ref<HTMLDivElement>
) {
    const header = 'header' in props ? props.header : false;
    const desktopHeader = 'desktopHeader' in props ? props.desktopHeader : header;
    const mobileHeader = 'mobileHeader' in props ? props.mobileHeader : header;

    const isMobile = useMediaQuery<Theme>(({ breakpoints }) => breakpoints.down('sm'));

    return (
        <LayoutContainer ref={ref}>
            {(desktopHeader || mobileHeader) && (
                <ContentContainer>
                    {isMobile ? (
                        typeof mobileHeader === 'boolean' ? (
                            <MobileHeader />
                        ) : (
                            mobileHeader
                        )
                    ) : typeof desktopHeader === 'boolean' ? (
                        <DesktopHeader />
                    ) : (
                        desktopHeader
                    )}
                </ContentContainer>
            )}
            {children}
        </LayoutContainer>
    );
});

export default WithHeader;
