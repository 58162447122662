import { useMatches } from 'react-router';

/**
 * Checks if the given path is a match at any point in the current route hierarchy.
 *
 * @returns `true` if the path is a soft match, `false` otherwise.
 */
export const isSoftMatch = (matches: ReturnType<typeof useMatches>, path: string) =>
    matches.some((match) => match.pathname === path);

/**
 * Finds a match for the given path at any point in the current route hierarchy.
 *
 * @returns The most specific match found, or `null` if there isn't one.
 */
export const useSoftMatch = (path: string) => useMatches().findLast((match) => match.pathname === path) ?? null;
