import { Alert, Button, Stack, styled } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { PiPlusBold } from 'react-icons/pi';
import { Navigate, useNavigate, useParams } from 'react-router';

import { STANDARDIZED_STAFF_TYPES } from '@allie/utils/src/constants/scheduling/staff-types.constants';

import DesktopHeader from '~/components/Layout/DesktopHeader';
import WithHeader from '~/components/Layout/WithHeader';
import { useGetCommunitySettings } from '~/scheduling/api/queries/community-settings/getCommunitySettings';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import { useCreateStaff } from '~/scheduling/api/queries/staff/createStaff';
import { useCreateStaffSchedule } from '~/scheduling/api/queries/staff/createStaffSchedule';
import { useDeleteStaffSchedule } from '~/scheduling/api/queries/staff/deleteStaffSchedule';
import { useGetStaff } from '~/scheduling/api/queries/staff/getStaff';
import { useUpdateStaff } from '~/scheduling/api/queries/staff/updateStaff';
import { LayoutBackground } from '~/scheduling/components/LayoutBackground';
import { HorizontalSeparator } from '~/scheduling/components/shared';
import DeleteStaffModal from '~/scheduling/pages/StaffList/StaffDetails/components/Modal/DeleteStaffModal';

import { primaryStaffRoleAtom, staffScheduleModalDataAtom } from './atom';
import HeaderActions from './components/HeaderActions';
import ScheduleSection from './components/ScheduleSection';
import { StaffInfoForm } from './components/StaffInfoForm';
import { StaffScheduleModal } from './components/StaffScheduleModal';
import { mapCreateStaffScheduleParams, mapStaff, mapStaffInputParams } from './mapStaff';
import { StaffDetailsFormFields } from './types';

const FormContainer = styled(Stack)({
    backgroundColor: 'white',
    borderRadius: '8px',
});

const FormSection = styled(Stack)({
    padding: '24px',
    gap: '12px',
});

const FutureScheduleButton = styled(Button)(({ theme }) => ({
    borderColor: theme.palette.primary[500],
}));

const StaffDetails = () => {
    const navigate = useNavigate();
    const { id: staffIdStr } = useParams<{ id: string }>();
    const staffId = staffIdStr ? +staffIdStr : undefined;
    const form = useForm<StaffDetailsFormFields>();
    const schedulesField = useFieldArray({ control: form.control, name: 'schedules' });
    const [primaryStaffRole, setPrimaryStaffRole] = useAtom(primaryStaffRoleAtom);
    const [schedules, setSchedules] = React.useState<number[]>([0]);
    const setStaffScheduleModalData = useSetAtom(staffScheduleModalDataAtom);

    const { data: communitySettings } = useGetCommunitySettings();
    const { data: roleData } = useGetRoles();
    const { data: staff, isLoading: isGetStaffLoading, error: getStaffError } = useGetStaff(staffId);
    const { mutateAsync: createStaff, isPending: isCreateStaffPending } = useCreateStaff();
    const { mutateAsync: updateStaff, isPending: isUpdateStaffPending } = useUpdateStaff(staffId);
    const { mutateAsync: createStaffSchedule, isPending: isCreateStaffSchedulePending } = useCreateStaffSchedule();
    const { mutateAsync: deleteStaffSchedule, isPending: isDeleteStaffSchedulePending } = useDeleteStaffSchedule();

    const isEditing = !!staffId;
    const isLoading = !!staffId && isGetStaffLoading;
    const isSaving =
        isCreateStaffPending || isUpdateStaffPending || isCreateStaffSchedulePending || isDeleteStaffSchedulePending;

    const formDefaultOptions = React.useMemo<StaffDetailsFormFields | undefined>(
        () =>
            communitySettings && roleData && staff
                ? mapStaff(staff, communitySettings.firstDayOfWeek, roleData.roleShiftById)
                : undefined,
        [staff, communitySettings, roleData]
    );

    React.useEffect(() => {
        if (staff && formDefaultOptions) {
            // hack to make form rerender after staff was loaded
            // ref: https://stackoverflow.com/questions/62242657/how-to-change-react-hook-form-defaultvalue-with-useeffect
            form.reset(formDefaultOptions);
            setPrimaryStaffRole(staff.roles.find(({ primary }) => primary)?.staffRoleId ?? null);
            setSchedules(staff.schedules.map((_, index) => index));
        } else {
            // reset primary staff role to avoid UI errors
            setPrimaryStaffRole(null);
        }
    }, [staff, formDefaultOptions]);

    const onSubmit = async (
        staffInput: ReturnType<typeof mapStaffInputParams>,
        newSchedulesInput: ReturnType<typeof mapCreateStaffScheduleParams>[] | undefined
    ) => {
        let existingStaffId = staffId;

        if (existingStaffId) await updateStaff(staffInput);
        else existingStaffId = await createStaff(staffInput);

        if (existingStaffId && newSchedulesInput?.length && staffInput.type !== STANDARDIZED_STAFF_TYPES.PRN) {
            for (const newScheduleInput of newSchedulesInput) {
                await createStaffSchedule({ staffId: existingStaffId, ...newScheduleInput });
            }
        }
    };

    const prepareAndSubmit = async (data: StaffDetailsFormFields) => {
        if (!primaryStaffRole || !communitySettings) return;

        const staffInput = mapStaffInputParams(data, primaryStaffRole);

        const newSchedules = data.schedules?.filter((_, index) => !staff?.schedules[index]);

        let newSchedulesInput: ReturnType<typeof mapCreateStaffScheduleParams>[] | undefined;
        if (staffInput.type !== STANDARDIZED_STAFF_TYPES.PRN) {
            newSchedulesInput = newSchedules?.map((newSchedule) =>
                mapCreateStaffScheduleParams(newSchedule, roleData!.roleShiftById, communitySettings.firstDayOfWeek)
            );
        }

        const runSubmit = async () => {
            await onSubmit(staffInput, newSchedulesInput);
            navigate('/scheduling/staff-list');
        };

        if (newSchedulesInput?.length) {
            setStaffScheduleModalData({
                onConfirm: runSubmit,
                schedule: newSchedulesInput.at(-1)!, // Right now we're only double-checking the last schedule
            });
        } else await runSubmit();
    };

    const staffType = form.watch('type');

    const isScheduleFormSectionVisible = staffType && staffType !== STANDARDIZED_STAFF_TYPES.PRN;

    const handleDelete = async (scheduleIndex: number) => {
        // verify if the schedule index matches with
        // some staff schedule or not. If so, call the delete api
        // otherwise, remove the component

        if (staff?.schedules[scheduleIndex] && confirm('Are you sure you want to delete this schedule?')) {
            await deleteStaffSchedule({ staffId: staff.id, staffScheduleId: staff.schedules[scheduleIndex].id });
        } else {
            setSchedules(schedules.filter((index) => index !== scheduleIndex));
        }

        schedulesField.remove(scheduleIndex);
    };

    if (getStaffError) {
        return <Navigate to="/scheduling/staff-list" />;
    }

    const isStaffTypeAlertVisible =
        staff && staffType && staff.staffType !== staffType && staffType === STANDARDIZED_STAFF_TYPES.PRN;

    const handleAddFutureSchedule = () => {
        const previousSchedule = schedulesField.fields.at(-1);

        if (previousSchedule) {
            schedulesField.append({ ...previousSchedule, startDate: undefined });
        }

        setSchedules((prev) => [...prev, (prev.at(-1) ?? 0) + 1]);
    };

    const valuesHaveChanged = form.formState.isDirty;

    return (
        <form onSubmit={form.handleSubmit(prepareAndSubmit)} style={{ height: '100%' }}>
            <WithHeader
                header={
                    <DesktopHeader
                        title={isEditing ? 'Edit Staff' : 'Add Staff'}
                        actions={
                            <HeaderActions
                                isLoading={isLoading}
                                isSaving={isSaving}
                                formHasChanges={valuesHaveChanged}
                            />
                        }
                    />
                }
            >
                <LayoutBackground>
                    <FormContainer>
                        <FormSection>
                            {isStaffTypeAlertVisible && (
                                <Alert
                                    severity="warning"
                                    color="error"
                                    sx={({ palette }) => ({
                                        marginBottom: '24px',
                                        outline: `1px solid ${palette.error[500]}`,
                                    })}
                                >
                                    <strong>Attention:</strong> After changing a staff to PRN, their current and future
                                    schedules will be deleted.
                                </Alert>
                            )}
                            <StaffInfoForm
                                key={staff?.id}
                                form={form}
                                roleFieldDeselectable={!staffIdStr}
                                staff={staff}
                                isLoading={isLoading}
                                isSaving={isSaving}
                            />
                        </FormSection>

                        {isScheduleFormSectionVisible && (
                            <>
                                <HorizontalSeparator />
                                <FormSection>
                                    {schedules.map((_, index) => (
                                        <ScheduleSection
                                            key={index}
                                            form={form}
                                            staff={staff}
                                            scheduleIndex={index}
                                            onDelete={() => handleDelete(index)}
                                            isDeleting={isDeleteStaffSchedulePending}
                                            isSaving={isSaving}
                                        />
                                    ))}
                                    <FutureScheduleButton
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        startIcon={<PiPlusBold />}
                                        onClick={handleAddFutureSchedule}
                                        disabled={isSaving}
                                    >
                                        Add a future schedule
                                    </FutureScheduleButton>
                                </FormSection>
                            </>
                        )}
                    </FormContainer>
                </LayoutBackground>

                <DeleteStaffModal />
                <StaffScheduleModal />
            </WithHeader>
        </form>
    );
};
export default StaffDetails;
