import { Theme, useMediaQuery } from '@mui/material';
import { NotificationBell, NovuProvider, PopoverNotificationCenter } from '@novu/notification-center';
import React from 'react';
import { useSelector } from 'react-redux';

import { usePermissions } from '~/permissions/utils';
import { ReduxStore } from '~/types/redux';

export const NotificationCenter = () => {
    const isMobile = useMediaQuery<Theme>(({ breakpoints }) => breakpoints.down('sm'));

    const { userId, novuSubscriberId } = useSelector((state: ReduxStore) => state.session.sessionData);
    const hasPermission = usePermissions();

    if (!hasPermission('Community', 'view-notification-center')) return null;

    return (
        <NovuProvider
            subscriberId={String(userId)}
            subscriberHash={novuSubscriberId}
            applicationIdentifier={process.env.REACT_APP_NOVU_APP_ID as string}
            styles={{
                bellButton: {
                    root: {
                        svg: isMobile
                            ? {
                                  color: '#ffffff',
                                  fill: '#ffffff1f',
                              }
                            : {
                                  color: '#006b75',
                                  fill: '#ffffff1f',
                              },
                    },
                },
                layout: {
                    root: isMobile
                        ? {
                              maxWidth: 'calc(100vw - 16px)',
                              margin: '8px 8px 0',
                          }
                        : {},
                },
            }}
        >
            <PopoverNotificationCenter colorScheme={'light'}>
                {({ unseenCount }) => <NotificationBell unseenCount={unseenCount} />}
            </PopoverNotificationCenter>
        </NovuProvider>
    );
};
