import { Box, Button, Typography, useTheme } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { DateTime } from 'luxon';
import React from 'react';
import { IoChevronDown } from 'react-icons/io5';

import SegmentedBottomSheet from '~/scheduling/components/SegmentedBottomSheet';
import { Calendar } from '~/scheduling/components/calendar';
import { formatCalendarDateToDateTime, formatDateTimeToCalendarDate } from '~/scheduling/components/calendar/util';
import { Scheduling } from '~/scheduling/types';

import { datePickerValueAtom, isDatePickerBottomSheetOpenAtom } from '../../atom';

const SelectDateAction = ({ selectedDate }: { selectedDate: DateTime<true> }) => {
    const toggle = useSetAtom(isDatePickerBottomSheetOpenAtom);
    const setDatePickerValue = useSetAtom(datePickerValueAtom);

    const handleConfim = () => {
        setDatePickerValue(selectedDate);
        toggle();
    };

    return (
        <Button fullWidth onClick={handleConfim}>
            Select Date
        </Button>
    );
};

export const DatePickerPill = () => {
    const { palette } = useTheme();
    const toggle = useSetAtom(isDatePickerBottomSheetOpenAtom);
    const datePickerValue = useAtomValue(datePickerValueAtom);

    return (
        <Box
            bgcolor={palette.primary[600] as string}
            padding="6px 12px"
            borderRadius="100px"
            display="flex"
            gap="6px"
            alignItems="center"
            onClick={toggle}
        >
            <Typography variant="body1">{datePickerValue.toFormat('ccc, MMM d')}</Typography>
            <IoChevronDown size={16} type="bold" />
        </Box>
    );
};

export const DatePickerBottomSheet = () => {
    const [isOpen, toggle] = useAtom(isDatePickerBottomSheetOpenAtom);
    const datePickerValue = useAtomValue(datePickerValueAtom);
    const [selectedDate, setSelectedDate] = React.useState(datePickerValue);

    const handleChange = ({ startDate }: Scheduling.DateRange) => {
        setSelectedDate(formatCalendarDateToDateTime(startDate));
    };

    return (
        <SegmentedBottomSheet
            isOpen={isOpen}
            onClose={() => {
                toggle();
                setSelectedDate(datePickerValue);
            }}
            header="Choose Date"
            actions={<SelectDateAction selectedDate={selectedDate} />}
            closeButton
        >
            <Calendar
                startDate={formatDateTimeToCalendarDate(selectedDate)}
                onChangeValue={handleChange}
                singleSelection
            />
        </SegmentedBottomSheet>
    );
};
