import { useMediaQuery } from '@mui/system';
import React from 'react';
import { Navigate } from 'react-router';

import { usePermissionsQuery } from '~/api/queries/permissions';
import { usePermissions } from '~/permissions/utils';
import theme from '~/scheduling/theme';

import NavigateError from './NavigateError';
import PermissionsLoading from './PermissionsLoading';

const NavigateHome = () => {
    // TODO: Update after applying the Scheduling theme globally
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { data: permissions } = usePermissionsQuery();
    const hasPermission = usePermissions();

    // Care Revenue
    if (hasPermission('Community', 'undertake-resident-action')) return <Navigate to="/home" replace />;
    if (hasPermission('Community', 'read-operations-dashboard')) return <Navigate to="/operations" replace />;

    // Scheduling
    if (hasPermission('Community', 'scheduling-read-dashboard')) {
        if (isMobile) return <Navigate to="/scheduling/full-schedule" replace />;
        else return <Navigate to="/scheduling/dashboard" replace />;
    }
    if (hasPermission('Community', 'scheduling-read-my-schedule'))
        return <Navigate to="/scheduling/my-schedule" replace />;

    if (!permissions || Object.keys(permissions).length === 0) return <PermissionsLoading />; // Permissions haven't loaded yet

    return <NavigateError />; // There's no fallback home page if the user doesn't have any of the permissions
};

export default NavigateHome;
