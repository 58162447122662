import { Box, Paper, Switch, Typography, styled, useTheme } from '@mui/material';
import { useAtomValue } from 'jotai';
import React, { useEffect, useMemo } from 'react';
import { Controller, FieldPath, UseFormReturn, useWatch } from 'react-hook-form';
import { PiBriefcase, PiCalendar, PiClock, PiTrashBold, PiUser } from 'react-icons/pi';

import { STANDARDIZED_STAFF_SCHEDULE_TYPES } from '@allie/utils/src/constants/scheduling/staff-schedule-types.constants';

import { useGetLocations } from '~/scheduling/api/queries/locations/getLocations';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import { FormDropdown } from '~/scheduling/components/form/FormDropdown';
import { StaffList } from '~/scheduling/pages/StaffList/types';

import { primaryStaffRoleAtom } from '../../../atom';
import { DAYS_OF_WEEK } from '../../../constants';
import { StaffDetailsFormFields } from '../../../types';
import { useRoleShift } from '../useRoleShift';

import { CustomTimeFields } from './CustomTimeFields';

const ScheduleDayContainer = styled(Paper)(({ theme }) => ({
    gap: '8px',
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
    border: '1px solid',
    borderColor: theme.palette.grey[100],
    borderRadius: '8px',
}));

const ScheduleDayHeader = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
});

const TrashIcon = styled(PiTrashBold)<{ disabled: boolean }>(({ theme, disabled }) => ({
    width: '20px',
    height: '20px',
    color: disabled ? theme.palette.grey[200] : theme.palette.error[600],
    cursor: 'pointer',
    '&:hover': !disabled
        ? {
              color: theme.palette.error[500] as string,
          }
        : undefined,
}));

interface ScheduleDayProps {
    form: UseFormReturn<StaffDetailsFormFields>;
    title: string;
    scheduleDay?: StaffList.ScheduleDayData;
    dayIndex: number;
    scheduleIndex: number;
    weekIndex?: number;
    weekDay?: string;
    withSwitch?: boolean;
    withDayOfWeekField?: boolean;
    isEditing?: boolean;
    withDeleteButton?: boolean;
    onDelete?: (fieldIndex: number) => void;
}

export const ScheduleDay = ({
    form,
    title,
    scheduleDay,
    dayIndex,
    scheduleIndex,
    withDayOfWeekField,
    weekDay,
    weekIndex = 0,
    withSwitch,
    isEditing,
    withDeleteButton,
    onDelete,
}: ScheduleDayProps) => {
    const fieldName: FieldPath<StaffDetailsFormFields> = `schedules.${scheduleIndex}.shifts.${weekIndex}.${dayIndex}`;
    const [staffRoles, mainlyServe, type, enabled, selectedRole, selectedShiftId, selectedLocationId] = useWatch({
        name: [
            'roles',
            'mainlyServe',
            `schedules.${scheduleIndex}.type`,
            `${fieldName}.enabled`,
            `${fieldName}.staffRoleId`,
            `${fieldName}.staffRoleShiftId`,
            `${fieldName}.locationId`,
        ],
        control: form.control,
    });
    const { palette } = useTheme();
    const primaryRole = useAtomValue(primaryStaffRoleAtom);

    const { data: roleData } = useGetRoles();
    const roles = roleData?.roles;
    const roleById = roleData?.roleById;

    const { roleShift } = useRoleShift({ form, fieldName });

    const staffTypeOptions = useMemo(() => {
        return (
            roles
                ?.filter((option) => staffRoles?.includes(option.id))
                .map((option) => ({
                    label: option.name,
                    value: option.id.toString(),
                })) ?? []
        );
    }, [staffRoles]);

    const { data: locationsData } = useGetLocations();

    const locationOptions = useMemo(
        () =>
            (locationsData?.locations ?? []).map((location) => ({
                value: location.id.toString(),
                label: location.name,
            })),
        [locationsData]
    );

    const shiftOptions = useMemo(
        () =>
            roleById
                ?.get(+selectedRole)
                ?.staffRoleShifts.map(({ id, name }) => ({ value: id.toString(), label: name })) ?? [],
        [roleById, selectedRole]
    );

    // Auto-populate staff type and location based on primary role and mainly serve
    useEffect(() => {
        // Do not reset if not in edit mode
        // changing only on creation to avoid resetting on edit
        if (isEditing) return;

        if (!selectedRole && primaryRole) {
            form.setValue(`${fieldName}.staffRoleId`, primaryRole.toString());
        }

        if (!selectedLocationId && mainlyServe) {
            form.setValue(`${fieldName}.locationId`, mainlyServe.toString());
        }

        if (selectedRole && roleShift) {
            form.setValue(`${fieldName}.staffRoleShiftId`, roleShift.id.toString());
        }
    }, [selectedRole, primaryRole, selectedLocationId, mainlyServe, roleShift, isEditing]);

    const enableSwitch = !(enabled != null ? enabled === false : scheduleDay?.enabled === false);

    const disabledFields =
        isEditing ||
        // should only disable fields if the schedule type is weekly or biweekly
        ((type === STANDARDIZED_STAFF_SCHEDULE_TYPES.WEEKLY || type === STANDARDIZED_STAFF_SCHEDULE_TYPES.BIWEEKLY) &&
            !enableSwitch);

    return (
        <ScheduleDayContainer elevation={0}>
            <ScheduleDayHeader>
                <Box display="flex" gap="4px">
                    <Typography
                        sx={{ fontSize: '15px', fontWeight: '700', textTransform: 'capitalize' }}
                    >{`${title} ${dayIndex + 1}`}</Typography>
                    {weekDay && <Typography sx={{ fontSize: '15px', fontWeight: '400' }}>{weekDay}</Typography>}
                </Box>
                <Box display="flex" gap="4px">
                    {withSwitch && (
                        <Controller
                            control={form.control}
                            defaultValue={enableSwitch}
                            name={`${fieldName}.enabled`}
                            render={({ field: { onChange } }) => (
                                <Switch
                                    defaultChecked={enableSwitch}
                                    disabled={isEditing}
                                    onChange={(_, check) => onChange(check)}
                                />
                            )}
                        />
                    )}
                    {withDeleteButton && onDelete && (
                        <TrashIcon disabled={disabledFields} onClick={() => onDelete(dayIndex)} />
                    )}
                </Box>
            </ScheduleDayHeader>
            {withDayOfWeekField && (
                <FormDropdown
                    name={`${fieldName}.dayOfWeek`}
                    placeholder="Day of Week"
                    icon={<PiCalendar size={20} color={palette.grey[300]} />}
                    form={form}
                    options={DAYS_OF_WEEK.map((dayOfWeek, value) => ({
                        value: (value + 1).toString(),
                        label: dayOfWeek,
                    }))}
                    selectProps={{ disabled: disabledFields, defaultValue: scheduleDay?.dayOfWeek }}
                />
            )}
            <FormDropdown
                form={form}
                name={`${fieldName}.staffRoleId`}
                placeholder="Staff Role"
                icon={<PiUser size={20} color={palette.grey[300]} />}
                options={staffTypeOptions}
                selectProps={{ disabled: disabledFields, defaultValue: scheduleDay?.staffRoleId }}
            />
            <FormDropdown
                name={`${fieldName}.locationId`}
                placeholder="Location"
                icon={<PiBriefcase size={20} color={palette.grey[300]} />}
                form={form}
                options={locationOptions}
                selectProps={{ disabled: disabledFields, defaultValue: scheduleDay?.locationId }}
            />
            <FormDropdown
                name={`${fieldName}.staffRoleShiftId`}
                placeholder="Shift"
                icon={<PiClock size={20} color={palette.grey[300]} />}
                form={form}
                options={shiftOptions}
                selectProps={{ disabled: disabledFields || !selectedRole, defaultValue: scheduleDay?.staffRoleShiftId }}
            />
            <CustomTimeFields
                fieldName={`schedules.${scheduleIndex}.shifts.${weekIndex}.${dayIndex}`}
                form={form}
                isEditing={isEditing}
                disabled={disabledFields || !selectedShiftId}
                defaultStartTime={scheduleDay?.customStartTime}
                defaultEndTime={scheduleDay?.customEndTime}
            />
        </ScheduleDayContainer>
    );
};
