import { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';

import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';

import { StaffDetailsFormFields } from '../../types';

interface UseRoleShiftProps {
    fieldName: `schedules.${number}.shifts.${number}.${number}`;
    form: UseFormReturn<StaffDetailsFormFields>;
}

export const useRoleShift = ({ fieldName, form }: UseRoleShiftProps) => {
    const { data: roleData } = useGetRoles();
    const roleById = roleData?.roleById;
    const roleShiftById = roleData?.roleShiftById;

    const [selectedRole, selectedShiftId] = useWatch({
        name: [`${fieldName}.staffRoleId`, `${fieldName}.staffRoleShiftId`],
        control: form.control,
    });

    return useMemo(() => {
        const roleShifts = selectedRole ? roleById?.get(+selectedRole)?.staffRoleShifts : null;
        const roleShiftId = selectedShiftId ? +selectedShiftId : null;

        const previousSelectedShift = roleShiftId ? roleShiftById?.get(roleShiftId) : null;

        const roleShift = previousSelectedShift
            ? roleShifts?.find((s) => s.index === previousSelectedShift?.index)
            : null;

        return { roleShift };
    }, [selectedShiftId, selectedRole, roleById, roleShiftById]);
};
