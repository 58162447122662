import { DateTime } from 'luxon';
import React from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/scheduling/api/common';
import { GetPublishedDateRange } from '~/scheduling/api/types/shift-slot/getPublishedDateRange';

export const useGetPublishedDateRange = (teamId?: number) => {
    const branchId = useBranchId()!;
    const token = useToken();

    const queryFn = React.useCallback(async () => {
        const options = { headers: { Authorization: token } };

        const { data } = await api.get<GetPublishedDateRange.Response>(
            `/scheduling/${branchId}/shift-slot/team/${teamId}/published-date-range`,
            options
        );

        return {
            firstPublishedDate: data.response.firstPublishedDate
                ? DateTime.fromISO(data.response.firstPublishedDate)
                : null,
            lastPublishedDate: data.response.lastPublishedDate
                ? DateTime.fromISO(data.response.lastPublishedDate)
                : null,
        };
    }, [branchId, teamId, token]);

    return useErrorHandledQuery({
        queryKey: ['published-date-range', branchId, teamId],
        queryFn,
        staleTime: 30000,
        enabled: !!branchId && !!teamId,
    });
};
