import { Box, Button, Chip, CircularProgress, Stack, Typography, styled, useTheme } from '@mui/material';
import { UserList } from '@phosphor-icons/react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { useGetAgencyStaffList } from '~/scheduling/api/queries/agency-staff/getAgencyStaffList';
import { useGetLocations } from '~/scheduling/api/queries/locations/getLocations';
import { useGetFullSchedule } from '~/scheduling/api/queries/shift-slot/getFullSchedule';
import { useMoveSlotStaff } from '~/scheduling/api/queries/shift-slot/moveSlotStaff';
import { useSwapSlotStaff } from '~/scheduling/api/queries/shift-slot/swapSlotStaff';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import { useGetStaffList } from '~/scheduling/api/queries/staff/getStaffList';

import CustomModal from '../../../../../../../../components/CustomModal';
import { isReplaceSlotModalOpenAtom, replaceSlotModalSlotIdsAtom } from '../../atoms';

const ReplaceSlotHeader = () => (
    <Stack alignItems="center" spacing="24px">
        <ReplaceSlotIcon />
        <ReplaceSlotDetails />
    </Stack>
);

const ReplaceSlotIcon = () => {
    const { palette } = useTheme();

    return (
        <Box
            sx={{
                bgcolor: palette.grey[50],
                height: '88px',
                p: '32px',
                borderRadius: '50%',
            }}
        >
            <UserList color={palette.grey[600]} weight="fill" fontSize="24px" />
        </Box>
    );
};

const ReplaceSlotDetails = () => {
    const [slotIdFrom, slotIdTo] = useAtomValue(replaceSlotModalSlotIdsAtom) ?? [];

    const { data: staffListData } = useGetStaffList();
    const staffById = staffListData?.staffById;

    const { data: agencyStaffListData } = useGetAgencyStaffList();
    const agencyStaffById = agencyStaffListData?.agencyStaffById;

    const { data: roleData } = useGetRoles();
    const roleById = roleData?.roleById;
    const roleShiftById = roleData?.roleShiftById;

    const { data: locationData } = useGetLocations();
    const locationById = locationData?.locationById;

    const { data: fullScheduleData } = useGetFullSchedule();
    const slotById = fullScheduleData?.slotById;

    if (!slotIdTo || !slotIdFrom || !slotById) return null;

    const slotFrom = slotById.get(slotIdFrom);
    const slotTo = slotById.get(slotIdTo);

    if (!slotFrom || !slotTo) return null;

    const { staffId, agencyStaffId } = slotFrom;
    const { shiftDay, roleId, roleShiftId, locationId } = slotTo;
    const dayStr = DateTime.fromISO(shiftDay).toFormat('EEE, MMM d');
    const role = roleById?.get(roleId)?.name;
    const roleShift = roleShiftById?.get(roleShiftId)?.name;
    const location = locationById?.get(locationId)?.abbreviation;
    const staff = staffId && staffById?.get(staffId)?.firstName;
    const agencyStaff = agencyStaffId && agencyStaffById?.get(agencyStaffId)?.name;

    return (
        <Stack alignItems="center" spacing="12px">
            <Typography variant="h5" sx={{ textAlign: 'center', textWrap: 'balance' }}>
                Are you sure you want to change {staff ?? agencyStaff}&rsquo;s shift?
            </Typography>
            <ReplaceSlotChipRow>
                <Chip variant="outlined" color="primary" label={dayStr} />
                <Chip variant="outlined" color="primary" label={roleShift} />
                <Chip variant="outlined" label={location} />
                <Chip variant="outlined" label={role} />
            </ReplaceSlotChipRow>
        </Stack>
    );
};

const ReplaceSlotChipRow = styled(Stack)({
    flexDirection: 'row',
    gap: '8px',
});

const ReplaceSlotActions = () => {
    const onClose = useSetAtom(isReplaceSlotModalOpenAtom);

    const [loadingButton, setLoadingButton] = useState<'swap' | 'replace' | null>(null);

    const [slotIdFrom, slotIdTo] = useAtomValue(replaceSlotModalSlotIdsAtom) ?? [];

    const { data: staffListData } = useGetStaffList();
    const staffById = staffListData?.staffById;

    const { data: agencyStaffListData } = useGetAgencyStaffList();
    const agencyStaffById = agencyStaffListData?.agencyStaffById;

    const { data: fullScheduleData } = useGetFullSchedule();
    const slotById = fullScheduleData?.slotById;

    const { mutateAsync: swapSlotStaff, isPending: isSwapSlotStaffPending } = useSwapSlotStaff();
    const { mutateAsync: moveSlotStaff, isPending: isMoveSlotStaffPending } = useMoveSlotStaff();

    if (!slotIdFrom || !slotIdTo || !slotById) return null;

    const slotFrom = slotById.get(slotIdFrom);
    const slotTo = slotById.get(slotIdTo);

    if (!slotFrom || !slotTo) return null;

    const { staffId: staffIdFrom, agencyStaffId: agencyStaffIdFrom } = slotFrom;
    const staffFrom = staffIdFrom && staffById?.get(staffIdFrom)?.firstName;
    const agencyStaffFrom = agencyStaffIdFrom && agencyStaffById?.get(agencyStaffIdFrom)?.name;

    const { staffId: staffIdTo, agencyStaffId: agencyStaffIdTo } = slotTo;
    const staffTo = staffIdTo && staffById?.get(staffIdTo)?.firstName;
    const agencyStaffTo = agencyStaffIdTo && agencyStaffById?.get(agencyStaffIdTo)?.name;

    const isPending = isSwapSlotStaffPending || isMoveSlotStaffPending;

    return (
        <Stack spacing="8px">
            <Button
                size="large"
                onClick={async () => {
                    setLoadingButton('swap');

                    try {
                        await swapSlotStaff({ slotId1: slotIdFrom, slotId2: slotIdTo });
                    } finally {
                        setLoadingButton(null);
                    }

                    onClose();
                }}
                disabled={isPending}
            >
                {loadingButton === 'swap' ? (
                    <CircularProgress size={24} thickness={4} sx={{ color: 'white' }} />
                ) : (
                    `Swap Shift with ${staffTo ?? agencyStaffTo}`
                )}
            </Button>
            <Button
                variant="outlined"
                color="error"
                size="large"
                onClick={async () => {
                    setLoadingButton('replace');

                    try {
                        await moveSlotStaff({ slotId1: slotIdFrom, slotId2: slotIdTo });
                    } finally {
                        setLoadingButton(null);
                    }

                    onClose();
                }}
                disabled={isPending}
            >
                {loadingButton === 'replace' ? (
                    <CircularProgress size={24} thickness={4} sx={{ color: 'error.100' }} />
                ) : (
                    `Move ${staffFrom ?? agencyStaffFrom}'s Shift`
                )}
            </Button>
            <Button variant="outlined" size="large" onClick={onClose} disabled={isPending}>
                Cancel
            </Button>
        </Stack>
    );
};

const ReplaceSlotModal = () => {
    const [isOpen, onClose] = useAtom(isReplaceSlotModalOpenAtom);

    return (
        <CustomModal isOpen={isOpen} onClose={onClose}>
            <Stack p="32px" spacing="32px">
                <ReplaceSlotHeader />
                <ReplaceSlotActions />
            </Stack>
        </CustomModal>
    );
};

export default ReplaceSlotModal;
