import { Box, Skeleton, Stack, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import range from 'lodash/range';
import React, { ComponentProps, forwardRef } from 'react';

import { useGetAgencyStaffList } from '~/scheduling/api/queries/agency-staff/getAgencyStaffList';
import { useGetStaffList } from '~/scheduling/api/queries/staff/getStaffList';
import { ScheduleGridSchema } from '~/scheduling/types';
import { useWeekInterval } from '~/scheduling/useWeekInterval';

import { useScheduleGridSchema } from '../../../../../../../useScheduleGridSchema';
import { slotItemDraggingCoordsAtom, slotItemIsDraggingAtom } from '../atoms';

import ShiftSlotGrid from './ShiftSlotGrid';
import { GRID_COLUMNS } from './shared';

const GridContainer = styled(Stack)(({ theme: { palette } }) => ({
    // 5px-wide striped background; transition between white and grey along 1px and back to avoid anti-aliasing artifacts.
    backgroundImage: `repeating-linear-gradient(
        30deg,
        ${palette.grey[50]} 0px,
        ${palette.grey[100]} 1px,
        ${palette.grey[50]} 2px,
        ${palette.grey[50]} 5px
    )`,
    flexDirection: 'row',
    overflowY: 'auto',
    userSelect: 'none', // Text selection anywhere in the grid will mess up dragging
}));

const GridStack = styled(Stack)({
    width: '100%',
});

const VerticalSpacer = styled(Box)(({ theme: { palette } }) => ({
    backgroundColor: palette.grey[25],
    width: '8px',
}));

const HorizontalSpacerCell = (props: ComponentProps<typeof Grid>) => <Grid xs={1} height="8px" {...props} />;

const HorizontalSpacerRow = ({ days }: { days: ScheduleGridSchema.Day[] }) => (
    <>
        <HorizontalSpacerCell bgcolor="grey.25" /* Skip shift info column */ />
        {days.map((day, index) => (
            <HorizontalSpacerCell key={index} bgcolor={day.isPreview || day.isWeekend ? 'transparent' : 'grey.25'} />
        ))}
    </>
);

const WeekGrid = forwardRef<HTMLDivElement>(function WeekGrid(_, ref) {
    const { weekInterval } = useWeekInterval();
    const scheduleGridSchema = useScheduleGridSchema({ interval: weekInterval });

    const { isPending: isGetStaffListPending } = useGetStaffList();
    const { isPending: isGetAgencyStaffListPending } = useGetAgencyStaffList();

    const slotItemIsDragging = useAtomValue(slotItemIsDraggingAtom);
    const resetSlotItemIsDragging = useResetAtom(slotItemIsDraggingAtom);

    const setSlotItemDraggingCoords = useSetAtom(slotItemDraggingCoordsAtom);
    const updateSlotItemDraggingCoords = (x: number, y: number) =>
        slotItemIsDragging && setSlotItemDraggingCoords([x, y]);

    if (!scheduleGridSchema || isGetStaffListPending || isGetAgencyStaffListPending) {
        return (
            <Stack p="8px" spacing="12px">
                {range(3).map((i) => (
                    <Grid key={i} container columns={GRID_COLUMNS}>
                        {range(GRID_COLUMNS * 4).map((j) => (
                            <Grid key={j} xs={1}>
                                <Box p="4px">
                                    <Skeleton height="36px" sx={{ flex: 1 }} />
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                ))}
            </Stack>
        );
    }

    // TODO: Create empty view

    return (
        <GridContainer
            ref={ref}
            // State update on the child being dragged is not fast enough and it may 'slip' off
            // the cursor and get cancelled, so we handle these on this bigger containing element
            onMouseMove={({ clientX, clientY }) => updateSlotItemDraggingCoords(clientX, clientY)}
            onMouseLeave={resetSlotItemIsDragging}
            onMouseUp={resetSlotItemIsDragging}
            onTouchMove={(event) => {
                const { clientX, clientY } = event.touches[0];
                updateSlotItemDraggingCoords(clientX, clientY);
            }}
            onTouchCancel={resetSlotItemIsDragging}
            onTouchEnd={resetSlotItemIsDragging}
        >
            <VerticalSpacer />

            <GridStack>
                {scheduleGridSchema.shifts.map((shift, index) => (
                    <Grid key={index} container columns={GRID_COLUMNS}>
                        {/* Extra spacing before first shift grid */}
                        {index === 0 && <HorizontalSpacerRow days={scheduleGridSchema.days} />}

                        <ShiftSlotGrid shift={shift} days={scheduleGridSchema.days} />

                        <HorizontalSpacerRow days={scheduleGridSchema.days} />
                    </Grid>
                ))}
            </GridStack>

            <VerticalSpacer />
        </GridContainer>
    );
});

export default WeekGrid;
