import { TabList } from '@mui/lab';
import { Tab } from '@mui/material';
import { useAtom } from 'jotai';
import React from 'react';

import MobileHeader from '~/components/Layout/MobileHeader';
import WithHeader from '~/components/Layout/WithHeader';
import { useGetLocations } from '~/scheduling/api/queries/locations/getLocations';
import MobileTabContext from '~/scheduling/components/MobileTabContext';
import { SELECTOR_ALL_ID } from '~/scheduling/constants';

import ConfirmStaffModal from '../../shared/SelectStaff/ConfirmStaffModal';

import { selectedTabAtom } from './atom';
import { ChangeStaffNotesBottomSheet, ChangeStaffReasonBottomSheet } from './components/ChangeStaff';
import { DatePickerBottomSheet, DatePickerPill } from './components/DatePicker';
import SelectStaffBottomSheet from './components/SelectStaff/SelectStaffBottomSheet';
import { ShiftSlotList } from './components/ShiftSlotList';
import { SlotDetailsBottomSheet } from './components/SlotDetails';

const Tabs = () => {
    const [selectedTab, setSelectedTab] = useAtom(selectedTabAtom);

    const { data: locationsData } = useGetLocations();
    const locations = locationsData?.locations ?? [];

    if (!locations.length) return;

    return (
        <MobileTabContext value={selectedTab}>
            <TabList onChange={(_, newTab: typeof selectedTab) => setSelectedTab(newTab)} sx={{ px: '24px' }}>
                <Tab label="All" value={SELECTOR_ALL_ID} />
                {locations.map(({ id, abbreviation }) => (
                    <Tab key={id} label={abbreviation} value={id} />
                ))}
            </TabList>

            <ShiftSlotList />
        </MobileTabContext>
    );
};

const Daily = () => {
    return (
        <WithHeader mobileHeader={<MobileHeader actions={<DatePickerPill />} />}>
            <Tabs />

            <DatePickerBottomSheet />
            <SlotDetailsBottomSheet />
            <ChangeStaffReasonBottomSheet />
            <ChangeStaffNotesBottomSheet />
            <SelectStaffBottomSheet />

            {/* TODO: Make this a bottom sheet */}
            <ConfirmStaffModal />
        </WithHeader>
    );
};

export default Daily;
