import {
    NotifyStaffGroupCaptionMessage,
    NotifyStaffGroupCaptionRow,
    NotifyStaffGroupCaptionValue,
    NotifyStaffGroupContainer,
    NotifyStaffGroupIconBox,
    NotifyStaffGroupIconNameContainer,
    NotifyStaffGroupName,
    NotifyStaffGroupNameContainer,
    NotifyStaffGroupNotes,
    NotifyStaffGroupNotesContainer,
    NotifyStaffGroupNotesIconBox,
    NotifyStaffGroupsStack,
    NotifyStaffTimelineConnector,
    numberIcons,
} from '.';
import { Box, Button, CircularProgress, MenuItem, Select, Stack, Typography, useTheme } from '@mui/material';
import { CaretDown, Check, Note } from '@phosphor-icons/react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect, useMemo, useState } from 'react';

import { useCreateSlotTeamNotifications } from '~/scheduling/api/queries/shift-slot/createSlotTeamNotifications';
import { CreateSlotTeamNotifications } from '~/scheduling/api/types/shift-slot/createSlotTeamNotifications';

import {
    NOTIFY_STAFF_DYNAMIC_OPTIONS_TO_SHOW,
    NOTIFY_STAFF_GROUPS,
    NOTIFY_STAFF_TIME_OPTIONS,
    NOTIFY_STAFF_TITLE_BY_GROUP,
    notifyStaffTimeValueToDateTime,
} from '../../../Desktop/utils/notifyStaff';
import {
    isSelectStaffNotifyNotesOpenAtom,
    selectStaffNotifyNotesAtom,
    selectStaffNotifySelectedTimesAtom,
    selectStaffNotifyTimeOptionsAtom,
    selectStaffSlotIdAtom,
} from '../atoms';

const useFilteredTimeOptions = (index: number) => {
    const timeOptions = useAtomValue(selectStaffNotifyTimeOptionsAtom);
    let filteredTimeOptions = [...timeOptions];

    const selectedTimes = useAtomValue(selectStaffNotifySelectedTimesAtom);
    const previousSelectedTimes = selectedTimes.slice(0, index);

    // Make this group's options be at least the same time as the previous groups
    timeOptions.forEach(({ value }) => {
        previousSelectedTimes.forEach((existingValue) => {
            if (Number.isFinite(value) && value === existingValue) {
                filteredTimeOptions = filteredTimeOptions.filter(({ value: otherValue }) => otherValue >= value);
            }
        });
    });

    filteredTimeOptions = [
        NOTIFY_STAFF_TIME_OPTIONS.now,
        ...filteredTimeOptions
            .filter(({ value }) => Number.isFinite(value))
            .slice(0, NOTIFY_STAFF_DYNAMIC_OPTIONS_TO_SHOW),
        NOTIFY_STAFF_TIME_OPTIONS.futureSkip,
    ];

    return filteredTimeOptions;
};

const NotOngoingNotifyStaffGroups = () => (
    <NotifyStaffGroupsStack>
        {NOTIFY_STAFF_GROUPS.map((_, index) => (
            <NotOngoingNotifyStaffGroup key={index} index={index} />
        ))}
        <NotifyStaffTimelineConnector />
    </NotifyStaffGroupsStack>
);

const NotOngoingNotifyStaffGroup = ({ index }: { index: number }) => {
    const { palette } = useTheme();

    const name = NOTIFY_STAFF_TITLE_BY_GROUP[NOTIFY_STAFF_GROUPS[index]];
    const Icon = numberIcons[index];

    return (
        <NotifyStaffGroupContainer>
            <NotifyStaffGroupIconNameContainer>
                <NotifyStaffGroupIconBox>
                    <Icon color={palette.grey[500]} fontSize={16} weight="fill" />
                </NotifyStaffGroupIconBox>
                <NotifyStaffGroupNameContainer>
                    <NotifyStaffGroupName>{name} Staff</NotifyStaffGroupName>
                </NotifyStaffGroupNameContainer>
            </NotifyStaffGroupIconNameContainer>
            <NotOngoingNotifyStaffGroupCaption index={index} />
        </NotifyStaffGroupContainer>
    );
};

const NotOngoingNotifyStaffGroupCaption = ({ index }: { index: number }) => {
    const { palette } = useTheme();

    const [isTimeSelectOpen, setIsTimeSelectOpen] = useState(false);

    const selectedTimes = useAtomValue(selectStaffNotifySelectedTimesAtom);
    const selectedTime = selectedTimes[index];

    const timeOptions = useAtomValue(selectStaffNotifyTimeOptionsAtom);
    const timeOption = timeOptions.find(({ value: selectorValue }) => selectorValue === selectedTime);

    if (!timeOption) return null;

    const { message, caption, color } = timeOption;

    return (
        <NotifyStaffGroupCaptionRow>
            <NotifyStaffGroupCaptionMessage>{message}</NotifyStaffGroupCaptionMessage>
            <Box position="relative">
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing="4px"
                    sx={{
                        m: '-4px -2px',
                        p: '4px 2px',
                        borderRadius: '4px',
                        userSelect: 'none',
                        cursor: 'pointer',
                        '&:hover': { bgcolor: palette.grey[50] },
                    }}
                    onClick={() => setIsTimeSelectOpen((prev) => !prev)}
                >
                    <NotifyStaffGroupCaptionValue color={color}>{caption}</NotifyStaffGroupCaptionValue>
                    <CaretDown color={palette.grey[600]} fontSize={14} weight="bold" />
                </Stack>
                <NotifyStaffTimeSelect
                    index={index}
                    isTimeSelectOpen={isTimeSelectOpen}
                    setIsTimeSelectOpen={setIsTimeSelectOpen}
                />
            </Box>
        </NotifyStaffGroupCaptionRow>
    );
};

const NotifyStaffTimeSelect = ({
    index,
    isTimeSelectOpen,
    setIsTimeSelectOpen,
}: {
    index: number;
    isTimeSelectOpen: boolean;
    setIsTimeSelectOpen: (value: boolean) => void;
}) => {
    const { palette } = useTheme();

    const filteredTimeOptions = useFilteredTimeOptions(index);

    const [selectedTimes, setSelectedTimes] = useAtom(selectStaffNotifySelectedTimesAtom);
    const selectedTime = selectedTimes[index];

    // Fix this group's time after a previous one is updated
    // and the current selected option is no longer valid
    useEffect(() => {
        if (!filteredTimeOptions.some(({ value }) => value === selectedTime)) {
            setSelectedTimes((selectedTimes) => {
                const newSelectedTimes = [...selectedTimes];
                newSelectedTimes[index] = filteredTimeOptions.findLast(({ value }) => Number.isFinite(value))!.value;
                return newSelectedTimes;
            });
        }
    }, [filteredTimeOptions, selectedTime]);

    const handleOnChange = (value: number) =>
        setSelectedTimes((selectedTimes) => {
            const newSelectedTimes = [...selectedTimes];
            newSelectedTimes[index] = value;

            // Fix previous groups to be at most the same time as this group
            for (let i = 0; i < index; i++) {
                if (Number.isFinite(newSelectedTimes[i]) && newSelectedTimes[i] > value) newSelectedTimes[i] = value;
            }

            // Fix next groups to be at least the same time as this group, if it hasn't been skipped
            for (let i = index + 1; i < newSelectedTimes.length; i++) {
                if (Number.isFinite(value) && newSelectedTimes[i] < value) newSelectedTimes[i] = value;
            }

            return newSelectedTimes;
        });

    return (
        <Select
            open={isTimeSelectOpen}
            onClose={() => setIsTimeSelectOpen(false)}
            value={selectedTime}
            onChange={({ target: { value } }) => handleOnChange(value as number)}
            sx={{
                position: 'absolute',
                bottom: '-4px',
                left: '50%',
                transform: 'translateX(-50%)',
                opacity: 0,
                pointerEvents: 'none',
            }}
            MenuProps={{
                sx: {
                    '& .MuiPaper-root': {
                        borderRadius: '6px',
                        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
                    },
                    '& .MuiMenu-list': {
                        p: 0,
                        border: '1px solid',
                        borderColor: palette.grey[50],
                    },
                },
            }}
        >
            {filteredTimeOptions.map(({ value, label, color }, index) => (
                <MenuItem
                    key={index}
                    value={value}
                    sx={{
                        justifyContent: 'space-between',
                        p: '8px 10px',
                        gap: '20px',
                        ...(index !== 0 ? { borderTop: '1px solid', borderColor: palette.grey[50] } : {}),
                    }}
                >
                    <Typography color={color} variant="body1" fontWeight={600}>
                        {label}
                    </Typography>
                    {value === selectedTime && <Check color={palette.primary.main} weight="bold" fontSize={14} />}
                </MenuItem>
            ))}
        </Select>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NotOngoingNotifyStaffNotes = () => {
    const { palette } = useTheme();

    const toggle = useSetAtom(isSelectStaffNotifyNotesOpenAtom);
    const notes = useAtomValue(selectStaffNotifyNotesAtom);

    return (
        <NotifyStaffGroupNotesContainer
            sx={{
                userSelect: 'none',
                cursor: 'pointer',
                '&:hover': {
                    color: palette.grey[900],
                    bgcolor: palette.grey[50],
                    borderColor: palette.grey[200],
                },
            }}
            onClick={toggle}
        >
            <NotifyStaffGroupNotesIconBox>
                <Note fontSize={16} weight="fill" />
            </NotifyStaffGroupNotesIconBox>
            <NotifyStaffGroupNotes>{notes ?? 'Add notes here (optional)'}</NotifyStaffGroupNotes>
        </NotifyStaffGroupNotesContainer>
    );
};

const NotOngoingNotifyStaffActions = () => {
    const slotId = useAtomValue(selectStaffSlotIdAtom)!;

    const notes = useAtomValue(selectStaffNotifyNotesAtom);
    const selectedTimes = useAtomValue(selectStaffNotifySelectedTimesAtom);
    const parsedTimes = useMemo(() => selectedTimes.map(notifyStaffTimeValueToDateTime), [selectedTimes]);

    const { mutate: createTeamNotifications, isPending } = useCreateSlotTeamNotifications();

    return (
        <Button
            onClick={() => {
                const teamNotifications = parsedTimes
                    .map((time, index) => ({
                        group: NOTIFY_STAFF_GROUPS[index],
                        notifyAt: time?.toISO() ?? null,
                        notes,
                    }))
                    .filter(({ notifyAt }) => notifyAt) as CreateSlotTeamNotifications.TeamNotification[];

                createTeamNotifications({ slotId, data: teamNotifications });
            }}
            disabled={!parsedTimes.length || isPending}
        >
            {isPending ? <CircularProgress size={20} thickness={4} sx={{ color: 'white' }} /> : 'Notify by Sequence'}
        </Button>
    );
};

const NotOngoingNotifyStaffTabPanel = () => (
    <>
        <NotOngoingNotifyStaffGroups />
        {/* TODO: uncomment when we are ready to add notes back */}
        {/* <NotOngoingNotifyStaffNotes />
        <NotOngoingNotifyStaffNotesModal /> */}
        <NotOngoingNotifyStaffActions />
    </>
);

export default NotOngoingNotifyStaffTabPanel;
