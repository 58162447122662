import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { connect } from 'react-redux';

import { useResidentsQuery } from '~/api/queries/residents';
import langDictionary from '~/app-strings';
import Loading from '~/components/Shared/Loading';
import ResidentRowData from '~/pages/Residents/components/ResidentRowData';
import ResidentRowSkeleton from '~/pages/Residents/components/ResidentRowSkeleton';
import { ReduxStore } from '~/types/redux';
import { ResidentsListResponse } from '~/types/residents';

const RootStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        position: 'relative',
        width: '100%',
    })
);

type Props = {
    branchId: number;
    residentsToShow: ResidentsListResponse[];
};

const ResidentsContainer = (props: Props) => {
    const { branchId, residentsToShow } = props;
    const { residents: residentsDictionary } = langDictionary;

    const {
        data: residentsList,
        isLoading: residentsListIsLoading,
        isError: residentsListIsError,
    } = useResidentsQuery({ branchId });

    if (residentsListIsLoading) {
        return <Loading />;
    }

    if (residentsListIsError || !residentsList) {
        return null;
    }

    return (
        <RootStyle>
            {residentsListIsLoading && <ResidentRowSkeleton totalItems={3} />}
            {!residentsListIsLoading && !residentsList?.length && !residentsToShow.length && (
                <Typography variant="subtitle1">{residentsDictionary.noResidents}</Typography>
            )}
            {!residentsListIsLoading && residentsList.length > 0 && !residentsToShow.length && (
                <Typography variant="subtitle1">{residentsDictionary.noMatch}</Typography>
            )}
            {!residentsListIsLoading &&
                residentsToShow.map((resident) => (
                    <ResidentRowData key={resident.residentId} resident={resident} branchId={branchId} />
                ))}
        </RootStyle>
    );
};

const mapStateToProps = ({ session }: ReduxStore) => {
    const {
        sessionData: { branchId },
    } = session;

    return {
        branchId,
    };
};

export default connect(mapStateToProps)(ResidentsContainer);
