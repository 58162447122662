import { Box, useTheme } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import React, { memo, useEffect, useMemo, useRef } from 'react';

import { SHIFT_SLOT_FLAG, SHIFT_SLOT_STATUS } from '@allie/utils/src/constants/scheduling/shift-slot.constants';

import { FullScheduleSlot } from '~/scheduling/api/queries/shift-slot/getFullSchedule';
import {
    selectStaffSlotIdAtom,
    selectStaffTabAtom,
} from '~/scheduling/pages/Schedule/Manager/shared/SelectStaff/atoms';
import { isSlotInTheFuture } from '~/scheduling/pages/Schedule/Manager/shared/isSlotInTheFuture';

import {
    moveSlotModalSlotIdsAtom,
    slotItemCanDropAtom,
    slotItemCannotDropReasonAtom,
    slotItemDraggingCoordsAtom,
    slotItemDraggingIdAtom,
    slotItemDraggingOverIdAtom,
    slotItemIsDraggingAtom,
} from '../../atoms';

import { NonDraggingSlotItemButton, SlotItemBox, isInsideRect } from './shared';

const OpenSlotItemButton = ({ slot }: { slot: FullScheduleSlot }) => {
    const { palette } = useTheme();

    const setSelectStaffSlotId = useSetAtom(selectStaffSlotIdAtom);
    const setSelectStaffTab = useSetAtom(selectStaffTabAtom);

    const notifiedStatus = slot.flags?.some((flag) => flag.type === SHIFT_SLOT_FLAG.NOTIFIED_REGULAR)
        ? 'regular'
        : slot.flags?.some((flag) => flag.type === SHIFT_SLOT_FLAG.NOTIFIED_ATTENTION)
          ? 'attention'
          : false;

    const isInTheFuture = useMemo(() => isSlotInTheFuture(slot), [slot]);

    const colors =
        slot.status === SHIFT_SLOT_STATUS.DRAFT
            ? {
                  text: palette.error[400] as string,
                  background: palette.grey[50],
              }
            : notifiedStatus === 'regular'
              ? {
                    text: palette.secondary[600] as string,
                    background: palette.secondary[100] as string,
                }
              : {
                    text: palette.error[600] as string,
                    background: palette.error[100] as string,
                };

    const handleClick = () => {
        const tabSection = !isInTheFuture ? 'search' : notifiedStatus ? 'notify' : 'suggested';
        setSelectStaffSlotId(slot.id);
        setSelectStaffTab(tabSection);
    };

    return (
        <NonDraggingSlotItemButton
            color={colors.text}
            bgcolor={colors.background}
            slot={slot}
            text="Open Shift"
            onClick={handleClick}
            sx={{ cursor: 'pointer' }}
        />
    );
};

const MemoizedOpenSlotItemButton = memo(OpenSlotItemButton);

const OpenSlotItemButtonBox = ({ slot, isDraggingOver }: { slot: FullScheduleSlot; isDraggingOver: boolean }) => (
    <Box
        sx={
            isDraggingOver
                ? ({ palette }) => ({
                      outline: `2px dashed ${palette.primary[500]}`,
                      borderRadius: '6px',
                  })
                : undefined
        }
    >
        <MemoizedOpenSlotItemButton slot={slot} />
    </Box>
);

const MemoizedOpenSlotItemButtonBox = memo(OpenSlotItemButtonBox);

const OpenSlotItem = ({ slot }: { slot: FullScheduleSlot }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const { current } = ref;

    const setMoveSlotModalSlotIds = useSetAtom(moveSlotModalSlotIdsAtom);

    const slotItemIsDragging = useAtomValue(slotItemIsDraggingAtom);
    const slotItemDraggingId = useAtomValue(slotItemDraggingIdAtom);
    const resetSlotItemDraggingId = useResetAtom(slotItemDraggingIdAtom);
    const [slotItemDraggingX, slotItemDraggingY] = useAtomValue(slotItemDraggingCoordsAtom) ?? [];

    const [slotItemDraggingOverId, setSlotItemDraggingOverId] = useAtom(slotItemDraggingOverIdAtom);
    const setSlotItemCanDrop = useSetAtom(slotItemCanDropAtom);
    const setSlotItemCannotDropReason = useSetAtom(slotItemCannotDropReasonAtom);

    const isMouseOver = useMemo(() => {
        if (!current || !slotItemDraggingX || !slotItemDraggingY) return false;
        return isInsideRect(slotItemDraggingX, slotItemDraggingY, current.getBoundingClientRect());
    }, [slotItemDraggingX, slotItemDraggingY]);

    const isInTheFuture = useMemo(() => isSlotInTheFuture(slot), [slot]);

    const canDropHere = useMemo(() => isInTheFuture, [isInTheFuture]);

    const isDraggingOver = slotItemIsDragging && isMouseOver;

    useEffect(() => {
        if (isDraggingOver) {
            const isInThePastMessage = !isInTheFuture ? 'This shift is in the past!' : null;
            const cannotDropReason = isInThePastMessage;

            setSlotItemDraggingOverId(slot.id);
            setSlotItemCanDrop(canDropHere);
            setSlotItemCannotDropReason(cannotDropReason);
        } else if (slotItemDraggingOverId === slot.id) {
            setSlotItemDraggingOverId(null);
            setSlotItemCanDrop(false);
            setSlotItemCannotDropReason(null);
        } else if (!slotItemDraggingOverId) setSlotItemCanDrop(false);
    }, [slotItemDraggingOverId, isDraggingOver, isInTheFuture, canDropHere, slot.id]);

    useEffect(() => {
        if (!slotItemIsDragging && slotItemDraggingId && isMouseOver && canDropHere) {
            setMoveSlotModalSlotIds([slotItemDraggingId, slot.id]);

            resetSlotItemDraggingId();
        }
    }, [slotItemIsDragging, slotItemDraggingId, canDropHere, slot.id]);

    return (
        <SlotItemBox ref={ref}>
            <MemoizedOpenSlotItemButtonBox
                slot={slot}
                isDraggingOver={slotItemIsDragging && isMouseOver && isInTheFuture}
            />
        </SlotItemBox>
    );
};

export default OpenSlotItem;
