import { Button } from '@mui/material';
import { useSetAtom } from 'jotai';
import React from 'react';
import { PiCheckBold } from 'react-icons/pi';
import { useBlocker } from 'react-router';

import { isSaveOptimizationModalOpenAtom } from '../atom';
import { thereAreNewChanges } from '../helpers';
import { useBudgetedShifts } from '../useBudgetedShifts';
import { useUpdateBudgets } from '../useUpdateBudgets';

const HeaderActions = () => {
    const toggle = useSetAtom(isSaveOptimizationModalOpenAtom);
    const { budgetedShifts } = useBudgetedShifts();
    const { budgetsToChange, reset: resetBudgets } = useUpdateBudgets();

    const disableButton = React.useMemo(
        () => !thereAreNewChanges(budgetsToChange, budgetedShifts),
        [budgetsToChange, budgetedShifts]
    );

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => !disableButton && currentLocation.pathname !== nextLocation.pathname
    );

    // prevent user from switching pages
    React.useEffect(() => {
        if (blocker.state === 'blocked') {
            if (confirm('Are you sure you want to leave? You will lose any unsaved changes.')) {
                resetBudgets();
                blocker.proceed();
            } else {
                blocker.reset();
            }
        }
    }, [blocker, resetBudgets]);

    // prevent user from closing the tab
    React.useEffect(() => {
        const preventTabToClose = (e: Event) => {
            if (!disableButton) {
                e.preventDefault();
            }
        };

        window.addEventListener('beforeunload', preventTabToClose);

        return () => window.removeEventListener('beforeunload', preventTabToClose);
    }, [disableButton]);

    return (
        <Button size="small" sx={{ gap: '8px' }} disabled={disableButton} onClick={toggle}>
            <PiCheckBold color="white" size={16} /> Save Changes
        </Button>
    );
};

export default HeaderActions;
