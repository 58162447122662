import { Box, Button, Divider, Typography } from '@mui/material';
import { isAfter, parse, subDays } from 'date-fns';
import Pluralize from 'pluralize';
import React, { useState } from 'react';

import { SHIFT_CHECK_IN_POINTS } from '@allie/utils/src/constants/rewards.constants';

import {
    useCheckInMutation,
    useRedeemPointsMutation,
    useRewardsDetailsQuery,
    useRewardsHistoryQuery,
} from '~/api/queries/caregiverRewards';
import rewardsBackgroundLarge from '~/assets/rewards-background-large.svg';
import rewardsBackgroundMedium from '~/assets/rewards-background-medium.svg';
import rewardsBackgroundSmall from '~/assets/rewards-background-small.svg';
import Loading from '~/components/Shared/Loading';
import { GoldCoin } from '~/components/Svg/GoldCoin';
import { pxToRem } from '~/components/theme/typography';

import CheckedInModal from './CheckedInModal';
import PointsRedemptionModal from './PointsRedemptionModal';

const MINIMUM_REDEMPTION_VALUE = 500;

type Props = {
    userId: number;
    branchId: number;
};

const RedeemSection = ({ userId, branchId }: Props) => {
    const [isRedemptionModalOpen, setIsRedemptionModalOpen] = useState(false);
    const [isCheckInModalOpen, setIsCheckInModalOpen] = useState(false);

    const toggleRedemptionModal = () => setIsRedemptionModalOpen((prevState) => !prevState);

    const toggleCheckInModal = () => setIsCheckInModalOpen((prevState) => !prevState);

    const {
        isLoading: rewardsDetailsIsLoading,
        isError: rewardsDetailsIsError,
        data: rewardsDetailsData,
    } = useRewardsDetailsQuery(userId, branchId);

    const {
        isLoading: rewardsHistoryIsLoading,
        isError: rewardsHistoryIsError,
        data: rewardsHistoryData,
    } = useRewardsHistoryQuery(userId, branchId);

    const { mutate: mutateRedeemPoints, isLoading: isRedemptionLoading } = useRedeemPointsMutation(userId, branchId);

    const { mutate: checkInMutation } = useCheckInMutation(userId, branchId);

    const redeemRewards = () => {
        if (!rewardsDetailsData || rewardsDetailsData.userClaimedPoints < MINIMUM_REDEMPTION_VALUE) {
            return;
        }
        mutateRedeemPoints();
        setIsRedemptionModalOpen(true);
    };

    const handleCheckIn = () => {
        checkInMutation();
        toggleCheckInModal();
    };

    if (rewardsDetailsIsLoading || rewardsHistoryIsLoading) {
        return <Loading />;
    }

    if (rewardsDetailsIsError || rewardsHistoryIsError) {
        return (
            <Typography fontSize={{ xs: pxToRem(12), lg: pxToRem(14) }}>
                There was an error when fetching the data, please try again later.
            </Typography>
        );
    }

    if (!rewardsDetailsData) {
        return null;
    }

    const unclaimedPoints =
        rewardsDetailsData.documentationCompliancePoints.unclaimedPoints +
        rewardsDetailsData.residentNotesPoints.unclaimedPoints +
        rewardsDetailsData.unscheduledTasksPoints.unclaimedPoints +
        rewardsDetailsData.previousUnclaimedPoints;

    const percentComplete = Math.floor(1000 * (rewardsDetailsData.userClaimedPoints / MINIMUM_REDEMPTION_VALUE)) / 10; // round to first decimal place
    const percentCompleteToDisplay = percentComplete > 100 ? 100 : percentComplete;

    const lastSevenDays = subDays(new Date(), 7);

    const parseShiftDayDate = (shiftDayDate: string) => parse(shiftDayDate, 'yyyy-MM-dd', new Date());

    const checkInsInLast7Days =
        rewardsHistoryData?.checkInsByShiftDate.filter(
            (item) => item.shiftDayDate && isAfter(parseShiftDayDate(item.shiftDayDate), lastSevenDays)
        ) ?? [];

    const amountOfCheckInsInLastSevenDays = checkInsInLast7Days.reduce((acc, item) => {
        const completeCount = item.checkIns.filter((checkIn) => checkIn === 'COMPLETE').length;
        return acc + completeCount;
    }, 0);

    const hasCheckedIn = rewardsDetailsData.isCheckedIn;

    return (
        <>
            <Box
                sx={{
                    backgroundImage: {
                        xs: `url(${rewardsBackgroundSmall})`,
                        md: `url(${rewardsBackgroundMedium})`,
                        lg: `url(${rewardsBackgroundLarge})`,
                    },
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: '#006B75',
                    borderRadius: pxToRem(8),
                }}
            >
                <Box
                    sx={{
                        padding: pxToRem(24),
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            color: '#DEDEE0',
                            fontSize: pxToRem(14),
                            lineHeight: pxToRem(24),
                        }}
                    >
                        Your points
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#FCD7B1',
                        }}
                    >
                        <GoldCoin size={20} viewBox="0 0 20 20" />
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'baseline',
                                lineHeight: pxToRem(28),
                                ml: pxToRem(4),
                            }}
                        >
                            <Box
                                sx={{
                                    fontSize: pxToRem(24),
                                    fontWeight: 700,
                                    color: '#FCD7B1',
                                    mr: pxToRem(4),
                                }}
                            >
                                {rewardsDetailsData.userClaimedPoints}
                            </Box>
                            <Box sx={{ fontSize: pxToRem(14), color: '#B1B2B7' }}>/{MINIMUM_REDEMPTION_VALUE}</Box>
                        </Box>
                    </Box>
                    {unclaimedPoints > 0 && (
                        <Box
                            sx={{
                                borderLeft: `${pxToRem(2)} solid #91BFC4`,
                                backgroundColor: '#30878F',
                                color: '#fff',
                                fontSize: pxToRem(12),
                                p: `${pxToRem(8)} ${pxToRem(10)}`,
                                mt: pxToRem(8),
                                mb: pxToRem(16),
                                alignSelf: 'flex-start',
                                textAlign: 'left',
                            }}
                        >
                            <Box sx={{ fontWeight: 700, display: 'inline' }}>
                                {Pluralize('point', unclaimedPoints, true)}
                            </Box>
                            {" earned recently, claim it fast below before it's expired!"}
                        </Box>
                    )}
                    {unclaimedPoints === 0 && rewardsDetailsData.userClaimedPoints < MINIMUM_REDEMPTION_VALUE && (
                        <Box
                            sx={{
                                borderLeft: `${pxToRem(2)} solid #91BFC4`,
                                backgroundColor: '#30878F',
                                color: '#fff',
                                fontSize: pxToRem(12),
                                p: `${pxToRem(8)} ${pxToRem(10)}`,
                                mt: pxToRem(8),
                                mb: pxToRem(16),
                                alignSelf: 'flex-start',
                                textAlign: 'left',
                            }}
                        >
                            At <Box sx={{ fontWeight: 700, display: 'inline' }}>500 points</Box> you will be able to
                            redeem your points for a $5 gift card.
                        </Box>
                    )}
                    <Box
                        sx={{
                            mt: pxToRem(16),
                            mb: pxToRem(24),
                            height: pxToRem(4),
                            width: '100%',
                            backgroundColor: '#30878F',
                            borderRadius: pxToRem(4),
                        }}
                    >
                        <Box
                            sx={{
                                width: `${percentCompleteToDisplay.toString()}%`,
                                backgroundColor: '#FCD7B1',
                                height: '100%',
                                borderRadius: pxToRem(4),
                            }}
                        />
                    </Box>
                    <Button
                        onClick={redeemRewards}
                        disabled={rewardsDetailsData.userClaimedPoints < MINIMUM_REDEMPTION_VALUE}
                        sx={{
                            borderRadius: pxToRem(4),
                            p: `${pxToRem(8)} ${pxToRem(16)}`,
                            fontWeight: 700,
                            fontSize: pxToRem(14),
                            color: '#006B75 !important',
                            backgroundColor: '#fff !important',
                            alignSelf: 'flex-start',
                            '&:disabled': {
                                backgroundColor: '#61A3A9 !important',
                            },
                        }}
                    >
                        Redeem rewards
                    </Button>
                </Box>
                <Divider
                    sx={{
                        backgroundColor: '#30878F',
                    }}
                />
                <Box
                    sx={{
                        p: {
                            xs: `${pxToRem(16)} ${pxToRem(24)}`,
                            lg: pxToRem(24),
                        },
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                color: '#C2DBDE',
                                fontSize: {
                                    xs: pxToRem(12),
                                    lg: pxToRem(14),
                                },
                                lineHeight: 1.3,
                            }}
                        >
                            Check-ins (Last 7 Days):
                        </Typography>
                        <Typography
                            sx={{
                                color: 'common.white',
                                fontWeight: 700,
                                fontSize: {
                                    xs: pxToRem(16),
                                    lg: pxToRem(18),
                                },
                                lineHeight: 1.5,
                            }}
                        >
                            {amountOfCheckInsInLastSevenDays}
                        </Typography>
                    </Box>
                    <Button
                        onClick={handleCheckIn}
                        disabled={hasCheckedIn}
                        sx={{
                            borderRadius: pxToRem(4),
                            fontWeight: 700,
                            fontSize: pxToRem(14),
                            backgroundColor: '#FFF !important',
                            color: '#006B75 !important',
                            p: `${pxToRem(8)} ${pxToRem(16)}`,
                            '&:disabled': {
                                backgroundColor: '#61A3A9 !important',
                            },
                        }}
                    >
                        Check In
                    </Button>
                </Box>
            </Box>
            <PointsRedemptionModal
                isOpen={isRedemptionModalOpen}
                onClose={toggleRedemptionModal}
                isLoading={isRedemptionLoading}
            />
            <CheckedInModal isOpen={isCheckInModalOpen} onClose={toggleCheckInModal} points={SHIFT_CHECK_IN_POINTS} />
        </>
    );
};

export default RedeemSection;
