import { ThemeProvider } from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router';

import WithNavigation from '~/components/Layout/WithNavigation';
import WithSafePadding from '~/components/Layout/WithSafePadding';
import { queryClient } from '~/scheduling/api/common';

import AccessControl from '../../router/components/AccessControl';
import CustomAlert from '../components/CustomAlert';
import Dashboard from '../pages/Dashboard';
import Reports from '../pages/Reports';
import ManagerSchedule from '../pages/Schedule/Manager';
import StaffSchedule from '../pages/Schedule/Staff';
import Notifications from '../pages/Schedule/Staff/Notifications';
import ShiftDetails from '../pages/Schedule/Staff/ShiftDetails';
import ShiftApproval from '../pages/ShiftApproval';
import StaffList from '../pages/StaffList';
import StaffDetails from '../pages/StaffList/StaffDetails';
import StaffOptimizations from '../pages/StaffOptimizations';
import theme from '../theme';

const router: RouteObject = {
    element: (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <QueryClientProvider client={queryClient}>
                <AccessControl featureFlag="scheduling">
                    <CustomAlert />

                    {/* Providing a new theme resets any theming that came before */}
                    <ThemeProvider theme={theme}>
                        <WithSafePadding>
                            <WithNavigation>
                                <Outlet />
                            </WithNavigation>
                        </WithSafePadding>
                    </ThemeProvider>
                </AccessControl>
            </QueryClientProvider>
        </LocalizationProvider>
    ),
    children: [
        // Manager routes
        {
            index: true,
            element: <Navigate to="dashboard" replace />,
        },
        {
            path: 'dashboard',
            handle: {
                title: 'Dashboard',
            },
            element: (
                <AccessControl permission={['Community', 'scheduling-read-dashboard']}>
                    <Dashboard />
                </AccessControl>
            ),
        },
        {
            path: 'full-schedule',
            handle: {
                title: 'Schedule',
            },
            element: (
                <AccessControl permission={['Community', 'scheduling-read-full-schedule']}>
                    <ManagerSchedule />
                </AccessControl>
            ),
        },
        {
            path: 'shift-approval',
            handle: {
                title: 'Shift Approval',
            },
            element: (
                <AccessControl permission={['Community', 'scheduling-read-full-schedule']}>
                    <ShiftApproval />
                </AccessControl>
            ),
        },
        {
            path: 'staff-list',
            element: (
                <AccessControl permission={['Community', 'scheduling-read-staff']}>
                    <Outlet />
                </AccessControl>
            ),
            children: [
                {
                    index: true,
                    handle: {
                        title: 'Staff',
                    },
                    element: <StaffList />,
                },
                {
                    path: 'staff-details',
                    children: [
                        {
                            index: true,
                            handle: {
                                title: 'Add Staff',
                            },
                            element: <StaffDetails />,
                        },
                        {
                            path: ':id',
                            handle: {
                                title: 'Edit Staff',
                            },
                            element: <StaffDetails />,
                        },
                    ],
                },
            ],
        },
        {
            path: 'staff-optimizations',
            handle: {
                title: 'Staff Optimizations',
            },
            element: (
                <AccessControl permission={['Community', 'scheduling-read-master-schedule']}>
                    <StaffOptimizations />
                </AccessControl>
            ),
        },
        {
            path: 'reports',
            handle: {
                title: 'Reports',
            },
            element: (
                <AccessControl permission={['Community', 'scheduling-read-reports']}>
                    <Reports />
                </AccessControl>
            ),
        },

        // Staff routes
        {
            path: 'my-schedule',
            element: (
                <AccessControl permission={['Community', 'scheduling-read-my-schedule']}>
                    <Outlet />
                </AccessControl>
            ),
            children: [
                {
                    index: true,
                    handle: {
                        title: 'Schedule',
                    },
                    element: <StaffSchedule />,
                },
                {
                    path: ':id',
                    handle: {
                        title: 'Shift Details',
                        backOne: true,
                    },
                    element: <ShiftDetails />,
                },
                {
                    path: 'notifications',
                    handle: {
                        title: 'Notifications',
                        backOne: true,
                    },
                    element: <Notifications />,
                },
            ],
        },
    ],
};

export default router;
