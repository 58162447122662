import { Button, CircularProgress, Stack, TextField, Typography, useTheme } from '@mui/material';
import { ArrowLeft } from '@phosphor-icons/react';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';

import { useMarkSlotOpen } from '~/scheduling/api/queries/shift-slot/markSlotOpen';
import SegmentedBottomSheet from '~/scheduling/components/SegmentedBottomSheet';

import { selectStaffSlotIdAtom } from '../../../../shared/SelectStaff/atoms';

import {
    isNotesSheetOpenAtom,
    notesSheetNotesAtom,
    notesSheetSelectedReasonAtom,
    notesSheetSlotIdAtom,
    reasonSheetSlotIdAtom,
} from './atoms';

const loadingButtonAtom = atom<'open' | 'select' | null>(null);

const NotesBottomSheetHeader = () => {
    const { palette } = useTheme();

    const slotId = useAtomValue(notesSheetSlotIdAtom);
    const toggle = useSetAtom(isNotesSheetOpenAtom);
    const isLoading = !!useAtomValue(loadingButtonAtom);

    const setReasonSheetSlotId = useSetAtom(reasonSheetSlotIdAtom);

    if (!slotId) return null;

    return (
        <Stack direction="row" alignItems="center" ml="-8px" spacing="4px">
            <Button
                variant="text"
                onClick={() => {
                    setReasonSheetSlotId(slotId);
                    toggle();
                }}
                sx={{
                    color: palette.grey[600],
                    minWidth: 0,
                    width: '36px',
                    height: '36px',
                    p: '8px',
                    borderRadius: '50%',
                }}
                disabled={isLoading}
            >
                <ArrowLeft size={20} />
            </Button>
            <Typography fontSize="16px" fontWeight={700} lineHeight="36px">
                Enter Details
            </Typography>
        </Stack>
    );
};

const NotesBottomSheetActions = () => {
    const [loadingButton, setLoadingButton] = useAtom(loadingButtonAtom);

    const slotId = useAtomValue(notesSheetSlotIdAtom);
    const selectedReason = useAtomValue(notesSheetSelectedReasonAtom);
    const notes = useAtomValue(notesSheetNotesAtom);
    const toggle = useSetAtom(isNotesSheetOpenAtom);

    const setSelectStaffSlotId = useSetAtom(selectStaffSlotIdAtom);

    const { mutateAsync: markSlotOpen, isPending } = useMarkSlotOpen();

    if (!slotId) return null;

    const handleMarkOpen = async () => {
        if (!selectedReason || !notes) return;

        await markSlotOpen({ slotId, data: { reason: selectedReason.value, notes: notes.trim() } });
        toggle();
    };

    const onMarkOpen = async () => {
        setLoadingButton('open');

        try {
            await handleMarkOpen();
        } finally {
            setLoadingButton(null);
        }
    };

    const onSelectStaff = async () => {
        setLoadingButton('select');

        try {
            await handleMarkOpen();
        } finally {
            setLoadingButton(null);
        }

        setSelectStaffSlotId(slotId);
    };

    return (
        <>
            <Button variant="outlined" color="error" onClick={onMarkOpen} disabled={!notes || isPending}>
                {loadingButton === 'open' ? (
                    <CircularProgress size={20} thickness={4} sx={{ color: 'error.100' }} />
                ) : (
                    'Mark as Open Shift'
                )}
            </Button>
            <Button onClick={onSelectStaff} disabled={!notes || isPending}>
                {loadingButton === 'select' ? (
                    <CircularProgress size={20} thickness={4} sx={{ color: 'white' }} />
                ) : (
                    'Select Staff'
                )}
            </Button>
        </>
    );
};

const NotesReasonTextField = () => {
    const [notes, setNotes] = useAtom(notesSheetNotesAtom);

    return (
        <Stack spacing="4px">
            <Typography fontWeight={700}>Detailed Reason</Typography>
            <TextField
                variant="outlined"
                placeholder="Input detailed reason"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                multiline
                minRows={3}
                sx={{ flex: 1 }}
            />
        </Stack>
    );
};

const NotesBottomSheet = () => {
    const [isOpen, toggle] = useAtom(isNotesSheetOpenAtom);
    const isLoading = !!useAtomValue(loadingButtonAtom);

    return (
        <SegmentedBottomSheet
            isOpen={isOpen}
            onClose={toggle}
            header={<NotesBottomSheetHeader />}
            actions={<NotesBottomSheetActions />}
            closeButton
            closeButtonProps={{ disabled: isLoading }}
        >
            <NotesReasonTextField />
        </SegmentedBottomSheet>
    );
};

export default NotesBottomSheet;
