import { Box, Button, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { Trash } from '@phosphor-icons/react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import { useNavigate } from 'react-router';

import { useDeleteStaff } from '~/scheduling/api/queries/staff/deleteStaff';
import { useGetStaffList } from '~/scheduling/api/queries/staff/getStaffList';
import CustomModal from '~/scheduling/components/CustomModal';
import { deleteStaffModalIdAtom, isDeleteStaffModalOpenAtom } from '~/scheduling/pages/StaffList/StaffDetails/atom';

const DeleteStaffHeader = () => (
    <Stack alignItems="center" spacing="24px">
        <DeleteStaffIcon />
        <DeleteStaffDetails />
    </Stack>
);

const DeleteStaffIcon = () => {
    const { palette } = useTheme();

    return (
        <Box
            sx={{
                bgcolor: palette.grey[50],
                height: '88px',
                p: '32px',
                borderRadius: '50%',
            }}
        >
            <Trash color={palette.grey[600]} weight="fill" fontSize="24px" />
        </Box>
    );
};

const DeleteStaffDetails = () => {
    const { palette } = useTheme();
    const staffId = useAtomValue(deleteStaffModalIdAtom);

    const { data: staffListData } = useGetStaffList();
    const staffById = staffListData?.staffById;

    if (!staffId) return null;

    const staffName = staffId && staffById?.get(staffId)?.name;

    return (
        <Stack alignItems="center" spacing="12px">
            <Typography variant="h5" sx={{ textAlign: 'center', textWrap: 'balance' }}>
                Are you sure you want to remove <span style={{ color: palette.error[600] as string }}>{staffName}</span>
                ?
            </Typography>
        </Stack>
    );
};

const DeleteStaffActions = () => {
    const navigate = useNavigate();

    const onClose = useSetAtom(isDeleteStaffModalOpenAtom);
    const { mutateAsync: deleteStaff, isPending: isDeleteStaffPending } = useDeleteStaff();

    const staffId = useAtomValue(deleteStaffModalIdAtom);

    const { data: staffListData } = useGetStaffList();
    const staffById = staffListData?.staffById;

    if (!staffId) return null;

    const staffName = staffId && staffById?.get(staffId)?.name;

    const isPending = isDeleteStaffPending;

    return (
        <Stack spacing="8px">
            <Button
                variant="outlined"
                color="error"
                size="large"
                onClick={async () => {
                    try {
                        await deleteStaff({ staffId });
                    } finally {
                        navigate('/scheduling/staff-list');
                    }

                    onClose();
                }}
                disabled={isPending}
            >
                {isPending ? (
                    <CircularProgress size={24} thickness={4} sx={{ color: 'error.100' }} />
                ) : (
                    `Remove ${staffName}`
                )}
            </Button>
            <Button variant="outlined" size="large" onClick={onClose} disabled={isPending}>
                Cancel
            </Button>
        </Stack>
    );
};

const DeleteStaffModal = () => {
    const [isOpen, onClose] = useAtom(isDeleteStaffModalOpenAtom);

    return (
        <CustomModal isOpen={isOpen} onClose={onClose}>
            <Stack p="32px" spacing="32px">
                <DeleteStaffHeader />
                <DeleteStaffActions />
            </Stack>
        </CustomModal>
    );
};

export default DeleteStaffModal;
