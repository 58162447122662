import {
    Calendar,
    ChartBar,
    ChartPieSlice,
    Coin,
    Export,
    FileText,
    House,
    Icon,
    IconWeight,
    ListChecks,
    Sparkle,
    User,
    UserCirclePlus,
    UserGear,
    UsersThree,
} from '@phosphor-icons/react';

import { ResourceActionTuple } from '~/permissions/constants';

/** @deprecated For removal. */ export const DESKTOP_DRAWER_WIDTH = 240;

export type Platform = 'desktop' | 'mobile';

export type Destination = {
    title: string;
    startIcon: Icon;
    startIconWeightActive?: IconWeight;
    endIcon?: Icon;
};

export type InnerDestination = Destination & {
    shortTitle?: string;
    path: string;
    permission?: ResourceActionTuple;
    featureFlag?: string; // Open by default if PostHog is not set up (e.g. in dev)
} & {
    [key in Platform]?: true;
};

export type TopLevelDestination = Destination & {
    innerDestinations: InnerDestination[];
};

export const TOP_LEVEL_DESTINATIONS: TopLevelDestination[] = [
    {
        title: 'Care Revenue',
        startIcon: ChartBar,
        innerDestinations: [
            {
                title: 'Home',
                path: '/home',
                startIcon: House,
                permission: { resource: 'Community', action: 'undertake-resident-action' },
                desktop: true,
                mobile: true,
            },
            {
                title: 'Residents',
                path: '/residents',
                startIcon: UsersThree,
                startIconWeightActive: 'bold',
                permission: { resource: 'Community', action: 'undertake-resident-action' },
                desktop: true,
                mobile: true,
            },
            {
                title: 'My Rewards',
                shortTitle: 'Rewards',
                path: '/rewards',
                startIcon: Coin,
                permission: { resource: 'Community', action: 'update-reward' },
                desktop: true,
                mobile: true,
            },
            {
                title: 'Operations & Risks',
                shortTitle: 'Ops & Risks',
                startIcon: ChartPieSlice,
                path: '/operations',
                permission: { resource: 'Community', action: 'read-operations-dashboard' },
                desktop: true,
                mobile: true,
            },
            {
                title: 'Care Documentation',
                shortTitle: 'Care',
                startIcon: ListChecks,
                startIconWeightActive: 'bold',
                path: '/care-documentation',
                permission: { resource: 'Community', action: 'read-all-resident-actions' },
                desktop: true,
                mobile: true,
            },
            {
                title: 'Resident Deep Dive',
                shortTitle: 'Deep Dive',
                startIcon: UsersThree,
                startIconWeightActive: 'bold',
                path: '/residents',
                permission: { resource: 'Community', action: 'read-resident-deep-dive' },
                desktop: true,
                mobile: true,
            },
            {
                title: 'Export Data',
                startIcon: Export,
                path: '/export-data',
                permission: { resource: 'Community', action: 'export-data' },
                desktop: true,
            },
        ],
    },
    {
        title: 'Scheduling',
        startIcon: Calendar,
        innerDestinations: [
            {
                title: 'Dashboard',
                startIcon: House,
                path: '/scheduling/dashboard',
                permission: { resource: 'Community', action: 'scheduling-read-dashboard' },
                featureFlag: 'scheduling',
                desktop: true,
            },
            {
                title: 'Schedule',
                startIcon: Calendar,
                path: '/scheduling/full-schedule',
                permission: { resource: 'Community', action: 'scheduling-read-full-schedule' },
                featureFlag: 'scheduling',
                desktop: true,
                mobile: true,
            },
            {
                title: 'Shift Approval',
                startIcon: UserCirclePlus,
                path: '/scheduling/shift-approval',
                permission: { resource: 'Community', action: 'scheduling-read-full-schedule' },
                featureFlag: 'scheduling',
                mobile: true,
            },
            {
                title: 'Staff',
                startIcon: User,
                startIconWeightActive: 'bold',
                path: '/scheduling/staff-list',
                permission: { resource: 'Community', action: 'scheduling-read-staff' },
                featureFlag: 'scheduling',
                desktop: true,
            },
            {
                title: 'Staff Optimizations',
                startIcon: UserGear,
                startIconWeightActive: 'bold',
                endIcon: Sparkle,
                path: '/scheduling/staff-optimizations',
                permission: { resource: 'Community', action: 'scheduling-read-master-schedule' },
                featureFlag: 'scheduling',
                desktop: true,
            },
            {
                title: 'Reports',
                startIcon: FileText,
                path: '/scheduling/reports',
                permission: { resource: 'Community', action: 'scheduling-read-reports' },
                featureFlag: 'scheduling',
                desktop: true,
            },
            {
                title: 'Schedule',
                path: '/scheduling/my-schedule',
                startIcon: Calendar,
                permission: { resource: 'Community', action: 'scheduling-read-my-schedule' },
                featureFlag: 'scheduling',
                desktop: true,
                mobile: true,
            },
        ],
    },
];
