import { Search as SearchIcon } from '@mui/icons-material';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import langDictionary from '~/app-strings';
import { CustomTextField } from '~/components/Custom';
import { INPUT_STYLES, MOBILE_INPUT_STYLES } from '~/components/Custom/constants';
import { BranchSelector, ZoneSelector } from '~/components/Filtering';
import SortButton from '~/components/Shared/Sorting/SortButton';
import SortDialog from '~/components/Shared/Sorting/SortDialog';
import { pxToRem } from '~/components/theme/typography';
import { setSortBy, setSortOrder } from '~/redux/actions/filters';
import { BranchesByRegionResponseBranch } from '~/types/companyInfo';
import { ReduxStore } from '~/types/redux';

const FilterFixedBar = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        width: '100%',
        backgroundColor: { xs: '#006B75', md: 'common.white' },
        p: '8px 16px',
    })
);

const FilterRow = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        width: '100%',
        height: pxToRem(48),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: pxToRem(8),
    })
);

type Props = {
    sortBy: string;
    sortOrder: string;
    renderBranchSelector: boolean;
    branchesWithAccess: BranchesByRegionResponseBranch[];
    branchId: number;
    onBranchChange: (newBranchId: number) => void;
    onSearch: (newSearchValue: string) => void;
    dispatchSetSortBy: (sortBy: string) => void;
    dispatchSetSortOrder: (sortOrder: string) => void;
};

const FilterContainer = (props: Props) => {
    const {
        sortBy,
        sortOrder,
        renderBranchSelector,
        branchesWithAccess,
        branchId,
        onBranchChange,
        onSearch,
        dispatchSetSortBy,
        dispatchSetSortOrder,
    } = props;
    const { residents: residentsDictionary } = langDictionary;
    const [searchValue, setSearchValue] = useState<string>();
    const [isSortDialogOpen, setIsSortDialogOpen] = useState<boolean>(false);

    const handleSearchChange = (newSearchValue: string) => {
        setSearchValue(newSearchValue);
        onSearch(newSearchValue);
    };

    const handleSortButtonClick = () => {
        setIsSortDialogOpen(true);
    };

    const handleSortDialogClose = () => {
        setIsSortDialogOpen(false);
    };

    const handleSortOptions = (selectedSortBy: string, selectedSortOrder: string) => {
        if (sortBy !== selectedSortBy) {
            dispatchSetSortBy(selectedSortBy);
        }
        if (sortOrder !== selectedSortOrder) {
            dispatchSetSortOrder(selectedSortOrder);
        }
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <FilterFixedBar>
                {renderBranchSelector && (
                    <BranchSelector
                        value={branchId}
                        options={branchesWithAccess.map((branch) => ({
                            label: branch.branchName,
                            value: branch.branchId.toString(),
                        }))}
                        onChange={onBranchChange}
                        isHeader
                        fullWidth
                    />
                )}
                <CustomTextField
                    label={residentsDictionary.searchLabel}
                    placeholder={residentsDictionary.searchPlaceholder}
                    value={searchValue}
                    fullWidth
                    startAdornment={<SearchIcon htmlColor="#DEDEE0" />}
                    onChange={handleSearchChange}
                    sx={isMobile ? MOBILE_INPUT_STYLES : INPUT_STYLES}
                />
                <FilterRow>
                    <ZoneSelector branchId={branchId} width="100%" fullWidth />
                    <Box sx={{ width: '40%', mb: pxToRem(8) }}>
                        <SortButton onClick={handleSortButtonClick} />
                    </Box>
                </FilterRow>
            </FilterFixedBar>
            <SortDialog
                isOpen={isSortDialogOpen}
                onClose={handleSortDialogClose}
                onSort={handleSortOptions}
                sortBy={sortBy}
                sortOrder={sortOrder}
            />
        </>
    );
};

const mapStateToProps = ({ filters }: ReduxStore) => {
    const {
        caregiverApp: { sortBy, sortOrder },
    } = filters;

    return {
        sortBy,
        sortOrder,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchSetSortBy: (sortBy: string) => dispatch(setSortBy(sortBy)),
    dispatchSetSortOrder: (sortOrder: string) => dispatch(setSortOrder(sortOrder)),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedFilterContainer: any = connect(mapStateToProps, mapDispatchToProps)(FilterContainer);

export default ConnectedFilterContainer;
