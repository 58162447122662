import { Box, Typography, styled } from '@mui/material';
import React, { useEffect, useRef } from 'react';

interface DayColumnProps {
    onClick?: () => void;
    selected?: boolean;
    insidePeriod?: boolean;
    disabled?: boolean;
    isStart?: boolean;
    isEnd?: boolean;
    children?: React.ReactNode;
}

const DayColumn = styled(Typography)<DayColumnProps>(({ theme, disabled, selected, insidePeriod }) => ({
    height: '100%',
    padding: '8px',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 400,
    cursor: disabled ? 'default' : 'pointer',
    borderRadius: selected ? '100px' : '0',
    backgroundColor: selected
        ? theme.palette.secondary.main
        : insidePeriod
          ? theme.palette.secondary.light
          : 'transparent',
    color: selected ? '#FFF' : disabled ? theme.palette.grey[200] : theme.palette.grey[600],
}));

const DayColumnContainer = styled(Box)<{ selected?: boolean; isStart?: boolean; isEnd?: boolean }>(
    ({ theme, selected, isStart, isEnd }) => ({
        flex: 1,
        aspectRatio: 1,
        background:
            // Gradient added only when a range of dates are selected.
            // It adds a continuous background to the selected range.
            // If it's the start date, the gradient will be add to the middle of the component
            // until the end of it, to simulate the continuty of the range. If it is the end date,
            // the gradient will be added to the start of the component until the middle of it,
            selected && (isStart || isEnd)
                ? `linear-gradient(270deg, ${isStart ? `${theme.palette.secondary.light} 49.99%, rgba(255, 255, 255, 0) 50%` : `rgba(255, 255, 255, 0) 50%, ${theme.palette.secondary.light} 49.99%`})`
                : undefined,
    })
);

export const CalendarDayColumn = ({ onClick, ...props }: DayColumnProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [cellHeight, setCellHeight] = React.useState<number>(0);

    useEffect(() => {
        const newCellHeight = (containerRef.current?.clientHeight || 0) - 16;
        if (containerRef.current && newCellHeight !== cellHeight) {
            setCellHeight(newCellHeight);
        }
    });

    return (
        <DayColumnContainer
            ref={containerRef}
            selected={props.selected}
            isStart={props.isStart}
            isEnd={props.isEnd}
            onClick={!props.disabled ? onClick : undefined}
        >
            <DayColumn variant="body1" {...props} lineHeight={cellHeight + 'px'} />
        </DayColumnContainer>
    );
};
