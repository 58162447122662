import { isDrawerOpenAtom, selectedTopLevelIndexAtom } from '.';
import { Box, Stack, Tooltip, Zoom, useTheme } from '@mui/material';
import { CaretUp } from '@phosphor-icons/react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import { SingleLineTypography } from '~/components/Shared/SingleLineTypography';
import { useSoftMatch } from '~/router/helpers';

import { InnerDestination, TopLevelDestination } from '../shared';
import { useFilteredDestinations } from '../useFilteredDestinations';

const DesktopDrawerTopLevelDestination = ({
    destination,
    isSelected: _isSelected,
    onSelect,
}: {
    destination: TopLevelDestination | InnerDestination;
    isSelected: boolean;
    onSelect: () => void;
}) => {
    const { palette } = useTheme();

    const navigate = useNavigate();
    const isSoftMatch = !!useSoftMatch('path' in destination ? destination.path : '');

    const isDrawerOpen = useAtomValue(isDrawerOpenAtom);

    const { title, startIcon: StartIcon } = destination;
    const isTopLevelDestination = 'innerDestinations' in destination;
    const innerDestinations = isTopLevelDestination ? destination.innerDestinations : null;

    const isSelected = isTopLevelDestination ? _isSelected : isSoftMatch;
    const isOpen = isDrawerOpen && isSelected;

    const [isTooltipOpen, setTooltipOpen] = useState(false);
    const onTooltipOpen = () => !isDrawerOpen && setTooltipOpen(true);
    const onTooltipClose = () => setTooltipOpen(false);

    return (
        <Stack sx={{ '& .MuiTooltip-tooltip': { p: 0 } }}>
            <Tooltip
                open={isTooltipOpen}
                onOpen={onTooltipOpen}
                onClose={onTooltipClose}
                title={<TooltipTopLevelDestination destination={destination} />}
                TransitionComponent={Zoom}
                TransitionProps={{
                    timeout: 200,
                    style: { transformOrigin: isTopLevelDestination ? '0 24px' : '0 18px' },
                }}
                placement={isTopLevelDestination ? 'right-start' : 'right'}
                arrow
            >
                <Stack
                    onClick={() => {
                        if (isTopLevelDestination) isDrawerOpen && onSelect();
                        else navigate(destination.path);
                    }}
                    sx={{
                        bgcolor: isSelected ? (palette.primary[400] as string) : 'transparent',
                        color: isSelected ? 'white' : (palette.primary[25] as string),
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '8px',
                        p: '12px 16px 12px 12px',
                        borderRadius: '8px',
                        userSelect: 'none',
                        cursor: 'pointer',
                        transition: 'background-color 0.2s',
                        '&:hover': { bgcolor: palette.primary[600] as string },
                        '& > svg': { fontSize: '24px !important', flexShrink: 0 },
                    }}
                >
                    <StartIcon weight="regular" />
                    <SingleLineTypography
                        sx={{
                            fontWeight: isSelected ? 600 : 400,
                            mr: 'auto',
                            opacity: isDrawerOpen ? 1 : 0,
                            transition: 'font-weight 0.2s, opacity 0.2s',
                        }}
                    >
                        {title}
                    </SingleLineTypography>
                    {isTopLevelDestination && (
                        <Box
                            sx={{
                                display: isDrawerOpen ? 'block' : 'none',
                                width: '16px',
                                height: '16px',
                                transform: isOpen ? 'rotate(0)' : 'rotate(180deg)',
                                transition: 'transform 0.4s',
                                '& > svg': { fontSize: '16px !important', flexShrink: 0 },
                            }}
                        >
                            <CaretUp weight="regular" />
                        </Box>
                    )}
                </Stack>
            </Tooltip>
            {isTopLevelDestination && (
                <Stack
                    sx={{
                        height: isOpen ? `${innerDestinations!.length * 40}px` : 0,
                        mt: isOpen ? '8px' : 0,
                        transform: isOpen ? 'scaleY(1)' : 'scaleY(0)',
                        transformOrigin: 'top',
                        overflow: 'hidden',
                        transition: 'height 0.3s, transform 0.2s',
                    }}
                >
                    {innerDestinations!.map((innerDestination, index) => (
                        <DesktopDrawerInnerDestination key={index} destination={innerDestination} />
                    ))}
                </Stack>
            )}
        </Stack>
    );
};

const DesktopDrawerInnerDestination = ({ destination }: { destination: InnerDestination }) => {
    const { palette } = useTheme();

    const navigate = useNavigate();

    const { title, endIcon: EndIcon, path } = destination;
    const isSelected = !!useSoftMatch(path);

    return (
        <Stack
            onClick={() => navigate(path)}
            sx={{
                color: isSelected ? 'white' : (palette.primary[100] as string),
                flexDirection: 'row',
                alignItems: 'center',
                height: '40px',
                userSelect: 'none',
                cursor: 'pointer',
                '&:hover': !isSelected
                    ? {
                          color: palette.primary[50] as string,
                          '& .MuiBox-root:first-of-type': { bgcolor: palette.primary[100] as string },
                      }
                    : {},
                '& > svg': { fontSize: '12px !important', flexShrink: 0, ml: '4px' },
            }}
        >
            <Box
                sx={{
                    bgcolor: isSelected ? 'white' : (palette.primary[300] as string),
                    m: '0 20px 0 24px',
                    width: '2px',
                    height: '100%',
                }}
            />
            <SingleLineTypography fontWeight={400}>{title}</SingleLineTypography>
            {EndIcon && <EndIcon weight="fill" />}
        </Stack>
    );
};

const TooltipTopLevelDestination = ({ destination }: { destination: TopLevelDestination | InnerDestination }) => {
    const { palette } = useTheme();

    const isTopLevelDestination = 'innerDestinations' in destination;
    const { title, endIcon: EndIcon } = destination;

    if (isTopLevelDestination) {
        return (
            <Stack
                sx={{
                    p: '4px 0',
                    userSelect: 'none',
                    '& .MuiStack-root:last-of-type': { borderRadius: '0 0 4px 4px' },
                }}
            >
                <SingleLineTypography
                    sx={{
                        color: palette.grey[400],
                        p: '4px 10px 6px',
                        fontSize: '12px',
                        fontWeight: 600,
                        lineHeight: '16px',
                        textTransform: 'uppercase',
                    }}
                >
                    {title}
                </SingleLineTypography>
                {destination.innerDestinations.map((innerDestination, index) => (
                    <TooltipInnerDestination key={index} destination={innerDestination} />
                ))}
            </Stack>
        );
    }

    return (
        <Stack
            sx={{
                flexDirection: 'row',
                gap: '6px',
                p: '10px',
                userSelect: 'none',
            }}
        >
            <SingleLineTypography
                sx={{
                    color: palette.grey[900],
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '18px',
                }}
            >
                {title}
            </SingleLineTypography>
            {EndIcon && <EndIcon color={palette.primary[500] as string} weight="fill" />}
        </Stack>
    );
};

const TooltipInnerDestination = ({
    destination: { title, startIcon: StartIcon, startIconWeightActive, endIcon: EndIcon, path },
}: {
    destination: InnerDestination;
}) => {
    const { palette } = useTheme();

    const setSelectedTopLevelIndex = useSetAtom(selectedTopLevelIndexAtom);

    const navigate = useNavigate();
    const isSelected = !!useSoftMatch(path);

    return (
        <Stack
            onClick={() => {
                navigate(path);
                setSelectedTopLevelIndex(undefined);
            }}
            sx={{
                color: isSelected ? (palette.primary[500] as string) : palette.grey[600],
                bgcolor: isSelected ? (palette.primary[50] as string) : 'transparent',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '6px',
                p: '10px',
                userSelect: 'none',
                cursor: 'pointer',
                '&:hover': !isSelected ? { bgcolor: palette.grey[25] } : {},
                '& > svg': { fontSize: '18px !important', flexShrink: 0 },
                '& > svg:last-of-type': EndIcon ? { fontSize: '14px !important' } : {},
            }}
        >
            <StartIcon weight={isSelected ? (startIconWeightActive ?? 'fill') : 'regular'} />
            <SingleLineTypography
                sx={{
                    color: isSelected ? (palette.primary[600] as string) : palette.grey[900],
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '18px',
                }}
            >
                {title}
            </SingleLineTypography>
            {EndIcon && <EndIcon color={palette.primary[500] as string} weight="fill" />}
        </Stack>
    );
};

const DesktopDrawerDestinations = () => {
    const isSchedulingMatch = !!useSoftMatch('/scheduling');
    const [selectedTopLevelIndex, setSelectedTopLevelIndex] = useAtom(selectedTopLevelIndexAtom);

    useEffect(() => {
        if (selectedTopLevelIndex === undefined) setSelectedTopLevelIndex(isSchedulingMatch ? 1 : 0);
    }, [selectedTopLevelIndex, isSchedulingMatch]);

    const filteredDestinations = useFilteredDestinations({ platform: 'desktop' });
    const singleProduct = filteredDestinations.length === 1 ? filteredDestinations[0] : null;

    const destinations = useMemo(
        () => singleProduct?.innerDestinations ?? filteredDestinations,
        [singleProduct, filteredDestinations]
    );

    return (
        <Stack sx={{ flex: 1, p: '8px 16px', gap: '8px' }}>
            {destinations.map((destination: TopLevelDestination | InnerDestination, index: number) => (
                <DesktopDrawerTopLevelDestination
                    key={index}
                    destination={destination}
                    isSelected={index === selectedTopLevelIndex}
                    onSelect={() => setSelectedTopLevelIndex(index)}
                />
            ))}
        </Stack>
    );
};

export default DesktopDrawerDestinations;
