import { Skeleton } from '@mui/material';
import React from 'react';

import { SHIFT_SLOT_STATUS } from '@allie/utils/src/constants/scheduling/shift-slot.constants';

import { useGetAgencyStaffList } from '~/scheduling/api/queries/agency-staff/getAgencyStaffList';
import { useGetLocations } from '~/scheduling/api/queries/locations/getLocations';
import { FullScheduleSlot } from '~/scheduling/api/queries/shift-slot/getFullSchedule';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import { useGetStaff } from '~/scheduling/api/queries/staff/getStaff';

import FilledSlot from './FilledSlot';
import OpenSlot from './OpenSlot';

interface SlotItemProps {
    slot: FullScheduleSlot;
}

export const SlotItem = (props: SlotItemProps) => {
    const { data: roleData, isLoading: isRolesLoading } = useGetRoles();
    const roleById = roleData?.roleById;
    const roleShiftById = roleData?.roleShiftById;

    const { data: locationData, isLoading: isLocationsLoading } = useGetLocations();
    const locationById = locationData?.locationById;

    const { id, roleId, roleShiftId, startTime, locationId, flags, staffId, agencyStaffId, status } = props.slot;
    const role = roleById?.get(roleId)?.name;
    const roleShift = roleShiftById?.get(roleShiftId)?.name;
    const location = locationById?.get(locationId)?.abbreviation;
    const flagsTypes = flags.map(({ type }) => type);

    const { data: staff, isLoading: isStaffLoading } = useGetStaff(staffId ?? undefined);
    const { data: agencyStaff, isLoading: isAgencyStaffLoading } = useGetAgencyStaffList();

    if (isRolesLoading || isLocationsLoading || isStaffLoading || isAgencyStaffLoading) {
        return <Skeleton height="72px" sx={{ marginTop: '8px' }} width="100%" />;
    }

    if (!role || !roleShift || !location || (staffId && !staff) || (agencyStaffId && !agencyStaff)) {
        return null;
    }

    if (!props.slot.agencyStaffId && !props.slot.staffId) {
        return (
            <OpenSlot
                id={id}
                location={location}
                role={role}
                flags={flagsTypes}
                startTime={startTime}
                isPublished={status === SHIFT_SLOT_STATUS.PUBLISHED}
            />
        );
    }

    const staffName = staff?.name ?? agencyStaff?.agencyStaffById.get(agencyStaffId!)?.name ?? '';

    return (
        <FilledSlot
            id={id}
            location={location}
            role={role}
            staffName={staffName}
            flags={flagsTypes}
            startTime={startTime}
            isPublished={status === SHIFT_SLOT_STATUS.PUBLISHED}
        />
    );
};
