import { Box, styled } from '@mui/material';

const WithSafePadding = styled(Box)({
    width: '100%',
    height: '100%',
    // Avoid status/navigation bar cutouts
    paddingTop: 'env(safe-area-inset-top)',
    paddingBottom: 'env(safe-area-inset-bottom)',
});

export default WithSafePadding;
