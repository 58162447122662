import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ReactNode } from 'react';

const TabPanelContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        width: '100%',
    })
);

const TabPanelContent = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        p: '0 10px',
    })
);

type Props = {
    index: number;
    value: number;
    children: ReactNode;
};

export const CustomTabPanel = (props: Props) => {
    const { children, value, index, ...other } = props;

    return (
        <TabPanelContainer role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} {...other}>
            {value === index && (
                <TabPanelContent>
                    <Typography>{children}</Typography>
                </TabPanelContent>
            )}
        </TabPanelContainer>
    );
};
