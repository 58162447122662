import { usePostHog } from 'posthog-js/react';
import { useMemo } from 'react';

import { isPostHogEnabled } from '~/helpers/PostHogHandler';
import { usePermissions } from '~/permissions/utils';

import { Platform, TOP_LEVEL_DESTINATIONS, TopLevelDestination } from './shared';

export const useFilteredDestinations = ({ platform }: { platform: Platform }): TopLevelDestination[] => {
    const hasPermission = usePermissions();
    const posthog = usePostHog();

    const filteredTopLevelDestinations = useMemo(
        () =>
            TOP_LEVEL_DESTINATIONS.map(({ innerDestinations, ...topLevelDestination }) => ({
                ...topLevelDestination,
                innerDestinations: innerDestinations.filter(({ permission, featureFlag, ...innerDestination }) => {
                    if (!innerDestination[platform]) return false;
                    if (permission && !hasPermission(permission.resource, permission.action)) return false;
                    if (featureFlag && isPostHogEnabled && !posthog.isFeatureEnabled(featureFlag)) return false;
                    return true;
                }),
            })).filter(({ innerDestinations }) => innerDestinations.length),
        [hasPermission]
    );

    return filteredTopLevelDestinations;
};
